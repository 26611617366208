import React, {Component} from 'react';
import {connect} from "react-redux";
import {Row, Col, Card, CardBody, Button, TabContent,
    TabPane,
    Collapse,
    NavLink,
    NavItem,
    Nav,} from "reactstrap";
    import classnames from "classnames";
import { MDBDataTable } from 'mdbreact';
import { Link } from "react-router-dom";
import Moment from "moment";
// import DonutChart from "../AllCharts/apex/dountchart";

// Import Action to copy breadcrumb items from local state to redux state
import {setBreadcrumbItems} from "../../store/actions";
import ReactApexChart from 'react-apexcharts';
import url from "../../helpers/apiUrl"
import productImage from '../../helpers/productImage';

class ProductsDeatails extends Component {
    constructor(props) {
        if (localStorage.getItem("token") === null) {
            return (
                this.props.history.push('/login')
            );
        }
        super(props);
        const { products } = this.props.location.state
        const { created_at } = this.props.location.state
        const { fromDate } = this.props.location.state
        const { toDate } = this.props.location.state
        this.state = {
            breadcrumbItems: [
                {
                    title: "One Dash",
                    link: "dashboard"
                },{
                    title: "Product Analytics",
                    link: "product-reports"
                }, {
                    title: "Product Details",
                    link: "#"
                },

            ],
            products: [],
            products1: [],
            details: [],
            devices: [],
            a2b: "0",
            view: "0",
            activeTab: "1",
            id: products._id,
            fromDate: fromDate,
            toDate: toDate,
            created_at: created_at,
            chartData:{},
            options: {},
            prices: []
        }
        this.toggle = this.toggle.bind(this);
        this.productDetails = this.productDetails.bind(this);
        this.productReports = this.productReports.bind(this);
    }

    componentDidMount() {
        this.props.setBreadcrumbItems("Products Details", this.state.breadcrumbItems);
        
        this.productReports();
        this.productDetails();
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }

    productReports(){
        var date = this.state.created_at;
        var fromDate = ''
        var toDate = ''
        if (date === "") {
            fromDate = ""
            toDate = ""
        } else {
            if(date === "1"){
                fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
                toDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            }else{
                fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
                toDate = Moment().format('YYYY-MM-DD')
            }
        }

        const regionNames = new Intl.DisplayNames(
            ['en'], {type: 'region'}
        );

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "productId": this.state.id,
        "fromDate": fromDate,
        "toDate": toDate
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/analytics/product-report`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.error === "Invalid Token"){
                localStorage.removeItem('token');
                sessionStorage.clear();
                this.props.history.push('/login') 
            }
            var array = []
            var arr = []
            var devarr =[]
            var viewarr = "0"
            var a2barr = "0"
            if(result.statusCode === "200"){
                for(let i = 0; i< result.body.country.buckets.length; i++){
                    array.push({
                        country: regionNames.of(result.body.country.buckets[i].key),
                        count: result.body.country.buckets[i].country_views.doc_count,
                        a2b: result.body.country.buckets[i].country_a2b.doc_count
                    })
                }
                
                    for(let i = 0; i < result.body.interaction_types.buckets.length; i++){
                        if(result.body.interaction_types.buckets[i].key === "TIMESLOT_CLICK"){
                            this.setState({
                                view:  result.body.interaction_types.buckets[i].doc_count
                            })
                        }
                       
                        if(result.body.interaction_types.buckets[i].key === "ADD_TO_BAG"){
                            this.setState({
                                a2b:  result.body.interaction_types.buckets[i].doc_count 
                            })
                            
                        }
                
                       }
               
               
               arr.push({  
                purchase: result.body.purchases.count || 0,
                revenue: result.body.purchases.amount || 0
            })

                for(let i = 0; i< result.body.device.buckets.length; i++){
                    devarr.push({
                        device_name: result.body.device.buckets[i].key,
                        device_view: result.body.device.buckets[i].device_views.doc_count
                    })
                    
                }       
            }
            this.setState({products: array, products1: arr, devices: devarr})
        })
        .catch(error => console.log('error', error));

        
        
    }

    productDetails(){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "productId": this.state.id
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/analytics/product-details`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.error === "Invalid Token"){
                localStorage.removeItem('token');
                sessionStorage.clear();
                this.props.history.push('/login') 
            }
            var array = []
            var arr = []
            if(result.statusCode === "200"){
                if(result.body[0]._source.variants.length > 0){
                    
                    for(let i = 0; i < result.body[0]._source.variants.length; i++){
                        arr.push({
                            title:  result.body[0]._source.variants[i].title,
                            price: result.body[0]._source.currencySymbol + result.body[0]._source.variants[i].price
                        })
                    }
                    this.setState({ prices: arr })
                }
                    array.push({
                        name: result.body[0]._source.name,
                        shopUrl: result.body[0]._source.shopUrl,
                        vendorType:  result.body[0]._source.vendorType,
                        currencySymbol: result.body[0]._source.currencySymbol,
                        image: result.body[0]._source.image.src,
                    })
            }  
            this.setState({ details: array })  
        })
        .catch(error => console.log('error', error));
    }

    render() {
        const data = {
            columns: [
                {
                    label: 'Country Name',
                    field: 'country',
                    width: 150
                },
                {
                    label: 'Views',
                    field: 'count',
                    width: 150
                },
                {
                    label: 'Add To Bag',
                    field: 'a2b',
                    width: 150
                }
                
            ],
            rows: this.state.products
        };
        const device = {
            columns: [
                {
                    label: 'Device Name',
                    field: 'device_name',
                    width: 150
                },
                {
                    label: 'Views',
                    field: 'device_view',
                    width: 150
                }
                
            ],
            rows: this.state.devices
        };
        const price = {
            columns: [
                {
                    label: 'Title',
                    field: 'title',
                    width: 150
                },
                {
                    label: 'Price',
                    field: 'price',
                    width: 150
                }
                
            ],
            rows: this.state.prices
        }
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <Nav tabs>
                                    <NavItem>
                                    <NavLink
                                    style={{ cursor : "pointer" }}
                                        className={classnames({
                                        active: this.state.activeTab === "1"
                                        })}
                                        onClick={() => {
                                        this.toggle("1");
                                        }}
                                    >
                                        <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                        <span className="d-none d-sm-block">Summary</span>
                                    </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                        style={{ cursor : "pointer" }}
                                            className={classnames({
                                            active: this.state.activeTab === "2"
                                            })}
                                            onClick={() => {
                                            this.toggle("2");
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                                <span className="d-none d-sm-block">Geography</span>
                                        </NavLink>
                                    </NavItem>
                                    
                                </Nav>
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="1" className="p-3">
                                        <Row  style={{backgroungColor: "yellow"}}>
                                            <Col>
                                                <Card>
                                                    <CardBody>
                                                        <Row xs="12">
                                                            <Col xs="4">
                                                                {
                                                                        this.state.details.map((detail) =>(
                                                                        <img style={{height: 200, width: 150}} src={`${productImage}` + detail.image}></img>
                                                                        ))
                                                                }
                                                                
                                                            </Col>
                                                            <Col xs="6">
                                                                {
                                                                    this.state.details.map((detail) =>(
                                                                    <>
                                                                    <h6>{detail.name}</h6>
                                                                    <p>{detail.vendorType}</p>
                                                                    <p>{detail.shopUrl}</p>
                                                                    </>
                                                                    )) 
                                                                }
                                                                
                                                                <hr></hr>
                                                                                                                                        
                                                                    <Row>
                                                                        <Col>{this.state.view}</Col>
                                                                        <Col>{this.state.a2b}</Col>
                                                                        
                                                                        {
                                                                            this.state.products1.map((pro) => (
                                                                                <>
                                                                                    <Col>{pro.purchase}</Col>
                                                                                    <Col>{pro.revenue}</Col>
                                                                                </>
                                                                            ))
                                                                        }
                                                                        
                                                                        {/* <Col>100</Col>     */}
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>Views</Col>
                                                                        <Col>Add To Bag</Col>
                                                                        <Col>Purchased</Col>
                                                                        <Col>Revenue</Col>
                                                                    </Row>
                                                                
                                                            </Col>  
                                                        </Row>                                                         
                                                        
                                                        <Row style={{marginTop: 20}}>
                                                            <Col xs="6">
                                                                <Card style={{marginTop: 50}}>
                                                                    <h6>Prices</h6>
                                                                    <CardBody>
                                                                        <MDBDataTable responsive striped
                                                                            data={price}
                                                                        />
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                            <Col xs="6" >
                                                                <Card style={{marginTop: 50}}>
                                                                    <h6>Devices View</h6>
                                                                    <CardBody style={{ cursor: "pointer" }}>
                                                                        {
                                                                            this.state.devices.length == 0  ?
                                                                            <div style={{display: "grid", justifyItems: "center", marginTop: 30}}>
                                                                                <i className='far fa-eye-slash' style={{fontSize: 50, marginBottom: 30}}></i>
                                                                                <h5>Oops!!</h5>
                                                                                <p>No views</p>
                                                                            </div> :
                                                                             this.state.devices.device_view === 0 ?
                                                                             <div style={{display: "grid", justifyItems: "center", marginTop: 30}}>
                                                                                <i className='far fa-eye-slash' style={{fontSize: 50, marginBottom: 30}}></i>
                                                                                <h5>Oops!!</h5>
                                                                                <p>No views</p>
                                                                            </div>:
                                                                            <ReactApexChart 
                                                                            options={ {
                                                                                labels: this.state.devices.map((abc) => abc.device_name),
                                                                                dataLabels: {
                                                                                    enabled: true
                                                                                },
                                                                                legend: {
                                                                                    show: true,
                                                                                    labels: {
                                                                                        colors: ["#FFFFFF"], 
                                                                                    }
                                                                                },
                                                                                responsive: [{
                                                                                    breakpoint: 480,
                                                                                    options: {
                                                                                        chart: {
                                                                                            width: 100,
                                                                                        },
                                                                                        legend: {
                                                                                            position: 'bottom'
                                                                                        }
                                                                                    }
                                                                                }]
                                                                            }} 
                                                                            series={this.state.devices.map((pqr) => pqr.device_view)} 
                                                                            type="donut" 
                                                                            height="285" 
                                                                        />
                                                                        }
                                                                        
                                                                        {/* <MDBDataTable responsive striped
                                                                            data={device}
                                                                        /> */}
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                        
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="2" className="p-3">
                                        <Row>
                                            <Col xs="12">
                                                <Card>
                                                    <CardBody style={{ cursor: "pointer" }}>
                                                        <MDBDataTable responsive striped
                                                            data={data}
                                                        />
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                        
                                    </TabPane>
                                    
                                </TabContent>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                    {/*
                        
                        <ReactApexChart 
                        options={ {
                            colors: ['#7A6FBE','#ECECEC', '#28BBE3'],
                            labels: this.state.devices.map((abc) => abc.device_name),
                            dataLabels: {
                                enabled: true
                            },
                            legend: {
                                show: true
                            },
                            responsive: [{
                                breakpoint: 480,
                                options: {
                                    chart: {
                                        width: 100,
                                    },
                                    legend: {
                                        position: 'bottom'
                                    }
                                }
                            }]
                        }} 
                    series={this.state.devices.map((pqr) => pqr.device_view)} 
                    type="donut" 
                    height="285" 
                    />
                    */}
                    
            </React.Fragment>
        );
    }
}

export default connect(null, {setBreadcrumbItems})(ProductsDeatails);