import React, { Component } from 'react';
import {
    Col,
    Row,
    Card,
    CardText,
    CardBody,
    TabContent,
    TabPane,
    NavLink,
    NavItem,
    Nav,
    Button,
    Input,
    FormGroup,
    Label,
    Alert,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    Modal
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { RotatingLines } from  'react-loader-spinner'

import SweetAlert from "react-bootstrap-sweetalert";

import { MDBDataTable } from 'mdbreact';
import Moment from "moment"
//url
import url from "../../helpers/apiUrl"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './users.css';

class UserDetails extends Component {
    constructor(props) {
        super(props);
        const tabStyle = {
            backgroundColor: "#26262b !important"
        };
        this.state = {
            breadcrumbItems: [
                { title: "One Dash", link: "dashboard" },
                { title: "Endusers", link: "user" },
                { title: "User Details", link: "#" },
            ],
            modal_edit: false,
            modal_shopify: false,
            modal_bigcommerce: false,
            modal_stripe: false,
            modal_woocommerce: false,
            modal_amazon: false,
            sub: '',
            // credentials: '',
            username: '',
            given_name: '',
            prevGiven_name: '',
            prevFamily_name: '',
            family_name: '',
            email: '',
            email_verified: '',
            status: '',
            created_at: '',
            upated_at: '',
            temp: false,
            integration: '',
            stripe: [],
            shopify: [],
            amazon: [],
            bigcommerce: [],
            woocommerce: [],
            subscription: [],
            payload: [],
            otherSub: [],
            credentials: Object,
            shopify_credential: [],
            strip_credential: [],
            bigcommerce_credential: [],
            woocommerce_credential: [],
            projects: [],
            subscriptionId: '',
            stripsubscriptionId: '',
            storage_subscriptionId:'',
            storage_stripsubscriptionId:'',
            storage_payload:[],
            storage_name: '',
            storage_duration: '',
            billing_time: '',
            billing_period: '',
            billing_periodrange: '',
            billing_programmaticrate: '',
            billing_socialrate: '',
            billing_alert: false,

            prev_billing_period: '',
            prev_billing_periodrange: '',
            prev_billing_programmaticrate: '',
            prev_billing_socialrate: '',
            billing_id: '',
            storage_price: '',
            status_filter: '',
            name_filter: "",
            type_filter: "",
            size_filter: "",
            fromDate_filter: "",
            toDate_filter: "",
            charity: false,
            prev_charity: false,
            charityAlert: false,
            success_confirm: false,
            alert_confirm: false,
            alert_confirm_subscription:false,
            alert_confirm_storage:false,
            invoice_year:Moment().format("YYYY"),
            invoice_array:[],
            id: '',
            projectName: '',
            prevProjectName: '',

            apiKey: '',
            storefrontAccessToken: '',
            apiPassword: '',
            sharedSecret: '',
            shopifyName: '',
            shopifyUrl: '',

            privateKey: '',
            publicKey: '',
            stripeName: '',
            stripeUrl: '',
            stripCredential: '',

            clientId: '',
            apiPath: '',
            clientSecret: '',
            accessToken: '',
            bigcommerceName: '',
            bigcommerceUrl: '',
            bigcommerceCredential: '',

            consumerSecret: '',
            consumerKey: '',
            woocommerceName: '',
            woocommerceUrl: '',
            woocommerceCredential: '',

            partnerTag: '',
            secretKey: '',
            marketplace: '',
            accessKey: '',
            amazonName: '',
            amazonUrl: '',
            amazonCredential: '',
            activeTab1: "5",

            created_at_filter: '',
            fromDate: '',
            toDate: '',
            modal_add_cal: false,
            trialPlan: '',
            programmaticRate: '',
            socialRate: '',
            billing_projects: [],
            totalAmount_bill: '',
            totalViews_bill: ''
        }
        this.tog_add_cal = this.tog_add_cal.bind(this);
        this.tog_add_cal2 = this.tog_add_cal2.bind(this);
        this.handleDatechange = this.handleDatechange.bind(this);
        this.handleToDatechange = this.handleToDatechange.bind(this);
        this.tog_edit = this.tog_edit.bind(this);
        this.tog_amazon = this.tog_amazon.bind(this);
        this.tog_shopify = this.tog_shopify.bind(this);
        this.tog_stripe = this.tog_stripe.bind(this);
        this.tog_bigcommerce = this.tog_bigcommerce.bind(this);
        this.tog_woocommerce = this.tog_woocommerce.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.changeHandler2 = this.changeHandler2.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.toggle1 = this.toggle1.bind(this);
        this.getAllIntegrations = this.getAllIntegrations.bind(this);
        this.getSubscriptionDetails = this.getSubscriptionDetails.bind(this);
        this.getAllProjects = this.getAllProjects.bind(this);
        this.bytesToSize = this.bytesToSize.bind(this);
        this.canclePlan = this.canclePlan.bind(this);
        this.updateCharity = this.updateCharity.bind(this);
        this.deleteProject = this.deleteProject.bind(this);
        this.renameProject = this.renameProject.bind(this);
        this.fillProject = this.fillProject.bind(this);
        // this.integrations = this.integrations.bind(this)
        this.clearFilter = this.clearFilter.bind(this);
        this.updateSocialProgramaticCampaign = this.updateSocialProgramaticCampaign.bind(this);
        this.billings = this.billings.bind(this);
        this.incrementYear = this.incrementYear.bind(this)
        this.decrementYear = this.decrementYear.bind(this)
        this.loadinvoices = this.loadinvoices.bind(this)
        this.disableToast = this.disableToast.bind(this);

    }

    componentDidMount() {
        this.props.setBreadcrumbItems("Users Details", this.state.breadcrumbItems);
        if (localStorage.getItem("token") === null) {
            return (
                this.props.history.push('/login')
                // <Redirect to={{ pathname: "/login" }} exact />
            );
        }
        const { user } = this.props.location.state

        this.getAllIntegrations(user.sub)
        this.getSubscriptionDetails(user.sub)
        this.getAllProjects(user.sub)
        this.billings(user.sub)
        this.loadinvoices(user.sub,this.state.invoice_year)
        //   this.integrations(user.sub)

        this.setState({
            sub: user.sub,
            username: user.Username,
            given_name: user.given_name,
            family_name: user.family_name,
            email: user.email,
            email_verified: user.email_verified,
            status: user.UserStatus,
            created_at: Moment(user.UserCreateDate).format('YYYY/MM/DD HH:mm:ss'),
            upated_at: Moment(user.UserLastModifiedDate).format('YYYY/MM/DD HH:mm:ss'),
            prevFamily_name: user.family_name,
            prevGiven_name: user.given_name
        })
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        if(e.target.value === "custom"){
            this.setState({ [e.target.name]: "custom2",fromDate:'',toDate:'' })
            this.tog_add_cal()
        }
        else{
            setTimeout(() => {
                // console.log("created",this.state.created_at_filter)
                this.getAllProjects(this.state.sub)
            }, 1500);
        }
    }

    changeHandler2 = (e) => {
        this.setState({ charity: !this.state.charity })
    }

    updateUser() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "username": this.state.username,
            "givenName": this.state.given_name,
            "familyName": this.state.family_name,
            "email": this.state.email
        });

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/endusers`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.statusCode === "200") {

                    toast(<div className='toast-message-container'>
                <h6>User Management</h6>
                <p>User details updated successfully.</p>
                </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-success'
                    });
                } else {
                    toast(<div className='toast-message-container'>
                    <h6>User Management</h6>
                    <p>User details could not be updated.</p>
                </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-error'
                    });
                }
            })
            .catch(error => console.log('error', error));
    }

    getAllIntegrations(sub) {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "sub": sub
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/endusers/integrations`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result.error === "Invalid Token"){
                    localStorage.removeItem('token');
                    sessionStorage.clear();
                    this.props.history.push('/login') 
                }
                var charity = false

                if (result.body[0]._source.advantages !== undefined) {
                    if (result.body[0]._source.advantages.charity === true) {
                        this.setState({
                            charity: true,
                            prev_charity: true
                        })
                    }
                    charity = result.body[0]._source.advantages.charity
                }
                
                //Shopify
                var shopifyarr = []
                if(result.body[0]._source.integration.hasOwnProperty("shopify")){
                    if(result.body[0]._source.integration.shopify.length !== 0){             
                        for(let i = 0 ; i < result.body[0]._source.integration.shopify.length ; i++){
                            shopifyarr.push({
                                shopify: result.body[0]._source.integration.shopify[i]
                            })
                            
                        }
                        localStorage.setItem("shopifyarr", JSON.stringify(shopifyarr))
                    }
                }
                
                console.log("integration")
                //Stripe
                var stripearr = []
                if(result.body[0]._source.integration.hasOwnProperty("stripe")){
                    if(result.body[0]._source.integration.stripe.length !== 0){
                        for(let i =0; i < result.body[0]._source.integration.stripe.length; i++){
                            stripearr.push({
                                stripe: result.body[0]._source.integration.stripe[i]
                            })
                        }
                        localStorage.setItem("stripearr", JSON.stringify(stripearr))
                    }
                }
                
                //BigCommerce
                var bigcommercearr = []
                if(result.body[0]._source.integration.hasOwnProperty("bigcommerce")){
                    if(result.body[0]._source.integration.bigcommerce.length !== 0){
                        for(let i =0; i < result.body[0]._source.integration.bigcommerce.length; i++){
                            bigcommercearr.push({
                                bigcommerce: result.body[0]._source.integration.bigcommerce[i]
                            })
                        }
                        localStorage.setItem("bigcommercearr", JSON.stringify(bigcommercearr))
                    }
                }

                //WooCommerce
                var woocommercearr = []
                if(result.body[0]._source.integration.hasOwnProperty("woocommerce")){
                    if(result.body[0]._source.integration.woocommerce.length !== 0){
                        for(let i =0; i < result.body[0]._source.integration.woocommerce.length; i++){
                            woocommercearr.push({
                                woocommerce: result.body[0]._source.integration.woocommerce[i]
                            })
                        }
                        localStorage.setItem("woocommercearr", JSON.stringify(woocommercearr))
                    }
                }

                //Amazon
                var amazonarr = []
                if(result.body[0]._source.integration.hasOwnProperty("amazon")){
                    if(result.body[0]._source.integration.amazon.length !== 0){
                        for(let i =0; i < result.body[0]._source.integration.amazon.length; i++){
                            amazonarr.push({
                                amazon: result.body[0]._source.integration.amazon[i]
                            })
                        }
                        localStorage.setItem("amazonarr", JSON.stringify(amazonarr))
                    }
                }

                this.setState({
                    shopify: shopifyarr,
                    stripe: stripearr,
                    bigcommerce: bigcommercearr,
                    woocommerce: woocommercearr,
                    amazon: amazonarr
                })

                this.setState({
                    billing_id: result.body[0]? result.body[0]._id : "",
                    billing_period: result.body[0]._source.billings ? result.body[0]._source.billings.period : "",
                    billing_periodrange: result.body[0]._source.billings ? result.body[0]._source.billings.periodRange : '',
                    billing_programmaticrate: result.body[0]._source.billings ? result.body[0]._source.billings.programmaticRate : '',
                    billing_socialrate:  result.body[0]._source.billings ? result.body[0]._source.billings.socialRate : "",
                    prev_billing_period: result.body[0]._source.billings.period,
                    prev_billing_periodrange: result.body[0]._source.billings.periodRange,
                    prev_billing_programmaticrate:  result.body[0]._source.billings.programmaticRate,
                    prev_billing_socialrate:  result.body[0]._source.billings.socialRate,
                })
                // console.log("hello")
                // console.log(this.state.stripe, "stripe")
            })
            .catch(error => console.log('error', error));
    }

    getSubscriptionDetails(sub) {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({ "sub": sub });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/endusers/subscription`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result.error === "Invalid Token"){
                    localStorage.removeItem('token');
                    sessionStorage.clear();
                    this.props.history.push('/login') 
                }
                console.log(result)
                var storage_name = "3 GB"
                var storage_duration = "month"
                var storage_price = '0'
                var billing_time = ''
                var subscription = ''
                var payload = ''
                var otherSub =''
                var subscriptionId = ''
                var stripsubscriptionId = ''
                var storage_subscriptionId = ""
                var storage_stripsubscriptionId = ""
                var storage_payload = []

                if(result.body.trialExpiry !== undefined){
                    this.setState({
                        trialPlan: result.body.trialExpiry
                    })

                }

                if(result.body[0]._source.role === "SERVICE"){    
                    if (result.body[0] !== undefined) {
                        storage_name = result.body[0]._source.plan.name
                        storage_duration = result.body[0]._source.plan.frequency
                        storage_price = result.body[0]._source.plan.price
                        billing_time = result.body[0]._source.nextBillingTime
                        storage_subscriptionId = result.body[0]._id
                        storage_stripsubscriptionId = result.body[0]._source.originSubscriptionId
                        storage_payload = result.body[0]._source.payload
                    };
                    subscription = result.body[1]._source.plan;
                    payload = result.body[1]._source.payload;
                    otherSub = result.body[1]._source;
                    subscriptionId = result.body[1]._id;
                    stripsubscriptionId = result.body[1]._source.originSubscriptionId;

                }
                else if (result.body[0]._source.role === "MAIN"){
                    
                    subscription = result.body[0]._source.plan;
                    payload = result.body[0]._source.payload;
                    otherSub = result.body[0]._source;
                    subscriptionId = result.body[0]._id;
                    stripsubscriptionId = result.body[0]._source.originSubscriptionId;
                    if (result.body[1] !== undefined) {
                        storage_name = result.body[1]._source.plan.name
                        storage_duration = result.body[1]._source.plan.frequency
                        storage_price = result.body[1]._source.plan.price
                        billing_time = result.body[1]._source.nextBillingTime
                        storage_subscriptionId = result.body[1]._id
                        storage_stripsubscriptionId = result.body[1]._source.originSubscriptionId
                        storage_payload = result.body[1]._source.payload

                    }; 

                }

                else if(result.body[1] !== undefined){
                    if (result.body[1]._source.role === "SERVICE") {
                        storage_name = result.body[1]._source.plan.name
                        storage_duration = result.body[1]._source.plan.frequency
                        storage_price = result.body[1]._source.plan.price
                        billing_time = result.body[1]._source.nextBillingTime
                        storage_subscriptionId = result.body[1]._id
                        storage_stripsubscriptionId = result.body[1]._source.originSubscriptionId
                        storage_payload = result.body[1]._source.payload

                    }
                    subscription= result.body[0]._source.plan;
                    payload = result.body[0]._source.payload;
                    otherSub = result.body[0]._source;
                    subscriptionId = result.body[0]._id;
                    stripsubscriptionId = result.body[0]._source.originSubscriptionId;
                    
                    
                }
                
                
                this.setState({
                    subscription: subscription,
                    payload: payload,
                    storage_payload:storage_payload,
                    otherSub: otherSub,
                    subscriptionId: subscriptionId,
                    stripsubscriptionId: stripsubscriptionId,
                    storage_name: storage_name,
                    storage_duration: storage_duration,
                    storage_price: storage_price,
                    billing_time: billing_time,
                    storage_stripsubscriptionId:storage_stripsubscriptionId,
                    storage_subscriptionId:storage_subscriptionId
                })
            })
            .catch(error => console.log('error', error));

    }

    canclePlan() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "subscriptionId": this.state.subscriptionId,
            "stripesubscriptionId": this.state.stripsubscriptionId
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/subscriptions/cancel-user`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result.statusCode==="200"){
                    toast(<div className='toast-message-container'>
                    <h6>Subscription</h6>
                    <p>Subscription successfully canceled.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-success'
                    });
                }else{
                    toast(<div className='toast-message-container'>
                    <h6>Subscription</h6>
                    <p>Subscription could not be canceled.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-error'
                    });
                }
                setTimeout(() => {
                    this.getSubscriptionDetails(this.state.sub)
                }, 2000);
                
            })
            .catch(error => console.log('error', error));
    }
    cancleStoragePlan(){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "stripesubscriptionId": this.state.storage_stripsubscriptionId,
        "subscriptionId": this.state.storage_subscriptionId
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/subscriptions/cancel-user`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            if(result.statusCode==="200"){
                toast(<div className='toast-message-container'>
                <h6>Storage</h6>
                <p>Storage plan successfully canceled.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"22px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-success'
                });
            }else{
                toast(<div className='toast-message-container'>
                <h6>Storage</h6>
                <p>Storage plan could not be canceled.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-error'
                });
            }
            setTimeout(() => {
                this.getSubscriptionDetails(this.state.sub)
            }, 2000);
        })
        .catch(error => console.log('error', error));
    }

    billings(sub){
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        console.log(firstDay, "billings")
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        console.log(lastDay, "billings")
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "sub": sub,
        "fromDate": Moment(firstDay).format('YYYY-MM-DD'),
        "toDate": Moment(lastDay).format('YYYY-MM-DD')
        });
        console.log("Billings raw", raw)

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/endusers/billing-invoice`, requestOptions)
        .then(response => response.json())
        .then(result => {
            var arr = []
            for(let i = 0; i < result.body.projects.length; i++){
                arr.push({
                    name: result.body.projects[i].name,
                    amount: result.body.projects[i].amount,
                    views: result.body.projects[i].views,
                    type: "Project | Social",

                })
            }
            this.setState({
                socialRate: result.body.socialRate,
                programmaticRate: result.body.programmaticRate,
                billing_projects: arr,
                totalViews_bill: result.body.totalViews,
                totalAmount_bill: result.body.totalAmount
            })
            console.log(this.state.billing_projects, "billings project")
        })
        .catch(error => console.log('error', error));
    }

    getAllProjects(sub) {
        var date = this.state.created_at_filter;
        var fromDate = ''
        var toDate = ''
        if (date === "custom" || date === "custom2") {
            fromDate = Moment(this.state.fromDate).format('YYYY-MM-DD')
            toDate = Moment(this.state.toDate).format('YYYY-MM-DD')
        } else if (date !== "") {
            fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            toDate = Moment().format('YYYY-MM-DD')
        }

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "sub": sub,
            "status": this.state.status_filter,
            "name": this.state.name_filter,
            "type": this.state.type_filter,
            "size": this.state.size_filter,
            "fromDate": fromDate,
            "toDate": toDate
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/endusers/projects`, requestOptions)
            .then(response => response.json())
            .then(data => {
                var array = []
                if(this.state.modal_add_cal === true){
                    this.setState(prevState => ({
                        modal_add_cal: false
                    }));
                }
                for (let i = 0; i < data.body.length; i++) {
                    array.push({
                        id: data.body[i]._id,
                        name: data.body[i]._source.name,
                        projectType: data.body[i]._source.projectType,
                        status: data.body[i]._source.status,
                        type: data.body[i]._source.type,
                        size: Number.isInteger(data.body[i]._source.size) ? this.bytesToSize(data.body[i]._source.size) : "-",
                        createdAt: Moment(data.body[i]._source.metadata.createdAt).format('YYYY/MM/DD HH:mm:ss'),
                        updatedAt: Moment(data.body[i]._source.metadata.updatedAt).format('YYYY/MM/DD HH:mm:ss'),
                        button:
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <Button type="button"
                                    onClick={
                                        () => this.fillProject(data.body[i]._source.name, data.body[i]._id)
                                    }
                                    style={
                                        { marginRight: 10}
                                    }
                                    color="primary"
                                    className="waves-effect waves-light buttons-secondary">
                                    <i className="ti-pencil"></i>
                                </Button>
                                <Button type="button" color="danger"
                                    onClick={
                                        () => this.setState({ alert_confirm: true, id: data.body[i]._id })
                                    }
                                    className="waves-effect waves-light buttons-secondary"
                                    id="sa-warning"><i className="ti-trash"></i>
                                </Button>
                            </div>

                    })
                }
                this.setState({ projects: array })
            })

            .catch(error => console.log('error', error));
        
    }

    renameProject() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "projectid": this.state.id,
            "projectname": this.state.projectName
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/endusers/rename-project`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.statusCode === "200") {

                    this.tog_edit()
                    this.setTimeout(this.getAllProjects(this.state.sub), 100)

                }

            })
            .catch(error => console.log('error', error));
    }

    deleteProject() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "projectid": this.state.id,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/endusers/delete-project`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result.statusCode==="200"){
                    toast(<div className='toast-message-container'>
                    <h6>Projects</h6>
                    <p>Project deleted successfully.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-success'
                    });
                    setTimeout(function(){
                        this.getAllProjects(this.state.sub)
                     }, 2000);
                }else{
                    toast(<div className='toast-message-container'>
                    <h6>Projects</h6>
                    <p>Project could not be deleted.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"22px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-error'
                    });
                }
            })
            .catch(error => console.log('error', error));
    }

    fillProject(name, id) {
        this.setState({
            projectName: name,
            id: id,
            prevProjectName: name
        })
        this.tog_edit()
    }

    updateCharity() {
        this.setState({ prev_charity: this.state.charity})
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "sub": this.state.sub,
            "charity": this.state.charity
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/endusers/charity`, requestOptions)
            .then(response => response.json())
            .then(result => {
                this.setState({
                    charityAlert: true
                })
                setTimeout(() =>{
                    this.setState({
                        charityAlert: false
                    })
                }, 3000)
                // console.log(result)
            })
            .catch(error => console.log('error', error));
    }

    updateSocialProgramaticCampaign(){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "id": this.state.billing_id,
            "period": this.state.billing_period,
            "periodRange":  this.state.billing_periodrange,
            "programmaticRate":  this.state.billing_programmaticrate,
            "socialRate":  this.state.billing_socialrate
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/endusers/socialrates`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.statusCode === "200"){
                toast(<div className='toast-message-container'>
                <h6>Campaign Rates</h6>
                <p>Rates updated successfully</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-success'
                });
            }else{
                toast(<div className='toast-message-container'>
                <h6>Campaign Rates</h6>
                <p>Rates could not be updated.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"22px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-error'
                });
            }
        })
        .catch(error => console.log('error', error));
    }

    bytesToSize(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }

    toggle1(tab) {
        if (this.state.activeTab1 !== tab) {
            this.setState({
                activeTab1: tab
            });
        }
    }

    tog_shopify(credentials, name, url) {
        // console.log( name.name, "credentials of shopify")
        this.setState(prevState => ({
            modal_shopify: !prevState.modal_shopify
        }));
        this.setState({ 
            credentials: credentials,
            shopifyName: name,
            shopifyUrl: url,
            apiKey: credentials.apiKey, 
            storefrontAccessToken: credentials.storefrontAccessToken,
            apiPassword: credentials.apiPassword,
            sharedSecret: credentials.sharedSecret
        })
        // console.log(this.state.credentials[0].apiKey, "credentials state")
        
    }

    tog_stripe(credentials, name, url) {
        this.setState(prevState => ({
            modal_stripe: !prevState.modal_stripe
        }));
        // console.log(name, url)
        this.setState({
            stripCredential: credentials,
            stripeName: name,
            stripeUrl: url,
            privateKey: credentials.privateKey,
            publicKey: credentials.publicKey
        })
        // console.log(this.state.stripeName)
        
    }

    tog_woocommerce(credentials, name, url) {
        this.setState(prevState => ({
            modal_woocommerce: !prevState.modal_woocommerce
        }));
        this.setState({
            woocommerceCredential: credentials,
            woocommerceName: name,
            woocommerceUrl: url,
            consumerSecret: credentials.consumerSecret,
            consumerKey: credentials.consumerKey
        })
        
    }

    tog_bigcommerce(credentials, name, url) {
        this.setState(prevState => ({
            modal_bigcommerce: !prevState.modal_bigcommerce
        }));
        this.setState({
            bigcommerceCredential: credentials,
            bigcommerceName: name,
            bigcommerceUrl: url,
            clientId: credentials.clientId,
            apiPath: credentials.apiPath,
            clientSecret: credentials.clientSecret,
            accessToken: credentials.accessToken
        })
        
    }

    tog_amazon(credentials, name, url) {
        this.setState(prevState => ({
            modal_amazon: !prevState.modal_amazon
        }));
        this.setState({
            amazonCredential: credentials,
            amazonName: name,
            amazonUrl: url,
            partnerTag: credentials.partnerTag,
            secretKey: credentials.secretKey,
            marketplace: credentials.marketplace,
            accessKey: credentials.accessKey
        })
        
    }

    tog_edit() {
        this.setState(prevState => ({
            modal_edit: !prevState.modal_edit
        }));
    }

    handleDatechange(value){
        var dateofvisit = Moment(value, 'DD-MM-YYYY');
        var today = Moment();
        if(today.diff(dateofvisit, 'Hours') <= 0){
            this.setState({
                DateAlert: true,fromDate: Moment(value).format("YYYY-MM-DD")
            })
            
            return  
        }else{
            this.setState({
                DateAlert: false
            }) 
            this.setState({fromDate: Moment(value).format("YYYY-MM-DD")})
        }
    }
    handleToDatechange(value){
        var dateofvisit = Moment(value, 'DD-MM-YYYY');
        var today = Moment();
        
        if(today.diff(dateofvisit, 'Hours') <= 0){
            this.setState({
                DateAlert: true,toDate: Moment(value).format("YYYY-MM-DD")
            })
            
            return  
        }else if(this.state.fromDate !== ""){
            if(Moment(this.state.fromDate, 'YYYY-MM-DD').diff(dateofvisit, 'Hours') > 0){
                this.setState({
                    DateAlert: true,toDate: Moment(value).format("YYYY-MM-DD")
                })
            }else{
                this.setState({
                    DateAlert: false
                })
                this.setState({toDate: Moment(value).format("YYYY-MM-DD")})
            }
        }else{
            this.setState({
                DateAlert: false
            })
            this.setState({toDate: Moment(value).format("YYYY-MM-DD")})

        }
    }
    tog_add_cal() {
        this.setState(prevState => ({
            modal_add_cal: !prevState.modal_add_cal

        }));
    }
    tog_add_cal2() {
        this.setState(prevState => ({
            modal_add_cal: false,fromDate:'',toDate:'',created_at_filter:"",DateAlert:false

        }));
        setTimeout(() => {
            this.getAllProjects(this.state.sub)
        }, 1500);
    }

    clearFilter() {
        this.setState({
            status_filter: '',
            name_filter: '',
            type_filter: '',
            size_filter: '',
            created_at_filter: ''
        })
        setTimeout(() => {
             this.getAllProjects(this.state.sub)
        }, 150)
        
        
    }
    decrementYear(){
        this.setState({
            invoice_year:parseInt(this.state.invoice_year)-1
        })
        this.loadinvoices(this.state.sub,parseInt(this.state.invoice_year)-1)

    }
    incrementYear(){
        this.setState({
            invoice_year:parseInt(this.state.invoice_year)+1
        })
        this.loadinvoices(this.state.sub,parseInt(this.state.invoice_year)+1)
    }
    loadinvoices(sub,year){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "sub": sub,
        "fromDate": year+"-01-01",
        "toDate": year+"-12-01"
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/endusers/invoices`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.statusCode==="200"){
                console.log(result.body.invoices)
                this.setState({invoice_array:result.body.invoices})
            }
        })
        .catch(error => console.log('error', error));
    }
    disableToast(){
        let toastDiv = document.querySelector('.toast-error');
        if(toastDiv == null){
            toast(<div className='toast-message-container'>
            <h6>Projects</h6>
            <p>Please select a valid date.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
         })
        }
    }
    render() {
        const cardStyle = {
            height: "106px",
            padding: "28px",
            borderRadius: "1px",
            backgroundColor: "#303035",
            boxShadow: "0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(0 0 0 / 25%)",
            marginBottom: "16px"
        }
        const data = {
            columns: [
                {
                    label: 'Project Name',
                    field: 'name',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Project Type',
                    field: 'projectType',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Status',
                    field: 'status',
                    sort: 'asc',
                    width: 270
                },
                {
                    label: 'Type',
                    field: 'type',
                    sort: 'disabled',
                    width: 200
                },
                {
                    label: 'Size',
                    field: 'size',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Created At',
                    field: 'createdAt',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Modified At',
                    field: 'updatedAt',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Action',
                    field: 'button',
                    width: 100,
                    sort: "disabled",
                },

            ],
            rows: this.state.projects
        };
        return (
            <React.Fragment>
                

                
                {this.state.alert_confirm ? (
                    
                    <div className="modal" style={{display:"block"}} tabIndex="-1" role="dialog" id='alertBox'>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Delete Project</h5>
                                
                            </div>
                            <div className="modal-body">
                                <p>{"Are you sure you want to delete the project?"}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" style={{background:"#FFFFFF0D", borderColor:"#2f2f34"}} className="btn" data-dismiss="modal"
                                onClick={() => {
                                    this.setState({
                                        alert_confirm: false,
                                    });
                                    document.getElementById('alertBox').style.display = 'none';
                                }} 
                                >Cancel</button>
                                <button type="button" className="btn"
                                onClick={() => {
                                    this.setState({ alert_confirm: false });
                                    this.deleteProject();
                                    document.getElementById('alertBox').style.display = 'none';
                                }}
                                >Delete</button>
                            </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                {this.state.alert_confirm_subscription ? (
                    
                    <div className="modal" style={{display:"block"}} tabIndex="-1" role="dialog" id='alertBox'>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Cancel Subscription</h5>
                                
                            </div>
                            <div className="modal-body">
                                <p>{"Are you sure you want to cancel the subscription?"}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" style={{background:"#FFFFFF0D", borderColor:"#2f2f34"}} className="btn" data-dismiss="modal"
                                onClick={() => {
                                    this.setState({
                                        alert_confirm_subscription: false,
                                    });
                                    document.getElementById('alertBox').style.display = 'none';
                                }} 
                                >Cancel</button>
                                <button type="button" className="btn"
                                onClick={() => {
                                    this.setState({ alert_confirm: false });
                                    this.canclePlan();
                                    document.getElementById('alertBox').style.display = 'none';
                                }}
                                >Confirm</button>
                            </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                {this.state.alert_confirm_storage ? (
                    
                    <div className="modal" style={{display:"block"}} tabIndex="-1" role="dialog" id='alertBox'>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Cancel Storage Plan</h5>
                                
                            </div>
                            <div className="modal-body">
                                <p>{"Are you sure you want to cancel the storage plan?"}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" style={{background:"#FFFFFF0D", borderColor:"#2f2f34"}} className="btn" data-dismiss="modal"
                                onClick={() => {
                                    this.setState({
                                        alert_confirm_storage: false,
                                    });
                                    document.getElementById('alertBox').style.display = 'none';
                                }} 
                                >Cancel</button>
                                <button type="button" className="btn"
                                onClick={() => {
                                    this.setState({ alert_confirm: false });
                                    this.cancleStoragePlan();
                                    document.getElementById('alertBox').style.display = 'none';
                                }}
                                >Confirm</button>
                            </div>
                            </div>
                        </div>
                    </div>
                ) : null}

                
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody className="text-center">
                                <h5>Name: {this.state.username}</h5>
                                <p>User ID: {this.state.sub}</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody className="text-center">
                                <Row>
                                    <Col className="ml-4">
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-form-label">Sub</Label>
                                            <Input className="" disabled type="text" name="sub" value={this.state.sub} onChange={this.changeHandler} id="example-text-input" />
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-form-label">Last Name</Label>
                                            <Input className="form-control" type="text" name="family_name" value={this.state.family_name} onChange={this.changeHandler} id="example-text-input" />
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="example-password-input" className=" col-form-label">Status</Label>
                                            <Input className="form-control" disabled type="text" name="status" value={this.state.status} onChange={this.changeHandler} id="example-text-input" />
                                        </FormGroup>
                                    </Col>
                                    <Col className="ml-4">
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-form-label">Username</Label>
                                            <Input className="form-control" disabled type="text" name="username" value={this.state.username} onChange={this.changeHandler} id="example-text-input" />
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-form-label">Email</Label>
                                            <Input className="form-control" type="email" disabled name="email" value={this.state.email} onChange={this.changeHandler} id="example-text-input" />
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-form-label">Created At</Label>
                                            <Input className="form-control" disabled type="text" name="created_at" value={this.state.created_at} onChange={this.changeHandler} id="example-text-input" />
                                        </FormGroup>
                                        {
                                            this.state.given_name === this.state.prevGiven_name && this.state.family_name === this.state.prevFamily_name ?
                                                <Button type="button" color="primary" style={{ width: "300px", cursor: "default" }}
                                                    onClick={
                                                        () => this.updateUser()
                                                    } 
                                                    
                                                    disabled
                                                    className="waves-effect waves-light ">
                                                    Update
                                                </Button>
                                                : <Button type="button" color="primary" style={{ width: "300px" }}
                                                    onClick={
                                                        () => this.updateUser()
                                                    }
                                                    className="waves-effect waves-light ">
                                                    Update
                                                </Button>
                                        }

                                    </Col>

                                    <Col className="ml-4">
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-form-label">First Name</Label>
                                            <Input className="form-control" type="text" name="given_name" value={this.state.given_name} onChange={this.changeHandler} id="example-text-input" />
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-form-label">Email Verified</Label>
                                            <Input className="form-control" disabled type="text" name="email_verified" value={this.state.email_verified} onChange={this.changeHandler} id="example-text-input" />
                                            
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="example-text-input" className="col-form-label">Updated At</Label>
                                            <Input className="form-control" disabled type="text" name="upated_at" value={this.state.upated_at} onChange={this.changeHandler} id="example-text-input" />
                                        </FormGroup>

                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody style={{background: "#26262b"}}>
                                <Nav pills justified className="tabsContainer">
                                    <NavItem className="waves-effect waves-light">
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: this.state.activeTab1 === "5"
                                            })}
                                            onClick={() => {
                                                this.toggle1("5");
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                            <span className="d-none d-sm-block">Projects</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="waves-effect waves-light">
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: this.state.activeTab1 === "6"
                                            })}
                                            onClick={() => {
                                                this.toggle1("6");
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                            <span className="d-none d-sm-block">Integrations</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="waves-effect waves-light">
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: this.state.activeTab1 === "7"
                                            })}
                                            onClick={() => {
                                                this.toggle1("7");
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                                            <span className="d-none d-sm-block">Subscription</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="waves-effect waves-light">
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: this.state.activeTab1 === "8"
                                            })}
                                            onClick={() => {
                                                this.toggle1("8");
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                                            <span className="d-none d-sm-block">Billing</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>


                                <TabContent activeTab={this.state.activeTab1}>
                                    <TabPane tabId="5" className="p-3">
                                        {/* Filters */}
                                        <Row>
                                            <Col xs="12">
                                                <Card>
                                                    <CardBody >
                                                        <Row xs="12">
                                                            <Col>
                                                                <Label for="example-password-input" className=" col-form-label">Status</Label>
                                                                <Col>
                                                                    <select className="form-control" name='status_filter'
                                                                        value={
                                                                            this.state.status_filter
                                                                        }
                                                                        onChange={
                                                                            this.changeHandler
                                                                        }>
                                                                        <option value="" disabled selected hidden>Select</option>
                                                                        <option value="DRAFT">DRAFT</option>
                                                                        <option value="PROCESSED">PROCESSED</option>
                                                                    </select>
                                                                </Col>
                                                            </Col>
                                                            <Col>
                                                                <Label for="example-tel-input" className="col-form-label">Project Name</Label>
                                                                <Col>
                                                                    <Input className="form-control" name="name_filter" type="text"
                                                                        value={
                                                                            this.state.name_filter
                                                                        }
                                                                        onChange={
                                                                            this.changeHandler
                                                                        }
                                                                        id="example-search-input" />
                                                                </Col>
                                                            </Col>
                                                            
                                                            <Col>
                                    
                                                                <Label for="example-password-input" className=" col-form-label">Created At</Label>
                                                                <Col>
                                                                    <select className="form-control" name='created_at_filter'
                                                                        value={
                                                                            this.state.created_at_filter
                                                                        }
                                                                        onChange={
                                                                            this.changeHandler
                                                                        }>
                                                                        <option value="" disabled selected hidden>Select</option>
                                                                        <option value="0">Today</option>
                                                                        <option value="1">Yesterday</option>
                                                                        <option value="7">Last 7 days</option>
                                                                        <option value="30">Last 30 days</option>
                                                                        <option value="180">Last 6 months</option>
                                                                        <option value="custom" onClick={this.tog_add_cal}>Custom</option>
                                                                        <option value="custom2" hidden>{ this.state.created_at_filter === "custom2" ? "From: " + this.state.fromDate + " To: " + this.state.toDate : "Custom"}</option>
                                            
                                                                    </select>
                                                                </Col>
                                                            
                                                            </Col>

                                                        </Row>
                                                        <Col className="text-left" style={{ marginTop: 10 }}>
                                                            <Button type="button"
                                                                style={{background: "#17A0FB", padding: "7px 14px", borderColor: "#17A0FB", fontWeight: 600}}
                                                                onClick={
                                                                    () => this.getAllProjects(this.state.sub)
                                                                }
                                                                color="warning"
                                                                className="waves-effect waves-light">
                                                                <i className='mdi mdi-filter-menu-outline'
                                                                    style={
                                                                        {  marginRight: 10, fontSize: 15 }
                                                                    }
                                                                ></i>
                                                                Filter
                                                            </Button>
                                                            {
                                                                this.state.size_filter === "" &&  this.state.type_filter === "" && this.state.name_filter === "" && this.state.status_filter === "" && this.state.created_at_filter === "" ?
                                                                <Button type="button" disabled
                                                                    style={{ marginLeft: 10 ,border: "1px solid #6a6a71", borderRadius: "3px" ,backgroundColor: "rgba(80,80,88,.8)", transition: "background-color .2s linear", fontWeight: 600}}
                                                                    onClick={
                                                                        () => this.clearFilter()
                                                                    }
                                                                    color="success"
                                                                    className="waves-effect waves-light">

                                                                    Clear Filter
                                                                </Button>
                                                                : <Button type="button" 
                                                                    style={{ marginLeft: 10 ,border: "1px solid #E6536C", borderRadius: "3px" ,backgroundColor: "#E6536C", transition: "background-color .2s linear", padding:"7px 14px", fontWeight: 600}}
                                                                    onClick={
                                                                        () => this.clearFilter()
                                                                    }
                                                                    color="success"
                                                                    className="waves-effect waves-light">

                                                                    Clear Filter
                                                                </Button>
                                                            }
                                                        </Col>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <Card>
                                                    <CardBody style={{ cursor: "pointer" }}>
                                                        <MDBDataTable
                                                            responsive
                                                            bordered
                                                            striped
                                                            data={data}
                                                        />

                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="6" className="p-3">
                                    {this.state.charityAlert ? <Alert color="success">
                                            <strong>Charity updated successfully.</strong>
                                        </Alert> : null}
                                        <Row>
                                        
                                            {
                                                JSON.stringify(this.state.shopify) === "[]" && JSON.stringify(this.state.stripe) === "[]" && JSON.stringify(this.state.woocommerce) === "[]" && JSON.stringify(this.state.bigcommerce) === "[]" ?
                                                    <Col lg="12">
                                                        {/* <Alert color="danger" className="mb-0" >
                                                            No integrations for this user.
                                                        </Alert> */}
                                                        <div style={{color:"white"}}>
                                                            This user doesn't have any integration
                                                        </div>
                                                    </Col>
                                                    : null
                                            }
                                           
                                                <Col lg="6">
                                                {JSON.stringify(this.state.shopify) === "[]" ? null :
                                                    typeof this.state.shopify == 'object' ? 
                                                    this.state.shopify.map(shopifys =>
                                                        <Col>
                                                        <Card className="mini-stat" style={cardStyle}>
                                                            <CardBody>
                                                                <Button style={{background:"#303035", border:"none", fontSize:"18px"}} color="secondary" className="float-right" onClick={() => this.tog_shopify(shopifys.shopify.credentials, shopifys.shopify.name, shopifys.shopify.url)}><i className="ti-pencil"></i></Button>
                                                                <h5>{shopifys.shopify.name}</h5>
                                                                <p style={{ fontSize: 10 }}>{shopifys.shopify.url}</p>
                                                            </CardBody>
                                                        </Card>
                                                    </Col> )
                                                    : null
                                                }

                                           
                                                {JSON.stringify(this.state.stripe) === "[]" ? null :
                                                    typeof this.state.stripe == 'object' ? 
                                                    this.state.stripe.map(stripee => 
                                                        <Col>
                                                            <Card className="mini-stat" style={cardStyle}>
                                                                <CardBody>
                                                                    <Button style={{background:"#303035", border:"none", fontSize:"18px"}} className="float-right" onClick={() => this.tog_stripe(stripee.stripe.credentials, stripee.stripe.name, stripee.stripe.url)}><i className="ti-pencil"></i></Button>
                                                                    <h5>{stripee.stripe.name}</h5>
                                                                    <p style={{ fontSize: 10 }}>{stripee.stripe.url}</p>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>)                                                    
                                                    : null
                                                }

                                            
                                                {
                                                    JSON.stringify(this.state.woocommerce) === "[]" ? null :
                                                        typeof this.state.woocommerce == 'object' ? 
                                                        this.state.woocommerce.map(woo => 
                                                            <Col>
                                                            <Card className="mini-stat" style={cardStyle}>
                                                                <CardBody>
                                                                    <Button style={{background:"#303035", border:"none", fontSize:"18px"}} className="float-right" onClick={() => this.tog_woocommerce(woo.woocommerce.credentials, woo.woocommerce.name, woo.woocommerce.url)}><i className="ti-pencil"></i></Button>
                                                                    <h5>{woo.woocommerce.name}</h5>
                                                                    <p style={{ fontSize: 10 }}>{woo.woocommerce.url}</p>
                                                                </CardBody>
                                                            </Card>
                                                        </Col> )
                                                         : null
                                                }

                                            

                                                {JSON.stringify(this.state.bigcommerce) === "[]" ? null :
                                                    typeof this.state.bigcommerce == 'object' ? 
                                                    this.state.bigcommerce.map(big =>
                                                    <Col>
                                                        <Card className="mini-stat" style={cardStyle}>
                                                            <CardBody>
                                                                <Button style={{background:"#303035", border:"none", fontSize:"18px"}} className="float-right" onClick={() => this.tog_bigcommerce(big.bigcommerce.credentials, big.bigcommerce.name, big.bigcommerce.url)}><i className="ti-pencil"></i></Button>
                                                                <h5>{big.bigcommerce.name}</h5>
                                                                <p style={{ fontSize: 10 }}>{big.bigcommerce.url}</p>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>) : null
                                                }

                                                {JSON.stringify(this.state.amazon) === "[]" ? null :
                                                    typeof this.state.amazon == 'object' ? 
                                                    this.state.amazon.map(ama =>
                                                    <Col>
                                                        <Card className="mini-stat" style={cardStyle}>
                                                            <CardBody>
                                                                <Button style={{background:"#303035", border:"none", fontSize:"18px"}} className="float-right" onClick={() => this.tog_amazon(ama.amazon.credentials, ama.amazon.name, ama.amazon.url)}><i className="ti-pencil"></i></Button>
                                                                <h5>{ama.amazon.name}</h5>
                                                                <p style={{ fontSize: 10 }}>{ama.amazon.url}</p>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>) : null
                                                }
                                                </Col>
                                                {
                                                    this.state.billing_period === "" ? null 
                                                    : <Col lg="6">
                                                        {this.state.billing_alert ? <Alert color="success">
                                                            <strong>Update successful.</strong>
                                                        </Alert> : null}
                                                        <FormGroup row className="formContainer2 smallform">
                                                            <div style={{paddingLeft: "10px"}}><Label for="example-password-input" className="col-form-label">Period</Label></div>
                                                            <Col lg="12">
                                                                <select className="selectformContainer2" style={{margin: "0px"}} name='billing_period'
                                                                    value={
                                                                        this.state.billing_period
                                                                    }
                                                                    onChange={
                                                                        this.changeHandler
                                                                    }>
                                                                    <option value="" disabled selected hidden>Select</option>
                                                                    <option value="week">Week</option>
                                                                    <option value="month">Month</option>
                                                                    <option value="year">Year</option>
                                                                </select>
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup className="formContainer2 smallform">
                                                            <Label for="example-text-input" className="col-form-label">Period Range</Label>
                                                            <Input className="form-control" type="number" step="0.1" min='0' name="billing_periodrange" value={this.state.billing_periodrange} onChange={this.changeHandler} id="example-text-input" />
                                                        </FormGroup>
                                                        <FormGroup className="formContainer2 smallform">
                                                            <Label for="example-text-input" className="col-form-label">Programmatic Rate</Label>
                                                            <Input className="form-control" type="number" step="0.1" min='0' name="billing_programmaticrate" value={this.state.billing_programmaticrate} onChange={this.changeHandler} id="example-text-input" />
                                                        </FormGroup>
                                                        <FormGroup className="formContainer2 smallform">
                                                            <Label for="example-text-input" className="col-form-label">Social Rate</Label>
                                                            <Input className="form-control" type="number" step="0.1" min='0' name="billing_socialrate" value={this.state.billing_socialrate} onChange={this.changeHandler} id="example-text-input" />
                                                        </FormGroup>
                                                        {
                                                            this.state.billing_period === this.state.prev_billing_period && this.state.billing_periodrange === this.state.prev_billing_periodrange && this.state.billing_programmaticrate === this.state.prev_billing_programmaticrate && this.state.billing_socialrate === this.state.prev_billing_socialrate ?
                                                            <Button type="button" color="primary" disabled
                                                                onClick={
                                                                    () => this.updateSocialProgramaticCampaign()
                                                                } 
                                                                className="waves-effect waves-light ">
                                                                Update
                                                            </Button>
                                                            : <Button type="button" color="primary" 
                                                                onClick={
                                                                    () => this.updateSocialProgramaticCampaign()
                                                                } 
                                                                className="waves-effect waves-light ">
                                                                Update
                                                            </Button>
                                                        }
                                                        
                                                    </Col>
                                                }
                                                
                                            
                                        </Row>
                                        <Row style={{ marginTop: 20 }}>
                                            <Col lg="4">
                                                <Card color="light" style={{ padding: 10 }} >
                                                    <h4 className="card-title">User Advantages</h4>
                                                    <CardText>
                                                        {
                                                            this.state.charity ? <input type="checkbox" id="" name="charity" value={this.state.charity} onChange={
                                                                this.changeHandler2
                                                            } checked /> :
                                                                <input type="checkbox" id="" name="charity" value={this.state.charity} onChange={
                                                                    this.changeHandler2
                                                                } />
                                                        }
                                                        Charity
                                                    </CardText>
                                                    {
                                                        this.state.charity == this.state.prev_charity ?
                                                        <Button type="button" disabled color="primary" onClick={() => this.updateCharity()} className="waves-effect waves-light">Submit</Button>
                                                        :  <Button type="button" color="primary" onClick={() => this.updateCharity()} className="waves-effect waves-light">Submit</Button>
                                                    }
                                                     {/* <Button type="button" color="primary" onClick={() => this.updateCharity()} className="waves-effect waves-light">Submit</Button> */}
                                                   
                                                </Card>
                                                {/*
                                                <Card color="light" className="mini-stat" style={cardStyle}>
                                                    <h4 className="card-header font-16 mt-0">User Advantages</h4>
                                                    <CardBody></CardBody>
                                                </Card>
                                                */}

                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="7" className="p-3">
                                        <Row>

                                            {this.state.trialPlan !== '' ?
                                            <Col lg="6">
                                                <Card color="light">
                                                    <h4 className="card-header font-16 mt-0">Trial Plan</h4>
                                                    <CardBody>
                                                        <CardText> Trial plan expire date: { Moment.unix(this.state.trialPlan / 1000).format("YYYY-MM-DD HH:mm:ss")}</CardText>
                                                    
                                                    </CardBody>
                                                </Card> 
                                            </Col>:
                                            this.state.subscription.name !== undefined ? 
                                            <Col lg="6">
                                                {this.state.payload.canceledAt === null ?
                                                    <Card color="light">
                                                        <h4 className="card-header font-16 mt-0">{this.state.subscription.name} Plan</h4>
                                                        <CardBody>
                                                            <CardText> Your plan will be automatically renewed on {Moment(new Date(this.state.otherSub.nextBillingTime * 1000).toLocaleDateString("en-US")).format('YYYY-MM-DD')}. It will be charged as one time payment of ${(this.state.subscription.price * 0.01).toFixed(2)}.</CardText>
                                                            <Button type="button" color="danger"
                                                                onClick={
                                                                    () => this.setState({ alert_confirm_subscription: true, sub: this.state.sub })
                                                                }
                                                                className="waves-effect waves-light"
                                                                id="sa-warning">Cancel  Plan
                                                            </Button>
                                                        </CardBody>
                                                    </Card> :
                                                    <Card color="light">
                                                        <h4 className="card-header font-16 mt-0">{this.state.subscription.name} Plan</h4>

                                                        <CardBody>
                                                            <CardText> Your plan will be automatically canceled  at {Moment(new Date(this.state.payload.canceledAt * 1000).toLocaleDateString("en-US")).format('YYYY-MM-DD')} </CardText>
                                                        </CardBody>
                                                    </Card>

                                                }
                                                </Col> :
                                                <Col lg="12">

                                                    <Alert color="danger" className="mb-0">
                                                        No subscription for this user.
                                                    </Alert>

                                                </Col>
                                            }
                                            {
                                                this.state.subscription.name === "Unlimited" ? "" : 
                                                <Col>
                                                {
                                                    this.state.storage_name === "3 GB" ? 
                                                        <Card color="light">
                                                            <h3 className='card-header font-16 mt-0'>Storage Service {this.state.storage_name}</h3>
                                                            
                                                            <CardBody>
                                                                <CardText>{this.state.storage_name} allocation of space</CardText>
                                                            </CardBody>
                                                        </Card> 
                                                        :
                                                        this.state.storage_payload.canceledAt === null ?
                                                        
                                                        <Card color="light">
                                                    
                                                        <h5 className="card-header font-16 mt-0">Services</h5>
                                                        <h3 className='card-header font-16 mt-0'>Storage Service {this.state.storage_name}</h3>
                                                        <CardBody>
                                                            <CardText> Your plan will be automatically renewed on {Moment(new Date(this.state.billing_time * 1000).toLocaleDateString("en-US")).format('YYYY-MM-DD')}. It will be charged as one payment of ${this.state.storage_price / 100}.</CardText>
                                                            <Button type="button" color="danger"
                                                                    onClick={
                                                                        () => this.setState({ alert_confirm_storage: true, sub: this.state.sub })
                                                                    }
                                                                    className="waves-effect waves-light"
                                                                    id="sa-warning">Cancel  Plan
                                                                </Button>
                                                        </CardBody>
                                                        </Card> 
                                                        :
                                                        <Card color="light">
                                                            <h3 className='card-header font-16 mt-0'>Storage Service {this.state.storage_name}</h3>
                                                            
                                                            <CardBody>
                                                                <CardText> Your plan will be automatically canceled  at {Moment(new Date(this.state.storage_payload.canceledAt * 1000).toLocaleDateString("en-US")).format('YYYY-MM-DD')} </CardText>
                                                            </CardBody>
                                                        </Card>
                                                    }
                                                
                                            </Col>
                                            }
                                                    
                                                   


                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="8" className="p-3">
                                        <h4>Social campaigns CPM</h4>
                                        <Row>
                                            <Col>
                                                <h6>Social Rate: $ {this.state.socialRate}</h6>
                                            </Col>
                                            <Col>
                                                <h6>Programmatic Rate: ${this.state.programmaticRate}</h6>
                                            </Col>
                                        </Row>
                                        <hr></hr>
                                        <h4>Current Period</h4>
                                        <Row>
                                            <div className="table-responsive" style={{marginTop: 20}}>
                                                <Table className="table table-striped mb-0">

                                                    <thead>
                                                        <tr>
                                                            <th>Project</th>
                                                            <th>Type</th>
                                                            <th>Views</th>
                                                            <th>Budget</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {  this.state.billing_projects.length === 0 ? 
                                                        <td>No records</td>
                                                        :
                                                            this.state.billing_projects.map((bill) => (
                                                            <tr>
                                                                <td>{bill.name}</td>
                                                                <td>{bill.type}</td>
                                                                <td>{bill.views}</td>
                                                                <td>${bill.amount}</td>
                                                            </tr>
                                                        ))
                                                        }
                                                        <tr>
                                                                <th>Total</th>
                                                                <td></td>
                                                                <td>{this.state.totalViews_bill}</td>
                                                                <td>${this.state.totalAmount_bill}</td>
                                                            </tr>
                                                    </tbody>
                                                </Table>
                                            </div> 
                                        </Row>
                                        <hr></hr>
                                        <br/>
                                        <br/>
                                        <Row>
                                            <div className='col-lg-6'>
                                            <h4>Billing Invoices</h4>

                                            </div>
                                            <div className='text-right col-lg-6' style={{display:"flex",justifyContent:"flex-end",marginBlockEnd:"25px",color:"#8699AD"}}>
                                            <p><button onClick={()=>this.decrementYear()} style={{background:"none",border:"none",color:"white"}}>{"< "}</button></p>
                                            <p style={{marginLeft:"5px",marginRight:"5px", fontSize:"12px !important"}}> {this.state.invoice_year} </p>
                                            <p><button onClick={()=>this.incrementYear()} style={{background:"none",border:"none",color:"white"}}>{" >"}</button></p>
                                            </div>
                                            
                                        </Row>
                                        
                                        <Row>
                                            <div className="table-responsive" style={{marginTop: 20}}>
                                                <Table className="table table-striped mb-0">

                                                    <thead>
                                                        <tr>
                                                            <th>Period</th>
                                                            <th>Status</th>
                                                            <th>Views</th>
                                                            <th>Budget</th>
                                                            <th>Invoice</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {  this.state.invoice_array.length === 0 ? 
                                                        <td>User doesn't have any invoices</td>
                                                        :
                                                            this.state.invoice_array.map((row) => (
                                                            <tr>
                                                                <td>{ Moment(row.period).format("MMM YYYY")}</td>
                                                                <td>{row.status === "PAID" ? <p style={{color:"rgb(43 251 23)"}}>Paid</p>:<p style={{color:"rgb(230, 64, 93)"}}>Not paid</p>}</td>
                                                                <td>{row.views}</td>
                                                                <td>${row.budget}</td>
                                                                <td>{row.link === null ? "---" : <a id="aTag" href={row.link} target="blank">View Invoice</a>}</td>
                                                            
                                                            </tr>
                                                        ))
                                                        }
                                                        
                                                        
                                                    </tbody>
                                                </Table>
                                            </div> 
                                        </Row>
                                        
                                    </TabPane>
                                </TabContent>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {/* Rename Project */}

                <Row>
                    <Modal isOpen={
                        this.state.modal_edit
                    }
                        toggle={
                            this.tog_edit
                        }
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">
                        <ModalHeader className="form-modal-header" toggle={
                            this.tog_edit
                        }>
                            Edit Details
                        </ModalHeader>
                        <ModalBody className="form-modal-body">
                            <FormGroup row className="formContainer" style={{flexDirection: "column"}}>
                                <div style={{padding: "0 0 10px 10px"}}>
                                    <Label for="example-text-input" className="col-form-label">Project Name</Label>
                                </div>
                                <Col>
                                    <Input className="" name="projectName"
                                        value={
                                            this.state.projectName
                                        }
                                        type="text"
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-text-input" />
                                </Col>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter className="form-modal-header">
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons"
                                onClick={
                                    this.tog_edit
                                }>Close
                            </Button>
                            {
                                this.state.projectName === this.state.prevProjectName ?
                                    <Button type="button" color="primary"
                                        onClick={
                                            () => this.renameProject()
                                        }
                                        disabled
                                        className="waves-effect waves-light">Save Changes
                                    </Button>
                                    : <Button type="button" color="primary"
                                        onClick={
                                            () => this.renameProject()
                                        }
                                        className="waves-effect waves-light">Save changes
                                    </Button>
                            }

                        </ModalFooter>
                    </Modal>
                </Row>
                {/* Shopify */}
                <Row>
                    <Modal isOpen={
                        this.state.modal_shopify
                    }
                        toggle={
                            this.tog_shopify
                        }
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">
                        <ModalHeader className="form-modal-header">
                            Integration Details
                        </ModalHeader>
                        <ModalBody className="form-modal-body">
                            <FormGroup row className="formContainer2">
                                <Label for="example-text-input" className="col-form-label">Name</Label>
                                <Col sm="10"> 
                                            <Input className="form-control" name="shopifyName" disabled
                                        value={
                                            this.state.shopifyName
                                        }
                                        type="text"
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-text-input" 
                                    />    
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-text-input" className="col-form-label">Shop Url</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="shopifyUrl" disabled
                                        value={
                                            this.state.shopifyUrl
                                        }
                                        type="text"
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-text-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-text-input" className="col-form-label">Api Key</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="apiKey" disabled
                                        value={
                                             this.state.apiKey 
                                        }
                                        type="text"
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-text-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-tel-input" className="col-form-label">Store Access Token</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="storefrontAccessToken" type="text" disabled
                                        value={
                                            this.state.storefrontAccessToken
                                        }
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-search-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-tel-input" className="col-form-label">Api Password</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="apiPassword" type="text" disabled
                                        value={
                                           this.state.apiPassword
                                        }
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-search-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-tel-input" className="col-form-label">Shared Secret</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="sharedSecret" type="text" disabled
                                        value={
                                            this.state.sharedSecret
                                        }
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-search-input" />
                                </Col>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter className="form-modal-header">
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons"
                                onClick={
                                    () => this.tog_shopify(this.state.shopify_credential, this.state.shopifyName, this.state.shopifyUrl)
                                }>Close
                            </Button>

                        </ModalFooter>
                    </Modal>
                </Row>

                {/* Stripe */}
                <Row>
                    <Modal isOpen={
                        this.state.modal_stripe
                    }
                        toggle={
                            this.tog_stripe
                        }
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">
                        <ModalHeader className="form-modal-header">
                            Integration Details
                        </ModalHeader>
                        <ModalBody className="form-modal-body">
                            <FormGroup row className="formContainer2">
                                <Label for="example-text-input" className="col-form-label">Name</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="stripeName" disabled
                                        value={
                                            // typeof this.state.stripe == 'object' ? this.state.stripe.name : null
                                            this.state.stripeName
                                        }
                                        type="text"
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-text-input" />
                                </Col>
                            </FormGroup>
                            
                            <FormGroup row className="formContainer2">
                                <Label for="example-text-input" className="col-form-label">Secret key</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="privateKey" disabled
                                        value={
                                            this.state.privateKey
                                        }
                                        type="text"
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-text-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-tel-input" className="col-form-label">Publishable Key</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="publicKey" type="text" disabled
                                        value={
                                            this.state.publicKey
                                        }
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-search-input" />
                                </Col>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter className="form-modal-header">
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons"
                                onClick={
                                    () => this.tog_stripe(this.state.stripCredential, this.state.stripeName, this.state.stripeUrl)
                                }>Close
                            </Button>

                        </ModalFooter>
                    </Modal>
                </Row>

                {/* WooCommerce */}
                <Row>
                    <Modal isOpen={
                        this.state.modal_woocommerce
                    }
                        toggle={
                            this.tog_woocommerce
                        }
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">
                        <ModalHeader className="form-modal-header">
                            Integration Details
                        </ModalHeader>
                        <ModalBody className="form-modal-body">
                            <FormGroup row className="formContainer2">
                                <Label for="example-text-input" className="col-form-label">Name</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="woocommerceName" disabled
                                        value={
                                            this.state.woocommerceName
                                        }
                                        type="text"
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-text-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-text-input" className="col-form-label">Shop Url</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="woocommerceUrl" disabled
                                        value={
                                            this.state.woocommerceUrl
                                        }
                                        type="text"
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-text-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-text-input" className="col-form-label">Consumer Secret</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="consumerSecret" disabled
                                        value={
                                            this.state.consumerSecret
                                        }
                                        type="text"
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-text-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-tel-input" className="col-form-label">Consumer Key</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="consumerKey" type="text" disabled
                                        value={
                                            this.state.consumerKey
                                        }
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-search-input" />
                                </Col>
                            </FormGroup>

                        </ModalBody>
                        <ModalFooter className="form-modal-header">
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons"
                                onClick={
                                    () => this.tog_woocommerce(this.state.woocommerceCredential, this.state.woocommerceName, this.state.woocommerceUrl)
                                }>Close
                            </Button>

                        </ModalFooter>
                    </Modal>
                </Row>

                {/* BigCommerce */}
                <Row>
                    <Modal isOpen={
                        this.state.modal_bigcommerce
                    }
                        toggle={
                            this.tog_bigcommerce
                        }
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">
                        <ModalHeader className="form-modal-header">
                            Integration Details
                        </ModalHeader>
                        <ModalBody className="form-modal-body">
                            <FormGroup row className="formContainer2">
                                <Label for="example-text-input" className="col-form-label">Name</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="bigcommerceUrl" disabled
                                        value={
                                            this.state.bigcommerceName
                                        }
                                        type="text"
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-text-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-text-input" className="col-form-label">Shop Url</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="bigcommerceName" disabled
                                        value={
                                            this.state.bigcommerceUrl
                                        }
                                        type="text"
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-text-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-text-input" className="col-form-label">Client ID</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="clientId" disabled
                                        value={
                                            this.state.clientId
                                        }
                                        type="text"
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-text-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-tel-input" className="col-form-label">Client Secret</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="clientSecret" type="text" disabled
                                        value={
                                            this.state.clientSecret
                                        }
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-search-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-tel-input" className="col-form-label">Api Path</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="apiPath" type="text" disabled
                                        value={
                                            this.state.apiPath
                                        }
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-search-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-tel-input" className="col-form-label">Access Token</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="accessToken" type="text" disabled
                                        value={
                                            this.state.accessToken
                                        }
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-search-input" />
                                </Col>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter className="form-modal-header">
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons"
                                onClick={
                                    () => this.tog_bigcommerce(this.state.bigcommerceCredential, this.state.bigcommerceName, this.state.bigcommerceUrl)
                                }>Close
                            </Button>

                        </ModalFooter>
                    </Modal>
                </Row>

                {/* Amazon */}
                <Row>
                    <Modal isOpen={
                        this.state.modal_amazon
                    }
                        toggle={
                            this.tog_amazon
                        }
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">
                        <ModalHeader className="form-modal-header">
                            Integration Details
                        </ModalHeader>
                        <ModalBody className="form-modal-body">
                            <FormGroup row className="formContainer2">
                                <Label for="example-text-input" className="col-form-label">Name</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="amazonName" disabled
                                        value={
                                            this.state.amazonName
                                        }
                                        type="text"
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-text-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-text-input" className="col-form-label">Shop Url</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="amazonUrl" disabled
                                        value={
                                            this.state.amazonUrl
                                        }
                                        type="text"
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-text-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-text-input" className="col-form-label">Patner Tag</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="partnerTag" disabled
                                        value={
                                            this.state.partnerTag
                                        }
                                        type="text"
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-text-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-tel-input" className="col-form-label">Secret Key</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="secretKey" type="text" disabled
                                        value={
                                            this.state.secretKey
                                        }
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-search-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-tel-input" className="col-form-label">Market Place</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="marketplace" type="text" disabled
                                        value={
                                            this.state.marketplace
                                        }
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-search-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-tel-input" className="col-form-label">Access Key</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="accessKey" type="text" disabled
                                        value={
                                            this.state.accessKey
                                        }
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-search-input" />
                                </Col>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter className="form-modal-header">
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons"
                                onClick={
                                    () => this.tog_amazon(this.state.amazonCredential ,this.state.amazonName, this.state.amazonUrl)
                                }>Close
                            </Button>

                        </ModalFooter>
                    </Modal>
                </Row>

                <Row>
                    <Modal isOpen={
                        this.state.modal_add_cal
                    }
                        toggle={
                            this.tog_add_cal
                        }
                        autoFocus={true}
                        size="lg">
                        <ModalHeader toggle={
                            this.tog_add_cal2
                        }>
                            Select Date
                        </ModalHeader>
                        <ModalBody>
                        {this.state.DateAlert ?  
                        this.disableToast()
                        : Moment(this.state.fromDate).isAfter(Moment(this.state.toDate)) || Moment(this.state.fromDate).isAfter(Moment()) || Moment(this.state.toDate).isAfter(Moment())?
                        this.disableToast()
                          : null}
                            <Row>
                                <Col>
                                    <Calendar 
                                        onChange={this.handleDatechange} 
                                        // value={this.state.fromDate} 
                                    />
                                </Col>
                                <Col>
                                    <Calendar 
                                        onChange={this.handleToDatechange} 
                                        // value={this.state.toDate} 
                                    />
                                </Col>
                            </Row>                                                   
                        </ModalBody>
                        <ModalFooter>
                        {
                                 this.state.DateAlert === true || this.state.fromDate === '' || this.state.toDate === ''? 
                                 <Button type="button" color="primary" className="waves-effect" disabled
                                    onClick={
                                        ()=>this.getAllProjects(this.state.sub)
                                    }>Save
                                </Button> 
                                : 
                                Moment(this.state.fromDate).isAfter(Moment(this.state.toDate)) || Moment(this.state.fromDate).isAfter(Moment()) || Moment(this.state.toDate).isAfter(Moment())?
                                <Button type="button" color="primary" className="waves-effect" disabled
                                    onClick={
                                        ()=>this.getAllProjects(this.state.sub)
                                    }>Save
                                </Button> :
                                <Button type="button" color="primary" className="waves-effect"
                                    onClick={
                                        ()=>this.getAllProjects(this.state.sub)
                                    }>{
                                        this.state.isLoadingSave === true ? 
                                            <div style={{display: "flex", justifyContent: "center"}}>
                                                <RotatingLines
                                                    strokeColor="white"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="10"
                                                    visible={this.state.isLoadingSave}
                                                />
                                            </div>
                                        : "Save"
                                    }
                                </Button>
                            }
                        </ModalFooter>
                    </Modal>
                </Row>
                <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />

            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(UserDetails);
