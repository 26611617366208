import React, {Component} from 'react';
import {connect} from "react-redux";
import {Row, 
    Col, 
    Card, 
    CardBody, 
    Button, 
    Label,
    Alert,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    Input
} from "reactstrap";

import { MDBDataTable } from 'mdbreact';
import Moment from "moment";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {setBreadcrumbItems} from "../../store/actions";
import url from "../../helpers/apiUrl"
import { RotatingLines } from  'react-loader-spinner'

// import { ExcelExport } from "@progress/kendo-react-excel-export";
import SpicyDatatable from 'spicy-datatable';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Sales extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                {
                    title: "One Dash",
                    link: "dashboard"
                }, {
                    title: "Sales Report",
                    link: "#"
                },

            ],
            modal_add: false,
            fromDate: '',
            toDate: '',
            created_at_filter: '',
            subscription: [],
            fileName: "Sales.csv",
            DateAlert: false,
            isLoadingSave: false
        }
        this.getSales = this.getSales.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.searchByUsername = this.searchByUsername.bind(this);
        // this.searchProduct = this.searchProduct.bind(this);
        this.changeHandlerFromDate = this.changeHandlerFromDate.bind(this);
        this.changeHandlerToDate = this.changeHandlerToDate.bind(this);
        this.tog_add = this.tog_add.bind(this);
        this.tog_add2 = this.tog_add2.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.disableToast = this.disableToast.bind(this);

    }

    componentDidMount() {
        this.props.setBreadcrumbItems("Sales", this.state.breadcrumbItems);
        if (localStorage.getItem("token") === null) {
            return (
                this.props.history.push('/login')
                // <Redirect to={{ pathname: "/login" }} exact />
            );
        }
        this.getSales()
    }

    

    getSales(){
        this.setState({isLoadingSave : true})

        var date = this.state.created_at_filter;
        var fromDate = Moment(new Date()).format('YYYY-MM-DD')
        var toDate = Moment(new Date()).format('YYYY-MM-DD')
        if (date === "custom2") {
            fromDate = Moment(this.state.fromDate).format('YYYY-MM-DD')
            toDate = Moment(this.state.toDate).format('YYYY-MM-DD')
        } else if (date !== "") {
            if(date === "1"){
                fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
                toDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            }else{
            fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            toDate = Moment().format('YYYY-MM-DD')
            }
        }
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
            "fromDate": fromDate,
            "toDate": toDate
            });
            console.log(raw, "raw")

            var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
            };

            fetch(`${url}/sales`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result.error === "Invalid Token"){
                    localStorage.removeItem('token');
                    sessionStorage.clear();
                    this.props.history.push('/login') 
                }
                console.log(result)
                var array =[]
                if(result.statusCode === "200"){
                    this.setState({isLoadingSave : false})

                    if(this.state.modal_add === true){
                        this.setState(prevState => ({
                            modal_add: false
                        }));
                    }
                    for(let i = 0; i < result.body.length; i++){
                        array.push({
                            name: result.body[i]._source.plan.name,
                            currency: result.body[i]._source.plan.currency,
                            price: (result.body[i]._source.plan.price / 100).toFixed(2) ,
                            interval: result.body[i]._source.plan.frequency,
                            paymentSystem: result.body[i]._source.paymentSystem,
                            id: result.body[i]._source.plan.id,
                            status: result.body[i]._source.status,
                            date: Moment(result.body[i]._source.metadata.updatedAt).format('YYYY/MM/DD HH:mm:ss'),
                            // nextBillingTime: result.body[i]._source.nextBillingTime,
                            // cancelAtPeriodEnd: result.body[i]._source.payload.cancelAtPeriodEnd,
                            next_renewal_date: result.body[i]._source.payload.cancelAtPeriodEnd === null ? "Renews at " + Moment(new Date(result.body[i]._source.nextBillingTime*1000)).format('YYYY/MM/DD HH:mm:ss') : result.body[i]._source.payload.cancelAtPeriodEnd === true ? "Cancels at " + Moment(new Date(result.body[i]._source.nextBillingTime*1000)).format('YYYY-MM-DD, h:mm:ss a') : "-",
                            // created_at_filter: Moment(result.body[i]._source.metadata.createdAt).format('YYYY-MM-DD, h:mm:ss a'),
                            
                        })
                    }
                }else{
                    toast(<div className='toast-message-container'>
                    <h6>Sales</h6>
                    <p>Request timed out.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-error'
                 })
                }
                this.setState({ subscription: array })
            })
            .catch(error => console.log('error', error));
        
    }

    searchByUsername(e){
        if (e.target.value === null || e.target.value === "" || e.target.value === undefined) {
            return this.viewAllProducts
        }
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "username": e.target.value
        });
        console.log("search", raw)

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/sales/user-report`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            var array =[]
            if(result.statusCode === "200"){
                for(let i = 0; i < result.body.length; i++){
                    array.push({
                        name: result.body[i]._source.plan.name,
                        currency: result.body[i]._source.plan.currency,
                        price: (result.body[i]._source.plan.price / 100).toFixed(2) ,
                        interval: result.body[i]._source.plan.frequency,
                        paymentSystem: result.body[i]._source.paymentSystem,
                        id: result.body[i]._source.plan.id,
                        status: result.body[i]._source.status,
                        // nextBillingTime: result.body[i]._source.nextBillingTime,
                        // cancelAtPeriodEnd: result.body[i]._source.payload.cancelAtPeriodEnd,
                        next_renewal_date: result.body[i]._source.payload.cancelAtPeriodEnd === null ? "Renews at " + Moment(new Date(result.body[i]._source.nextBillingTime*1000)).format('YYYY/MM/DD HH:mm:ss') : result.body[i]._source.payload.cancelAtPeriodEnd === true ? "Cancels at " + Moment(new Date(result.body[i]._source.nextBillingTime*1000)).format('YYYY-MM-DD, h:mm:ss a') : "-",
                        // created_at_filter: Moment(result.body[i]._source.metadata.createdAt).format('YYYY-MM-DD, h:mm:ss a'),
                        date: Moment(result.body[i]._source.metadata.updatedAt).format('YYYY/MM/DD HH:mm:ss'),
                    })
                }
            }
            this.setState({ subscription: array })
        })
        .catch(error => console.log('error', error));
    }

    exportExcel()  {
        const fileType = "xlsx";
        // const fileExtension = ".xlsx";
        console.log("Enter the function")
        const ws = XLSX.utils.json_to_sheet(this.state.subscription);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        FileSaver.saveAs(data, "SalesReport" + ".xlsx");
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        if(e.target.value === "custom"){
            this.setState({ [e.target.name]: "custom2",fromDate:'',toDate:'' })
            this.tog_add()
        }
        
        else{
            setTimeout(() => {
                this.getSales()
            }, 1500);
        }
       
        
    }

    tog_add() {
        this.setState(prevState => ({
            modal_add: !prevState.modal_add

        }));
    }
    tog_add2() {
        this.setState(prevState => ({
            modal_add: !prevState.modal_add,fromDate:'',toDate:'',created_at_filter:0,DateAlert:false
        }));
        setTimeout(() => {
            this.getSales()
        }, 1500);
        
    }

    changeHandlerFromDate = (value) => {
        var dateofvisit = Moment(value, 'DD-MM-YYYY');
        var today = Moment();
        if(today.diff(dateofvisit, 'Hours') <= 0){
            this.setState({
                DateAlert: true,fromDate: Moment(value).format("YYYY-MM-DD")
            })
            
            return  
        }else{
            this.setState({
                DateAlert: false
            }) 
            this.setState({fromDate: Moment(value).format("YYYY-MM-DD")})
        }
        
    }
    changeHandlerToDate = (value) => {
        var dateofvisit = Moment(value, 'DD-MM-YYYY');
        var today = Moment();
        
        if(today.diff(dateofvisit, 'Hours') <= 0){
            this.setState({
                DateAlert: true,toDate: Moment(value).format("YYYY-MM-DD")
            })
            
            return  
        }else if(this.state.fromDate !== ""){
            if(Moment(this.state.fromDate, 'YYYY-MM-DD').diff(dateofvisit, 'Hours') > 0){
                this.setState({
                    DateAlert: true,toDate: Moment(value).format("YYYY-MM-DD")
                })
            }else{
                this.setState({
                    DateAlert: false
                })
                this.setState({toDate: Moment(value).format("YYYY-MM-DD")})
            }
        }else{
            this.setState({
                DateAlert: false
            })
            this.setState({toDate: Moment(value).format("YYYY-MM-DD")})

        }
    }
    disableToast(){
        let toastDiv = document.querySelector('.toast-error');
        if(toastDiv == null){
            toast(<div className='toast-message-container'>
            <h6>Sales</h6>
            <p>Please select a valid date.</p>
             </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
         })
        }
    }


    render() {
        const data = {
            columns: [
                {
                    label: 'Name',
                    field: 'name',
                    width: 150
                },
                {
                    label: 'Currency',
                    field: 'currency',
                    width: 270
                },
                {
                    label: 'Price',
                    field: 'price',
                    width: 270
                },
                
                {
                    label: 'Interval',
                    field: 'interval',
                    width: 270
                },
                {
                    label: 'Payment',
                    field: 'paymentSystem',
                    width: 200
                },
                {
                    label: 'ID',
                    field: 'id',
                    width: 150
                }, {
                    label: 'Status',
                    field: 'status',
                    width: 100
                },
                {
                    label: 'Date',
                    field: 'date',
                    width: 100
                },
                {
                    label: 'Next Renewal Date',
                    field: 'next_renewal_date',
                    width: 100
                }
                
            ],
            rows: this.state.subscription
        };

        
        return (
            <React.Fragment>
                <Row style={{marginBottom: 20}}>
                    <Col xs="8">
                    <Col lg="6">
                    <Form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                            <Input onChange={this.searchByUsername} type="text" className="form-control" placeholder="Search by username..." />
                            <span className="fa fa-search"></span>
                        </div>
                    </Form>
                </Col>
                    </Col>
                    <Col xs="4">
                        <Col>
                            <select className="form-control" name='created_at_filter'
                                value={
                                    this.state.created_at_filter
                                }
                                onChange={
                                    this.changeHandler
                                }>
                                <option value="0">Today</option>
                                <option value="1">Yesterday</option>
                                <option value="7">Last 7 days</option>
                                <option value="30">Last 30 days</option>
                                <option value="180">Last 6 months</option>
                                <option value="custom" onClick={this.tog_add}>Custom</option>
                                <option value='custom2' hidden>{ this.state.created_at_filter === "custom2" ? "From: " + this.state.fromDate+ " To: " + this.state.toDate: "Custom"}</option>
                            </select>
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                               <Button color="primary" onClick ={() => this.exportExcel()}>Export</Button>
                                
                                    
                                    <MDBDataTable 
                                        style={{marginTop: 10}}
                                        id="table-to-xls"
                                        responsive bordered striped
                                        data={data}
                                    />
                              
                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Modal isOpen={
                        this.state.modal_add
                    }
                        toggle={
                            this.tog_add
                        }
                        autoFocus={true}
                        size="lg">
                        <ModalHeader toggle={
                            this.tog_add2
                        }>
                            Select Date
                        </ModalHeader>
                        <ModalBody>
                        {this.state.DateAlert ?  
                        this.disableToast()
                        : Moment(this.state.fromDate).isAfter(Moment(this.state.toDate)) || Moment(this.state.fromDate).isAfter(Moment()) || Moment(this.state.toDate).isAfter(Moment())?
                        this.disableToast()
                          : null}
                            <Row>
                                <Col>
                                    <Calendar 
                                         onChange={this.changeHandlerFromDate}
                                    />
                                </Col>
                                <Col>
                                    <Calendar  
                                        onChange={this.changeHandlerToDate} 
                                    />
                                </Col>
                            </Row>                                                   
                        </ModalBody>
                        <ModalFooter>
                        {
                                 this.state.DateAlert === true || this.state.fromDate === '' || this.state.toDate === ''? 
                                 <Button type="button" color="primary" className="waves-effect" disabled
                                    onClick={
                                        this.getSales
                                    }>Save
                                </Button> 
                                : 
                                Moment(this.state.fromDate).isAfter(Moment(this.state.toDate)) || Moment(this.state.fromDate).isAfter(Moment()) || Moment(this.state.toDate).isAfter(Moment())?
                                <Button type="button" color="primary" className="waves-effect" disabled
                                    onClick={
                                        this.getSales
                                    }>Save
                                </Button> :
                                <Button type="button" color="primary" className="waves-effect"
                                    onClick={
                                        this.getSales
                                    }>{
                                        this.state.isLoadingSave === true ? 
                                            <div style={{display: "flex", justifyContent: "center"}}>
                                                <RotatingLines
                                                    strokeColor="white"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="10"
                                                    visible={this.state.isLoadingSave}
                                                />
                                            </div>
                                        : "Save"
                                    }
                                </Button>
                            }
                            
                        </ModalFooter>
                    </Modal>
                </Row>
                <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />
            </React.Fragment>
        );
    }
}

export default connect(null, {setBreadcrumbItems})(Sales);
