
import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    Col,
    Row,
    Card,
    CardBody,
    FormGroup,
    Label,
    Button,
    Alert,
    UncontrolledTooltip
} from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";
import { setBreadcrumbItems } from "../../store/actions";
import { MDBDataTable } from 'mdbreact';
import Moment from "moment"
import url from "../../helpers/apiUrl"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ListContacts extends Component {
    
    constructor(props) {
        if (localStorage.getItem("token") === null) {
            console.log(localStorage.getItem("token"))
            return (
                this.props.history.push('/login')
            );
        }
        super(props);
        const { list } = this.props.location.state
        this.state = {
            breadcrumbItems: [
                {
                    title: "One Dash",
                    link: "dashboard"
                }, 
                {
                    title: "List Management",
                    link: "list-management"
                },{
                    title: "List Contacts",
                    link: "#"
                },
            ], 
            id: list.id,
            contacts: [],
            alert_confirm: false,
            contact_last_name: '',
            contact_last_name: '',
            user_id: ''
        };
        this.viewContacts = this.viewContacts.bind(this);
        this.removeContact = this.removeContact.bind(this);
    }
    

    componentDidMount() {
        this.props.setBreadcrumbItems("List Contacts", this.state.breadcrumbItems);
        
            this.viewContacts()
            console.log(this.state.id)
        
        
    }

    viewContacts(){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "list_id": this.state.id
        });
        console.log("Contacts", raw)

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/contacts/viewbylist`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.error === "Invalid Token"){
                localStorage.removeItem('token');
                sessionStorage.clear();
                this.props.history.push('/login') 
            }
            console.log("res", result)
            var array = []
            if(result.statusCode === "200"){
                for(let i = 0; i < result.body.length; i++){
                    array.push({
                        id:  result.body[i].id,
                        contact_list_id: result.body[i].list_id,
                        contact_first_name: result.body[i].contact_first_name,
                        contact_last_name: result.body[i].contact_last_name,
                        contact_country: result.body[i].contact_country,
                        contact_email: result.body[i].contact_email,
                        contact_mobile: result.body[i].contact_mobile,
                        contact_status: result.body[i].contact_status,
                        contact_created_at: Moment(result.body[i].contact_created_at).format('YYYY/MM/DD HH:mm:ss'),
                        contact_updated_at: Moment(result.body[i].contact_updated_at).format('YYYY/MM/DD HH:mm:ss'),
                        button: <> <Button type="button" color="danger"
                                        onClick={
                                            () => this.setState({ contact_first_name:  result.body[i].contact_first_name, contact_last_name:  result.body[i].contact_last_name, alert_confirm: true, user_id: result.body[i].id })
                                        }
                                        className="waves-effect waves-light buttons-secondary"
                                        id="tooltip_delete_campaign"><i className="ti-trash"></i>
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target="tooltip_delete_campaign">
                                        Delete 
                                    </UncontrolledTooltip>
                                </>
                    })
                }
                this.setState({ contacts: array })
            }
            else{
                this.setState({ contacts: [] })
            }
        })
        .catch(error => console.log('error', error));
    }

    removeContact (){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(`${url}/contacts/removelist/` + this.state.user_id, requestOptions)
        .then(response => response.json())
        .then(result => {
            toast("Contact removed successfully", {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"22px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-success'
            });
            this.viewContacts()
        })
        .catch(error => console.log('error', error));
    }

    
       
    render() {
        const data = {
            columns: [
                
                {
                    label: 'ID',
                    field: 'id',
                    width: 150
                },
                {
                    label: 'First Name',
                    field: 'contact_first_name',
                    width: 200
                },
                {
                    label: 'Last Name',
                    field: 'contact_last_name',
                    width: 10
                }, 
                {
                    label: 'Country',
                    field: 'contact_country',

                    width: 10
                },
                {
                    label: 'Email',
                    field: 'contact_email',
                    width: 10
                },
                {
                    label: 'Phone No.',
                    field: 'contact_mobile',
                    sort: "disabled",
                    width: 10
                },
                {
                    label: 'Status',
                    field: 'contact_status',
                    width: 10
                },  
                {
                    label: 'Created At',
                    field: 'contact_created_at',
                    width: 150
                }, {
                    label: 'Updated At',
                    field: 'contact_updated_at',
                    width: 100
                },
                {
                    label: 'Action',
                    field: 'button',
                    sort: 'disabled',
                    width: 100
                }
            ],
            rows: this.state.contacts
        };
        return (
            <React.Fragment>
                {this.state.alert_confirm ? (
                    
                    <div className="modal" style={{display:"block"}} tabIndex="-1" role="dialog" id='alertBox'>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Delete Contact</h5>
                                
                            </div>
                            <div className="modal-body">
                                <p>{ "Are you sure you want to remove " + " " + this.state.contact_first_name +  " " + this.state.contact_last_name + "?"}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" style={{background:"#FFFFFF0D", borderColor:"#2f2f34"}} className="btn" data-dismiss="modal"
                                onClick={() => {
                                    this.setState({
                                        alert_confirm: false,
                                    });
                                    document.getElementById('alertBox').style.display = 'none';
                                }} 
                                >Cancel</button>
                                <button type="button" className="btn"
                                onClick={() => {
                                    this.setState({ alert_confirm: false })
                                    this.removeContact();
                                    document.getElementById('alertBox').style.display = 'none';
                                }}
                                >Delete</button>
                            </div>
                            </div>
                        </div>
                    </div>

                ) : null}
                

              <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <MDBDataTable responsive bordered striped
                                data={data}
                            />
                        </CardBody>
                    </Card>
                </Col>
              </Row>
              <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(ListContacts);