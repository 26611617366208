
import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    Col,
    Row,
    Card,
    CardBody,
    FormGroup,
    Label,
    Button,
    Alert,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    UncontrolledTooltip
} from "reactstrap";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { MDBDataTable } from 'mdbreact';
import { AvForm, AvField } from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import Moment from "moment"
import { Link } from "react-router-dom";
import url from "../../helpers/apiUrl";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { RotatingLines } from  'react-loader-spinner'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class TemplateManagemnet extends Component {
    constructor(props) {
        super(props);
   
        this.state = {
            breadcrumbItems: [
                {
                    title: "One Dash",
                    link: "dashboard"
                },
                {
                    title: "Campaign",
                    link: "campaign"
                }, {
                    title: "Template",
                    link: "#"
                },
            ],
            success_confirm: false,
            alert_confirm: false,
            dynamic_title: "",
            dynamic_description: "",
            templates: [],
            id: '',
            template: '',
            template_created_at: '',
            templated_updated_at: '',
            template_type: '',
            template_type_filter: '',
            template_status_filter: '',
            template_status: '',
            created_at_filter: '',
            fromDate: '',
            toDate: '',
            modal_add_cal: false,
            DateAlert:false,
            isLoadingSave: false,
        };
        this.viewTemplates = this.viewTemplates.bind(this);
        this.deleteTemplate = this.deleteTemplate.bind(this);
        this.fillTemplate = this.fillTemplate.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.tog_add_cal = this.tog_add_cal.bind(this);
        this.tog_add_cal2 = this.tog_add_cal2.bind(this);
        this.handleDatechange = this.handleDatechange.bind(this);
        this.handleToDatechange = this.handleToDatechange.bind(this);
        this.disableToast = this.disableToast.bind(this);

    }
    

    componentDidMount() {
        this.props.setBreadcrumbItems("Template Management", this.state.breadcrumbItems);
        if (localStorage.getItem("token") === null) {
            return (
                this.props.history.push('/login')
                // <Redirect to={{ pathname: "/login" }} exact />
            );
        }
        this.viewTemplates()
    }

    tog_add_cal() {
        this.setState(prevState => ({
            modal_add_cal: !prevState.modal_add_cal
        }));
        console.log("Hello WOrld")
    }

    tog_add_cal2() {
        this.setState(prevState => ({
            modal_add_cal: !prevState.modal_add_cal,fromDate:'',toDate:'',created_at_filter:"",DateAlert:false

        }));
        setTimeout(() => {
            this.viewTemplates()
        }, 1500);
    }
    handleDatechange(value){
        var dateofvisit = Moment(value, 'DD-MM-YYYY');
        var today = Moment();
        if(today.diff(dateofvisit, 'Hours') <= 0){
            this.setState({
                DateAlert: true,fromDate: Moment(value).format("YYYY-MM-DD")
            })
            
            return  
        }else{
            this.setState({
                DateAlert: false
            }) 
            this.setState({fromDate: Moment(value).format("YYYY-MM-DD")})
        }
    }
    handleToDatechange(value){
        var dateofvisit = Moment(value, 'DD-MM-YYYY');
        var today = Moment();
        
        if(today.diff(dateofvisit, 'Hours') <= 0){
            this.setState({
                DateAlert: true,toDate: Moment(value).format("YYYY-MM-DD")
            })
            
            return  
        }else if(this.state.fromDate !== ""){
            if(Moment(this.state.fromDate, 'YYYY-MM-DD').diff(dateofvisit, 'Hours') > 0){
                this.setState({
                    DateAlert: true,toDate: Moment(value).format("YYYY-MM-DD")
                })
            }else{
                this.setState({
                    DateAlert: false
                })
                this.setState({toDate: Moment(value).format("YYYY-MM-DD")})
            }
        }else{
            this.setState({
                DateAlert: false
            })
            this.setState({toDate: Moment(value).format("YYYY-MM-DD")})

        }
    }


    viewTemplates(){
        this.setState({isLoadingSave : true})
        var date = this.state.created_at_filter;
        var fromDate = ''
        var toDate = ''
        if (date === "custom2") {
            fromDate = Moment(this.state.fromDate).format('YYYY-MM-DD')
            toDate = Moment(this.state.toDate).format('YYYY-MM-DD')
        } else if (date !== "") {
            if(date === "1"){
                fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
                toDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            }else{
            fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            toDate = Moment().format('YYYY-MM-DD')
            }
        }

        
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "template_type": this.state.template_type_filter,
            "template_status": this.state.template_status_filter,
            "from_date": fromDate,
            "to_date": toDate
          });
          console.log("filter temp",raw)

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/templates/view/`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.error === "Invalid Token"){
                localStorage.removeItem('token');
                sessionStorage.clear();
                this.props.history.push('/login') 
            }
            var arr = []
            if(result.statusCode === "200"){
                this.setState({isLoadingSave : false})
                
                if(this.state.modal_add_cal === true){
                    this.setState(prevState => ({
                        modal_add_cal: false
                    }));
                }  
                for(let i =0; i < result.body.length; i++){
                    // console.log(result.body[1].id)
                    arr.push({
                        id: result.body[i].id,
                        template_name: result.body[i].template_name,
                        template_description: result.body[i].template_description,
                        template_type: result.body[i].template_type,
                        template_content: result.body[i].template_content,
                        template_status: result.body[i].template_status,
                        template_created_at: Moment(result.body[i].template_created_at).format('YYYY/MM/DD HH:mm:ss'),
                        templated_updated_at: Moment(result.body[i].templated_updated_at).format('YYYY/MM/DD HH:mm:ss'),
                        button: <div style={{display: "flex", justifyContent: "center"}}>
                            <Link to={{
                                    pathname: "/update-template",
                                    state: {
                                        template: result.body[i]
                                    }
                                }}>
                                    <Button type="button"
                                        onClick={
                                            () => this.fillTemplate(result.body[i])
                                        }
                                        style={
                                            { marginRight: 10}
                                        }
                                        color="primary" id="tooltip_edit_template"
                                        className="waves-effect waves-light buttons-secondary">
                                        <i className="ti-pencil"></i>
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target="tooltip_edit_template">
                                        Edit 
                                    </UncontrolledTooltip>
                                </Link>
                            <Button type="button" color="danger"
                                onClick={
                                    () => this.setState({ template_name: result.body[i].template_name, alert_confirm: true, id: result.body[i].id })
                                }
                                className="waves-effect waves-light buttons-secondary"
                                id="tooltip_delete_template"><i className="ti-trash"></i>
                            </Button>
                            <UncontrolledTooltip placement="bottom" target="tooltip_delete_template">
                                Delete 
                            </UncontrolledTooltip>

                        </div>
                    })
                }
            }
            this.setState({ templates: arr })
            console.log(this.state.templates)
        })
        .catch(error => console.log('error', error));
    
    }

    fillTemplate(template){
        this.setState({
            template_content: template.template_content,
            template_description: template.template_description,
            template_name: template.template_name,
            template_status: template.template_status,
            template_type: template.template_type,
            id: template.id  
        })
    }

    deleteTemplate(){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(`${url}/templates/delete/` + this.state.id, requestOptions)
        .then(response => response.json())
        .then(result => {
            toast(<div className='toast-message-container'>
            <h6>Template Management</h6>
            <p>Template deleted successfully.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-success'
         })
            this.viewTemplates()
        })
        .catch(error => console.log('error', error));
    }

    clearFilter(){
        this.setState({
            template_type_filter: '',
            template_status_filter: '',
            template_created_at: '',
            created_at_filter: ''
        })
        setTimeout(() => {
            this.viewTemplates()
        }, 100)
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        if(e.target.value === "custom"){
            this.setState({ [e.target.name]: "custom2",fromDate:'',toDate:'' })
            this.tog_add_cal()
        }
        else{
            setTimeout(() => {
                // console.log("created",this.state.created_at_filter)
                this.viewTemplates()
            }, 1500);
        }
    }

    disableToast(){
        let toastDiv = document.querySelector('.toast-error');
        if(toastDiv == null){
            toast(<div className='toast-message-container'>
            <h6>Template Management</h6>
            <p>Please select a valid date.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
         })
        }
    }
    render() {
        const data = {
            columns: [
                {
                    label: 'ID',
                    field: 'id',

                    width: 150
                },
                {
                    label: 'Template Name',
                    field: 'template_name',

                    width: 270
                },
                {
                    label: 'Template Subject',
                    field: 'template_description',

                    width: 200
                },
                {
                    label: 'Template Type',
                    field: 'template_type',

                    width: 10
                },
                {
                    label: 'Template Status',
                    field: 'template_status',

                    width: 10
                },
                {
                    label: 'Created At',
                    field: 'template_created_at',

                    width: 150
                }, {
                    label: 'Updated At',
                    field: 'templated_updated_at',

                    width: 100
                }, {
                    label: 'Action',
                    field: 'button',
                    sort: "disabled",
                    width: 100
                }
            ],
            rows: this.state.templates
        };

        console.log("Filter states", this.state.created_at_filter !== "" || this.state.template_status !== "" || this.state.template_type, this.state.template_type, this.state.template_status,this.state.created_at_filter )
        return (
            <React.Fragment>
                
                {this.state.alert_confirm ? (
                    
                    <div className="modal" style={{display:"block"}} tabIndex="-1" role="dialog" id='alertBox'>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Delete Template</h5>
                                
                            </div>
                            <div className="modal-body">
                                <p>{ "Are you sure you want to delete " + this.state.template_name + "?"}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" style={{background:"#FFFFFF0D", borderColor:"#2f2f34"}} className="btn" data-dismiss="modal"
                                onClick={() => {
                                    this.setState({
                                        alert_confirm: false,
                                    });
                                    document.getElementById('alertBox').style.display = 'none';
                                }} 
                                >Cancel</button>
                                <button type="button" className="btn"
                                onClick={() => {
                                    this.setState({ alert_confirm: false })
                                    this.deleteTemplate();
                                    document.getElementById('alertBox').style.display = 'none';
                                }}
                                >Delete</button>
                            </div>
                            </div>
                        </div>
                    </div>

                ) : null}
                
                <div style={
                    {
                        marginTop: 20,
                        marginBottom: 30
                    }
                }>
                    <Link to="/create-template"><Button type="button"
                        style={{background: "#17A0FB", padding: "7px 14px", borderColor: "#17A0FB", fontWeight: 600}}
                        onClick={
                            this.tog_add
                        }
                        color="info"
                        className="waves-effect waves-light">
                       Add Templates</Button></Link>
                </div>

                {/* Filters */}
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody >
                                <Row xs="12">
                                    <Col>
                                            <Label for="example-password-input" className="col-form-label">Template Type</Label>
                                            <Col>
                                                <select className="form-control" name='template_type_filter'
                                                    value={
                                                        this.state.template_type_filter
                                                    }
                                                    onChange={
                                                        this.changeHandler
                                                    }>
                                                    <option value="" disabled selected hidden>Select</option>
                                                    <option value="Email">Email</option>
                                                    <option value="Sms">Sms</option>
                                                    <option value="Whatsapp">Whatsapp</option>
                                                </select>
                                            </Col>
                                        </Col>
                                
                                    <Col>
                                        <Label for="example-password-input" className="col-form-label">Template Status</Label>
                                        <Col>
                                            <select className="form-control" name='template_status_filter'
                                                value={
                                                    this.state.template_status_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                <option value="Active">Active</option>
                                                <option value="Inactive">Inactive</option>
                                            </select>
                                        </Col>
                                    </Col>
                                    <Col>
                                        <Label for="example-password-input" className=" col-form-label">Created At</Label>
                                        <Col>
                                            <select className="form-control" name='created_at_filter'
                                                value={
                                                    this.state.created_at_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                <option value="0">Today</option>
                                                <option value="1">Yesterday</option>
                                                <option value="7">Last 7 days</option>
                                                <option value="30">Last 30 days</option>
                                                <option value="180">Last 6 months</option>
                                                <option value = "custom" onClick={this.tog_add_cal}>Custom</option>
                                                <option value = "custom2" hidden>{ this.state.created_at_filter === "custom2" ? "From: " + this.state.fromDate + " To: " + this.state.toDate : "Custom"}</option>
                                            </select>
                                        </Col>
                                    </Col>
                                </Row>
                                <Col className="text-left" style={{ marginTop: 10 }}>
                                    <Button type="button"
                                        style={{background: "#17A0FB", padding: "7px 14px", borderColor: "#17A0FB", fontWeight: 600}}
                                        onClick={
                                            this.viewTemplates
                                        }
                                        color="warning"
                                        className="waves-effect waves-light">
                                        <i className='mdi mdi-filter-menu-outline'
                                            style={
                                                { marginRight: 10, fontSize: 15 }
                                            }
                                        ></i>
                                        Filter
                                    </Button>
                                    {
                                        this.state.created_at_filter !== "" || this.state.template_status_filter !== "" || this.state.template_type_filter ?
                                        <Button type="button" 
                                        style={{ marginLeft: 10 ,border: "1px solid #E6536C", borderRadius: "3px" ,backgroundColor: "#E6536C", transition: "background-color .2s linear", padding:"7px 14px", fontWeight: 600}}
                                        onClick={
                                            () => this.clearFilter()
                                        }
                                        color="success"
                                        className="waves-effect waves-light">

                                        Clear Filter
                                        </Button> :
                                        <Button type="button" disabled
                                        style={{ marginLeft: 10 ,border: "1px solid #6a6a71", borderRadius: "3px" ,backgroundColor: "rgba(80,80,88,.8)", transition: "background-color .2s linear", fontWeight: 600}}
                                        onClick={
                                            () => this.clearFilter()
                                        }
                                        color="success"
                                        className="waves-effect waves-light">

                                        Clear Filter
                                        </Button>
                                    }
                                    
                                </Col>
                                {/*
                                
                                */}

                            </CardBody>
                        </Card>
                    </Col>
                </Row>


                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody >
                                <MDBDataTable responsive bordered striped
                                    data={data}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Modal isOpen={
                        this.state.modal_add_cal
                    }
                        toggle={
                            this.tog_add_cal
                        }
                        autoFocus={true}
                        size="lg">
                        <ModalHeader toggle={
                            this.tog_add_cal2
                        }>
                            Select Date
                        </ModalHeader>
                        <ModalBody>
                        {this.state.DateAlert ?  
                        this.disableToast()
                        : Moment(this.state.fromDate).isAfter(Moment(this.state.toDate)) || Moment(this.state.fromDate).isAfter(Moment()) || Moment(this.state.toDate).isAfter(Moment())?
                        this.disableToast()
                          : null}
                            <Row>
                                <Col>
                                    <Calendar 
                                        onChange={this.handleDatechange} 
                                    />
                                </Col>
                                <Col>
                                    <Calendar 
                                        onChange={this.handleToDatechange} 
                                    />
                                </Col>
                            </Row>                                                   
                        </ModalBody>
                        <ModalFooter>
                        {
                                 this.state.DateAlert === true || this.state.fromDate === '' || this.state.toDate === ''? 
                                 <Button type="button" color="primary" className="waves-effect" disabled
                                    onClick={
                                        this.viewTemplates
                                    }>Save
                                </Button> 
                                : 
                                Moment(this.state.fromDate).isAfter(Moment(this.state.toDate)) || Moment(this.state.fromDate).isAfter(Moment()) || Moment(this.state.toDate).isAfter(Moment())?
                                <Button type="button" color="primary" className="waves-effect" disabled
                                    onClick={
                                        this.viewTemplates
                                    }>Save
                                </Button> :
                                <Button type="button" color="primary" className="waves-effect"
                                    onClick={
                                        this.viewTemplates
                                    }>{
                                        this.state.isLoadingSave === true ? 
                                            <div style={{display: "flex", justifyContent: "center"}}>
                                                <RotatingLines
                                                    strokeColor="white"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="10"
                                                    visible={this.state.isLoadingSave}
                                                />
                                            </div>
                                        : "Save"
                                    }
                                </Button>
                            }
                            
                        </ModalFooter>
                    </Modal>
                </Row>
                <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(TemplateManagemnet);