import React, { Component } from 'react';
import {
    Col,
    Row,
    Card,
    CardBody,
    FormGroup,
    Label,
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Modal,
    Form,
    Alert,
    UncontrolledTooltip
} from "reactstrap";
import { connect } from "react-redux";
import { Link,withRouter } from "react-router-dom";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { RotatingLines } from  'react-loader-spinner'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SweetAlert from "react-bootstrap-sweetalert";

import { MDBDataTable } from 'mdbreact';

// Import datatable css
import "../Tables/datatables.scss";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import Moment from "moment"

//url
import url from "../../helpers/apiUrl"


class Enduser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_edit: false,
            modal_add: false,
            modal_resetpassword: false,
            success_msg: false,
            hidden: true,
            fromDate: '',
            toDate: '',
            password: '',
            newpassword: '',
            users: [],
            givenName: '',
            familyName: '',
            email: '',
            username: '',
            nextPagination: '',
            prevPagination: '',
            success_confirm: false,
            alert_confirm: false,
            alert_confirm_disabled: false,
            alert_confirm_enable: false,
            dynamic_title: "",
            dynamic_description: "",
            pageToken: ["null"],
            currentPage: 0,
            lastPage: false,
            id_filter: '',
            email_filter: '',
            email_verified_filter: '',
            status_filter: '',
            from_date_filter: '',
            to_date_filter: '',
            temp: false,
            created_at_filter: '',
            enable: '',
            DateAlert:false,
            isLoadingSave:false
        }
        this.tog_edit = this.tog_edit.bind(this);
        this.tog_add = this.tog_add.bind(this);
        
        this.tog_resetpassword = this.tog_resetpassword.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.toggleShow = this.toggleShow.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.fillUser = this.fillUser.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.fillUser2 = this.fillUser2.bind(this);
        this.previousPagination = this.previousPagination.bind(this);
        this.nextPaginations = this.nextPaginations.bind(this);
        this.pageChangeHandler = this.pageChangeHandler.bind(this);
        this.searchUser = this.searchUser.bind(this);
        this.enableUser = this.enableUser.bind(this);
        this.disableUser = this.disableUser.bind(this);
        this.tog_add_cal = this.tog_add_cal.bind(this);
        this.tog_add_cal2 = this.tog_add_cal2.bind(this);
        this.handleDatechange = this.handleDatechange.bind(this);
        this.handleToDatechange = this.handleToDatechange.bind(this);
        this.disableToast = this.disableToast.bind(this);

    }

    componentDidMount() {
        if (localStorage.getItem("token") === null) {
            return (
                this.props.history.push('/login')
            );
        }
        if (this.props.password) {
            this.setState({ password: this.props.password });
        }
        
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        var raw = JSON.stringify({
            "sub": '',
            "email": '',
            "email_verified": '',
            "status": '',
            "fromDate": '',
            "toDate": '',
            "Enabled": ''
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        var urll = `${url}/endusers/all`
        fetch(urll, requestOptions)
            .then((response) => response.json())
            .then(data => {
                if(data.error === "Invalid Token"){
                    localStorage.removeItem('token');
                    sessionStorage.clear();
                    this.props.history.push('/login') 
                }
                var array = []
                if(this.state.modal_add_cal === true){
                    this.setState(prevState => ({
                        modal_add_cal: false
                    }));
                }
                if(data.statusCode === "200"){
                    this.setState({isLoadingSave : false})

                    for (let i = 0; i < data.body.length; i++) {
                        array.push({
                            sub: data.body[i].sub,
                            Username: data.body[i].Username,
                            given_name: data.body[i].given_name,
                            family_name: data.body[i].family_name,
                            email: data.body[i].email,
                            email_verified: data.body[i].email_verified,
                            UserStatus: data.body[i].UserStatus,
                            UserCreateDate: Moment(data.body[i].UserCreateDate).format('YYYY/MM/DD HH:mm:ss'),
                            UserLastModifiedDate: Moment(data.body[i].UserLastModifiedDate).format('YYYY/MM/DD HH:mm:ss'),
                            button:
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <Link to={{
                                        pathname: "/userdetails",
                                        state: {
                                            user: data.body[i]
                                        }
                                    }}>
                                        <Button type="button"
                                            onClick={
                                                () => this.fillUser(data.body[i])
                                            }
                                            style={
                                                { marginRight: 10 }
                                            }
                                            id="tooltip4"
                                            color="primary"
                                            className="waves-effect waves-light buttons-secondary">
                                            <i className="ion ion-md-arrow-round-forward"></i>
                                        </Button>
                                        <UncontrolledTooltip placement="bottom" target="tooltip4">
                                            View Details
                                        </UncontrolledTooltip>
                                    </Link>
                                    <Button type="button" color="success"
                                        onClick={
                                            () => this.updatePassword(data.body[i].email)
                                        }
                                        style={
                                            { marginRight: 10}
                                        }
                                        className="waves-effect waves-light buttons-secondary"
                                        id="tooltip1"><i className="mdi mdi-lock-reset"></i>
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target="tooltip1">
                                        Update Password
                                    </UncontrolledTooltip>
                                    <Button type="button" color="danger"
                                        onClick={
                                            () => this.setState({given_name: data.body[i].given_name, family_name: data.body[i].family_name, alert_confirm: true, username: data.body[i].Username })
                                        }
                                        style={
                                            { marginRight: 10}
                                        }
                                        className="waves-effect waves-light buttons-secondary"
                                        id="tooltip2"><i className="ti-trash"></i>
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target="tooltip2">
                                        Delete User
                                    </UncontrolledTooltip>
                                    {
                                        data.body[i].Enabled ?
                                        <>
                                            <Button type="button" color="info" 
                                                onClick={
                                                    () => this.setState({given_name: data.body[i].given_name, family_name: data.body[i].family_name, alert_confirm_disabled: true, username: data.body[i].Username })
                                                }
                                                style={
                                                    { marginRight: 10}
                                                }
                                                className="waves-effect waves-light mo-mb-2 mr-1 mr-1 buttons-secondary"
                                                id="tooltip3"><i className="ti-lock"></i>
                                            </Button>
                                            <UncontrolledTooltip placement="bottom" target="tooltip3">
                                                Block User
                                            </UncontrolledTooltip>
                                        </>
                                            : 
                                            <>
                                            <Button type="button" color="light"
                                                onClick={
                                                    () => this.setState({given_name: data.body[i].given_name, family_name: data.body[i].family_name, alert_confirm_enable: true, username: data.body[i].Username })
                                                }
                                                style={
                                                    { marginRight: 10}
                                                }
                                                className="waves-effect waves-light mo-mb-2 mr-1 mr-1 buttons-secondary"
                                                id="tooltip"><i className="mdi mdi-lock-open-variant-outline"></i>
                                            </Button>
                                            <UncontrolledTooltip placement="bottom" target="tooltip">
                                                Unblock User
                                            </UncontrolledTooltip>
                                        </>
                                    }
    
    
                                </div>
                        })
                    }
                    this.setState({ users: array })
                }
                else(
                    this.setState({ users: [] })
                )
                
            })
        
        
    }

    getUsers() {
        this.setState({isLoadingSave : true})

        var date = this.state.created_at_filter;
        var fromDate = ''
        var toDate = ''
        if (date === "custom2") {
            fromDate = Moment(this.state.fromDate).format('YYYY-MM-DD')
            toDate = Moment(this.state.toDate).format('YYYY-MM-DD')
        } else if (date !== "") {
            if(date === "1"){
                fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
                toDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            }else{
            fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            toDate = Moment().format('YYYY-MM-DD')
            }
        }

        
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        var raw = JSON.stringify({
            "sub": this.state.id_filter,
            "email": this.state.email_filter,
            "email_verified": this.state.email_verified_filter,
            "status": this.state.status_filter,
            "fromDate": fromDate,
            "toDate": toDate,
            "Enabled": this.state.enable === "true" ? true : this.state.enable === "false" ? false : ''
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        var urll = `${url}/endusers/all`
        fetch(urll, requestOptions)
            .then((response) => response.json())
            .then(data => {
                if(data.error === "Invalid Token"){
                    localStorage.removeItem('token');
                    sessionStorage.clear();
                    this.props.history.push('/login') 
                }
                var array = []
                if(this.state.modal_add_cal === true){
                    this.setState(prevState => ({
                        modal_add_cal: false
                    }));
                }
                if(data.statusCode === "200"){
                    this.setState({isLoadingSave : false})

                    for (let i = 0; i < data.body.length; i++) {
                        array.push({
                            sub: data.body[i].sub,
                            Username: data.body[i].Username,
                            given_name: data.body[i].given_name,
                            family_name: data.body[i].family_name,
                            email: data.body[i].email,
                            email_verified: data.body[i].email_verified,
                            UserStatus: data.body[i].UserStatus,
                            UserCreateDate: Moment(data.body[i].UserCreateDate).format('YYYY/MM/DD HH:mm:ss'),
                            UserLastModifiedDate: Moment(data.body[i].UserLastModifiedDate).format('YYYY/MM/DD HH:mm:ss'),
                            button:
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <Link to={{
                                        pathname: "/userdetails",
                                        state: {
                                            user: data.body[i]
                                        }
                                    }}>
                                        <Button type="button"
                                            onClick={
                                                () => this.fillUser(data.body[i])
                                            }
                                            style={
                                                { marginRight: 10 }
                                            }
                                            id="tooltip4"
                                            color="primary"
                                            className="waves-effect waves-light buttons-secondary">
                                            <i className="ion ion-md-arrow-round-forward"></i>
                                        </Button>
                                        <UncontrolledTooltip placement="bottom" target="tooltip4">
                                            View Details
                                        </UncontrolledTooltip>
                                    </Link>
                                    <Button type="button" color="success"
                                        onClick={
                                            () => this.updatePassword(data.body[i].email)
                                        }
                                        style={
                                            { marginRight: 10}
                                        }
                                        className="waves-effect waves-light buttons-secondary"
                                        id="tooltip1"><i className="mdi mdi-lock-reset"></i>
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target="tooltip1">
                                        Update Password
                                    </UncontrolledTooltip>
                                    <Button type="button" color="danger"
                                        onClick={
                                            () => this.setState({given_name: data.body[i].given_name, family_name: data.body[i].family_name, alert_confirm: true, username: data.body[i].Username })
                                        }
                                        style={
                                            { marginRight: 10}
                                        }
                                        className="waves-effect waves-light buttons-secondary"
                                        id="tooltip2"><i className="ti-trash"></i>
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target="tooltip2">
                                        Delete User
                                    </UncontrolledTooltip>
                                    {
                                        data.body[i].Enabled ?
                                        <>
                                            <Button type="button" color="info" 
                                                onClick={
                                                    () => this.setState({given_name: data.body[i].given_name, family_name: data.body[i].family_name, alert_confirm_disabled: true, username: data.body[i].Username })
                                                }
                                                style={
                                                    { marginRight: 10}
                                                }
                                                className="waves-effect waves-light mo-mb-2 mr-1 mr-1 buttons-secondary"
                                                id="tooltip3"><i className="ti-lock"></i>
                                            </Button>
                                            <UncontrolledTooltip placement="bottom" target="tooltip3">
                                                Block User
                                            </UncontrolledTooltip>
                                        </>
                                            : 
                                            <>
                                            <Button type="button" color="light"
                                                onClick={
                                                    () => this.setState({given_name: data.body[i].given_name, family_name: data.body[i].family_name, alert_confirm_enable: true, username: data.body[i].Username })
                                                }
                                                style={
                                                    { marginRight: 10}
                                                }
                                                className="waves-effect waves-light mo-mb-2 mr-1 mr-1 buttons-secondary"
                                                id="tooltip"><i className="mdi mdi-lock-open-variant-outline"></i>
                                            </Button>
                                            <UncontrolledTooltip placement="bottom" target="tooltip">
                                                Unblock User
                                            </UncontrolledTooltip>
                                        </>
                                    }
    
    
                                </div>
                        })
                    }
                    this.setState({ users: array })
                }
                else(
                    this.setState({ users: [] })
                )
                
            })
        
    }

    enableUser() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "username": this.state.username
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/endusers/enable-user`, requestOptions)
            .then(response => response.json())
            .then(result => {
                toast(<div className='toast-message-container'>
                <h6>User Management</h6>
                <p>User has been unblocked.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-success'
                });
                this.getUsers();
            })
            .catch(error => {
                toast(<div className='toast-message-container'>
                <h6>User Management</h6>
                <p>User could not be unblocked.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-error'
                });
            });
    }

    disableUser() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "username": this.state.username
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/endusers/disable-user`, requestOptions)
            .then(response => response.json())
            .then(result => {
                toast(<div className='toast-message-container'>
                <h6>User Management</h6>
                <p>User has been blocked.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-success'
                });
                this.getUsers()
            })
            .catch(error => {
                toast(<div className='toast-message-container'>
                <h6>User Management</h6>
                <p>User could not be blocked.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-error'
                });
            });
    }

    deleteUser() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "username": this.state.username
        });

        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/endusers`, requestOptions)
            .then(response => response.json())
            .then(data => {
                toast(<div className='toast-message-container'>
                <h6>User Management</h6>
                <p>User has been deleted.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-success'
                });
                this.setState({ users: this.state.users.filter(user => user.Username !== this.state.username) })
            })
            .catch(error => {
                toast(<div className='toast-message-container'>
                <h6>User Management</h6>
                <p>User could not be deleted.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-error'
                });
            });
    }

    fillUser = (user) => {
        this.setState({
            familyName: user.family_name,
            givenName: user.given_name,
            email: user.email,
            username: user.Username
        })
        this.tog_edit();
    }

    fillUser2 = (user) => {
        this.setState({
            username: user.Username
        })
        this.tog_resetpassword();
    }

    updateUser() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "username": this.state.username,
            "givenName": this.state.givenName,
            "familyName": this.state.familyName,
            "email": this.state.email
        });

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/endusers`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.statusCode === "200") {
                    this.getUsers(0)
                    this.tog_edit()
                    this.setState({
                        givenName: '',
                        familyName: '',
                        email: '',
                        username: '',
                    })
                    toast(<div className='toast-message-container'>
                    <h6>User Management</h6>
                    <p>User updated successfully.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-error'
                 })
                } else {
                    toast(<div className='toast-message-container'>
                    <h6>User Management</h6>
                    <p>Something went wrong.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-error'
                 })
                }
            })
            .catch(error => console.log('error', error));
    }

    searchUser(e) {
        if (e.target.value === null || e.target.value === "" || e.target.value === undefined) {
            return this.getUsers(0)
        }
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "username": e.target.value
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/endusers/search`, requestOptions)
            .then(response => response.json())
            .then(data => {
                var array = []
                for (let i = 0; i < data.body.length; i++) {
                    array.push({
                        sub: data.body[i].sub,
                        Username: data.body[i].Username,
                        given_name: data.body[i].given_name,
                        family_name: data.body[i].family_name,
                        email: data.body[i].email,
                        email_verified: data.body[i].email_verified,
                        UserStatus: data.body[i].UserStatus,
                        UserCreateDate: Moment(data.body[i].UserCreateDate).format('YYYY/MM/DD HH:mm:ss'),
                        UserLastModifiedDate: Moment(data.body[i].UserLastModifiedDate).format('YYYY/MM/DD HH:mm:ss'),
                        button:
                        <div style={{display: "flex", justifyContent: "center"}}>
                                    <Link to={{
                                        pathname: "/userdetails",
                                        state: {
                                            user: data.body[i]
                                        }
                                    }}>
                                        <Button type="button"
                                            onClick={
                                                () => this.fillUser(data.body[i])
                                            }
                                            style={
                                                { marginRight: 10 }
                                            }
                                            id="tooltip4"
                                            color="primary"
                                            className="waves-effect waves-light buttons-secondary">
                                            <i className="ion ion-md-arrow-round-forward"></i>
                                        </Button>
                                        <UncontrolledTooltip placement="bottom" target="tooltip4">
                                            View Details
                                        </UncontrolledTooltip>
                                    </Link>
                                    <Button type="button" color="success"
                                        onClick={
                                            () => this.updatePassword(data.body[i].email)
                                        }
                                        style={
                                            { marginRight: 10}
                                        }
                                        className="waves-effect waves-light buttons-secondary"
                                        id="tooltip1"><i className="mdi mdi-lock-reset"></i>
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target="tooltip1">
                                        Update Password
                                    </UncontrolledTooltip>
                                    <Button type="button" color="danger"
                                        onClick={
                                            () => this.setState({given_name: data.body[i].given_name, family_name: data.body[i].family_name, alert_confirm: true, username: data.body[i].Username })
                                        }
                                        style={
                                            { marginRight: 10}
                                        }
                                        className="waves-effect waves-light buttons-secondary"
                                        id="tooltip2"><i className="ti-trash"></i>
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target="tooltip2">
                                        Delete User
                                    </UncontrolledTooltip>
                                    {
                                        data.body[i].Enabled ?
                                        <>
                                            <Button type="button" color="info" 
                                                onClick={
                                                    () => this.setState({given_name: data.body[i].given_name, family_name: data.body[i].family_name, alert_confirm_disabled: true, username: data.body[i].Username })
                                                }
                                                style={
                                                    { marginRight: 10}
                                                }
                                                className="waves-effect waves-light mo-mb-2 mr-1 mr-1 buttons-secondary"
                                                id="tooltip3"><i className="ti-lock"></i>
                                            </Button>
                                            <UncontrolledTooltip placement="bottom" target="tooltip3">
                                                Block User
                                            </UncontrolledTooltip>
                                        </>
                                            : 
                                            <>
                                            <Button type="button" color="light"
                                                onClick={
                                                    () => this.setState({given_name: data.body[i].given_name, family_name: data.body[i].family_name, alert_confirm_enable: true, username: data.body[i].Username })
                                                }
                                                style={
                                                    { marginRight: 10}
                                                }
                                                className="waves-effect waves-light mo-mb-2 mr-1 mr-1 buttons-secondary"
                                                id="tooltip"><i className="mdi mdi-lock-open-variant-outline"></i>
                                            </Button>
                                            <UncontrolledTooltip placement="bottom" target="tooltip">
                                                Unblock User
                                            </UncontrolledTooltip>
                                        </>
                                    }
    
    
                                </div>
                    })
                }

                if (data.statusCode === "200") {
                    this.setState({
                        users: array
                    })
                }
                else {
                    this.setState({
                        users: []
                    })
                }
            })
            .catch(error => console.log('error', error));
    }

    handleDatechange(value){
        var dateofvisit = Moment(value, 'DD-MM-YYYY');
        var today = Moment();
        if(today.diff(dateofvisit, 'Hours') <= 0){
            this.setState({
                DateAlert: true,fromDate: Moment(value).format("YYYY-MM-DD")
            })
            
            return  
        }else{
            this.setState({
                DateAlert: false
            }) 
            this.setState({fromDate: Moment(value).format("YYYY-MM-DD")})
        }
    }
    handleToDatechange(value){
        var dateofvisit = Moment(value, 'DD-MM-YYYY');
        var today = Moment();
        
        if(today.diff(dateofvisit, 'Hours') <= 0){
            this.setState({
                DateAlert: true,toDate: Moment(value).format("YYYY-MM-DD")
            })
            
            return  
        }else if(this.state.fromDate !== ""){
            if(Moment(this.state.fromDate, 'YYYY-MM-DD').diff(dateofvisit, 'Hours') > 0){
                this.setState({
                    DateAlert: true,toDate: Moment(value).format("YYYY-MM-DD")
                })
            }else{
                this.setState({
                    DateAlert: false
                })
                this.setState({toDate: Moment(value).format("YYYY-MM-DD")})
            }
        }else{
            this.setState({
                DateAlert: false
            })
            this.setState({toDate: Moment(value).format("YYYY-MM-DD")})

        }
    }
    

    updatePassword(email) {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "email": email
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/endusers/reset-password`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.statusCode === "200") {

                    // this.setState({
                    //     temp: true
                    // })
                    // setTimeout(() => {
                    //     this.setState({
                    //         temp: false
                    //     })
                    // }, 3000);
                    toast(<div className='toast-message-container'>
                    <h6>User Management</h6>
                    <p>Reset password link sent successfully.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-error'
                 })
                } else {
                    toast(<div className='toast-message-container'>
                    <h6>User Management</h6>
                    <p>Something went wrong.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-error'
                 })
                }
            })
            .catch(error => console.log('error', error));

    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        if(e.target.value === "custom"){
            this.setState({ [e.target.name]: "custom2",fromDate:'',toDate:'' })
            this.tog_add_cal()
        }
        else{
            setTimeout(() => {
                this.getUsers()
            }, 1500);
        }
    }

    previousPagination() {
        if (this.state.currentPage >= 1) {
            this.getUsers(this.state.currentPage - 1)
        } else {
            this.getUsers(0)
        }
        var temp = this.state.pageToken
        temp = temp.slice(0, this.state.currentPage)
        this.setState({
            pageToken: temp
        })
    }

    nextPaginations(pageNo) {

        this.getUsers(this.state.currentPage + 1)
    }

    pageChangeHandler(pageNo) {
        this.getUsers(pageNo)
    }

    handlePasswordChange(e) {
        this.setState({ password: e.target.value });
    }

    toggleShow() {
        this.setState({ hidden: !this.state.hidden });
    }

    tog_edit() {
        this.setState(prevState => ({
            modal_edit: !prevState.modal_edit
        }));
    }
    tog_add() {
        this.setState(prevState => ({
            modal_add: !prevState.modal_add
        }));
    }
    tog_resetpassword() {
        this.setState(prevState => ({
            modal_resetpassword: !prevState.modal_resetpassword
        }));
    }
    clearFilter() {
        this.setState({
            id_filter: '',
            email_filter: '',
            email_verified_filter: '',
            status_filter: '',
            from_date_filter: '',
            to_date_filter: '',
            fromDate: '',
            toDate: '',
            created_at_filter: '',
            enable: ''
        })
        setTimeout(() => {
            this.getUsers()
        }, 1500)

    }

    tog_add_cal() {
        this.setState(prevState => ({
            modal_add_cal: !prevState.modal_add_cal

        }));
    }

    tog_add_cal2() {
        this.setState(prevState => ({
            modal_add_cal: !prevState.modal_add_cal,fromDate:'',toDate:'',created_at_filter:"",DateAlert:false

        }));
        setTimeout(() => {
            this.getUsers()
        }, 1500);
    }
    disableToast(){
        let toastDiv = document.querySelector('.toast-error');
        if(toastDiv == null){
            toast(<div className='toast-message-container'>
            <h6>User Management</h6>
            <p>Please select a valid date.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
         })
        }
    }
    render() {
        const { pageToken } = this.state
        var Page_Token = pageToken;
        if (Page_Token.length > 3) {
        }

        const data = {
            columns: [
                {
                    label: 'ID',
                    field: 'sub',
                    width: 150
                },
                {
                    label: 'Username',
                    field: 'Username',
                    width: 100
                },
                {
                    label: 'First Name',
                    field: 'given_name',
                    width: 270
                },
                {
                    label: 'Last Name',
                    field: 'family_name',
                    width: 200
                },
                {
                    label: 'Email',
                    field: 'email',
                    width: 100
                },
                {
                    label: 'Email Verified',
                    field: 'email_verified',
                    width: 100
                },
                {
                    label: 'Status',
                    field: 'UserStatus',
                    width: 100
                },
                {
                    label: 'Created At',
                    field: 'UserCreateDate',
                    width: 100
                },
                {
                    label: 'Updated At',
                    field: 'UserLastModifiedDate',
                    width: 100
                },
                {
                    label: 'Action',
                    field: 'button',
                    sort: "disabled",
                    width: 100
                }
            ],
            rows: this.state.users
        };

        return (
            <React.Fragment>
                {this.state.alert_confirm ? (
                    
                    <div className="modal" style={{display:"block"}} tabIndex="-1" role="dialog" id='alertBox'>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Delete User</h5>
                                
                            </div>
                            <div className="modal-body">
                                <p>{ "Are you sure you want to delete" + " " + this.state.given_name +  " " + this.state.family_name + "?"}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" style={{background:"#FFFFFF0D", borderColor:"#2f2f34"}} className="btn" data-dismiss="modal"
                                onClick={() => {
                                    this.setState({
                                        alert_confirm: false,
                                    });
                                    document.getElementById('alertBox').style.display = 'none';
                                }} 
                                >Cancel</button>
                                <button type="button" className="btn"
                                onClick={() => {
                                    this.setState({ alert_confirm: false })
                                    this.deleteUser();
                                    document.getElementById('alertBox').style.display = 'none';
                                }}
                                >Delete</button>
                            </div>
                            </div>
                        </div>
                    </div>

                ) : null}

                

                {this.state.alert_confirm_enable ? (

                    <div className="modal" style={{display:"block"}} tabIndex="-1" role="dialog" id='alertBox'>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Unblock User</h5>
                            
                        </div>
                        <div className="modal-body">
                            <p>{"Are you sure you want to unblock " + this.state.username + "?"}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" style={{background:"#FFFFFF0D", borderColor:"#2f2f34"}} className="btn" data-dismiss="modal"
                            onClick={() => {
                                this.setState({
                                    alert_confirm_enable: false,
                                });
                                document.getElementById('alertBox').style.display = 'none';
                            }} 
                            >Cancel</button>
                            <button type="button" className="btn"
                            onClick={() => {
                                this.setState({ alert_confirm_enable: false })
                                this.enableUser();
                                document.getElementById('alertBox').style.display = 'none';
                            }}
                            >Unblock</button>
                        </div>
                        </div>
                    </div>
                </div>
                ) : null}

                

                {this.state.alert_confirm_disabled ? (
                    
                    <div className="modal" style={{display:"block"}} tabIndex="-1" role="dialog" id='alertBox'>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Block User</h5>
                                
                            </div>
                            <div className="modal-body">
                                <p>{"Are you sure you want to block " + this.state.username + "?"}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" style={{background:"#FFFFFF0D", borderColor:"#2f2f34"}} className="btn" data-dismiss="modal"
                                onClick={() => {
                                    this.setState({
                                        alert_confirm_disabled: false,
                                    });
                                    document.getElementById('alertBox').style.display = 'none';
                                }} 
                                >Cancel</button>
                                <button type="button" className="btn"
                                onClick={() => {
                                    this.setState({ alert_confirm_disabled: false });
                                    this.disableUser();
                                    document.getElementById('alertBox').style.display = 'none';
                                }}
                                >Block</button>
                            </div>
                            </div>
                        </div>
                    </div>
                ) : null}

                
                <h1>One Dash Users Details</h1>

                <Col lg="6">
                    <Form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                            <Input onChange={this.searchUser} type="text" className="form-control" placeholder="Search by username..." />
                            <span className="fa fa-search"></span>
                        </div>
                    </Form>
                </Col>

                {/* Filters */}
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody >
                                <Row xs="12">
                                    <Col>
                                        <Label for="example-password-input" className=" col-form-label">ID</Label>
                                        <Col>
                                            <Input className="form-control" name="id_filter" type="text"
                                                value={
                                                    this.state.id_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }
                                                id="example-search-input" />
                                        </Col>
                                    </Col>
                                    <Col>
                                        <Label for="example-password-input" className=" col-form-label">Email</Label>
                                        <Col>
                                            <Input className="form-control" name="email_filter" type="text"
                                                value={
                                                    this.state.email_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }
                                                id="example-search-input" />
                                        </Col>
                                    </Col>

                                    <Col>
                                        <Label for="example-password-input" className=" col-form-label">Email Verified</Label>
                                        <Col>
                                            <select className="form-control" name='email_verified_filter'
                                                value={
                                                    this.state.email_verified_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                <option value="true">True</option>
                                                <option value="false">False</option>
                                            </select>
                                        </Col>
                                    </Col>
                                    <Col>
                                        <Label for="example-password-input" className="col-form-label">Status</Label>
                                        <Col>
                                            <select className="form-control" name='status_filter'
                                                value={
                                                    this.state.status_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                <option value="CONFIRMED">Confirm</option>
                                                <option value="UNCONFIRMED">Unconfirm</option>
                                            </select>
                                        </Col>
                                    </Col>
                                    <Col>
                                        <Label for="example-password-input" className="col-form-label">Enabled User</Label>
                                        <Col>
                                            <select className="form-control" name='enable'
                                                value={
                                                    this.state.enable
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                <option value="true">True</option>
                                                <option value="false">False</option>
                                            </select>
                                        </Col>
                                    </Col>
                                    <Col>
                                    
                                        <Label for="example-password-input" className=" col-form-label">Created At</Label>
                                        <Col>
                                            <select className="form-control" name='created_at_filter'
                                                value={
                                                    this.state.created_at_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                <option value="0">Today</option>
                                                <option value="1">Yesterday</option>
                                                <option value="7">Last 7 days</option>
                                                <option value="30">Last 30 days</option>
                                                <option value="180">Last 6 months</option>
                                                <option value="custom" onClick={this.tog_add_cal}>Custom</option>
                                                <option value="custom2" hidden>{ this.state.created_at_filter === "custom2" ? "From: " + this.state.fromDate + " To: " + this.state.toDate : "Custom"}</option>
                                            </select>
                                        </Col>
                                    
                                    </Col>

                                </Row>
                                <Col className="text-left" style={{marginTop: 10}}>
                                    <Button type="button"
                                        style={{background: "#17A0FB", padding: "7px 14px", borderColor: "#17A0FB", fontWeight: 600}}
                                        onClick={
                                            () => this.getUsers()
                                        }
                                        color="warning"
                                        className="waves-effect waves-light">
                                        <i className='mdi mdi-filter-menu-outline'
                                            style={
                                                { marginRight: 10, fontSize: 15 }
                                            }
                                        ></i>
                                        Filter
                                    </Button>

                                    {
                                        this.state.id_filter === '' && this.state.enable === '' && this.state.email_filter === '' && this.state.email_verified_filter === '' && this.state.status_filter === '' && this.state.created_at_filter === '' ?
                                        <Button type="button" disabled
                                            style={{ marginLeft: 10 ,border: "1px solid #6a6a71", borderRadius: "3px" ,backgroundColor: "rgba(80,80,88,.8)", transition: "background-color .2s linear", fontWeight: 600}}
                                            onClick={
                                                () => this.clearFilter()
                                            }
                                            color="success"
                                            className="waves-effect waves-light">

                                            Clear Filter
                                        </Button>:
                                        <Button type="button"
                                            style={{ marginLeft: 10 ,border: "1px solid #E6536C", borderRadius: "3px" ,backgroundColor: "#E6536C", transition: "background-color .2s linear", padding:"7px 14px", fontWeight: 600}}
                                            onClick={
                                                () => this.clearFilter()
                                            }
                                            
                                            className="waves-effect waves-light">

                                            Clear Filter
                                        </Button>
                                    }
                                    
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {this.state.temp ? <Alert color="success">
                    <strong>Password reset link has been sent to given email address.</strong>
                </Alert> : null}
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody >
                                <MDBDataTable
                                    responsive
                                    bordered
                                    striped
                                    data={data}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>


                {/* Editing Form */}
                <Row>
                    <Modal
                        isOpen={this.state.modal_edit}
                        toggle={this.tog_edit}
                        autoFocus={true}
                        size="lg"
                    >
                        <ModalHeader toggle={this.tog_edit}>
                            Edit Details
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup row>
                                <Label for="example-text-input" className="col-sm-2 col-form-label">First Name</Label>
                                <Col sm="10">
                                    <Input className="form-control" type="text" name="givenName" value={this.state.givenName} onChange={this.changeHandler} id="example-text-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="example-text-input" className="col-sm-2 col-form-label">Last Name</Label>
                                <Col sm="10">
                                    <Input className="form-control" type="text" name="familyName" value={this.state.familyName} onChange={this.changeHandler} id="example-text-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>

                                <Col sm="10">
                                    <Input className="form-control" type="hidden" name="username" id="example-text-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="example-tel-input" className="col-sm-2 col-form-label">Email</Label>
                                <Col sm="10">
                                    <Input className="form-control" type="email" name="email" value={this.state.email} onChange={this.changeHandler} id="example-search-input" />
                                </Col>
                            </FormGroup>

                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="secondary" className="waves-effect" onClick={this.tog_edit}>Close</Button>
                            <Button type="button" color="primary" onClick={() => this.updateUser()} className="waves-effect waves-light">Save changes</Button>
                        </ModalFooter>
                    </Modal>
                </Row>

                {/* Reset Password Form */}
                <Row>
                    <Modal
                        isOpen={this.state.modal_resetpassword}
                        toggle={this.tog_resetpassword}
                        autoFocus={true}
                        size="lg"
                    >
                        <ModalHeader toggle={this.tog_resetpassword}>
                            Reset Password
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup row>
                                <Label for="example-url-input" className="col-sm-2 col-form-label">Username</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="username" disabled value={this.state.username} type="text" id="example-url-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="example-url-input" className="col-sm-2 col-form-label">Create New Password</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="password" type="password" value={this.state.password} onChange={this.changeHandler} id="example-url-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="example-url-input" className="col-sm-2 col-form-label">Confirm Password</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="newpassword" value={this.state.newpassword} type="password" onChange={this.changeHandler} id="example-url-input" />
                                </Col>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="secondary" className="waves-effect" onClick={this.tog_standard}>Close</Button>
                            <Button type="button" color="primary" className="waves-effect waves-light" onClick={() => this.updatePassword()}>Save</Button>
                        </ModalFooter>
                    </Modal>
                </Row>

                <Row>
                    <Modal isOpen={
                        this.state.modal_add_cal
                    }
                        toggle={
                            this.tog_add_cal
                        }
                        autoFocus={true}
                        size="lg">
                        <ModalHeader toggle={
                            this.tog_add_cal2
                        }>
                            Select Date
                        </ModalHeader>
                        <ModalBody>
                        {this.state.DateAlert ?  
                        this.disableToast()
                        : Moment(this.state.fromDate).isAfter(Moment(this.state.toDate)) || Moment(this.state.fromDate).isAfter(Moment()) || Moment(this.state.toDate).isAfter(Moment())?
                        this.disableToast()
                          : null}
                        
                            <Row>
                                <Col>
                                    <Calendar 
                                        onChange={this.handleDatechange} 
                                    />
                                </Col>
                                <Col>
                                    <Calendar 
                                        onChange={this.handleToDatechange} 
                                    />
                                </Col>
                            </Row>                                                   
                        </ModalBody>
                        <ModalFooter>
                        {
                                 this.state.DateAlert === true || this.state.fromDate === '' || this.state.toDate === ''? 
                                 <Button type="button" color="primary" className="waves-effect" disabled
                                    onClick={
                                        this.getUsers
                                    }>Save
                                </Button> 
                                : 
                                Moment(this.state.fromDate).isAfter(Moment(this.state.toDate)) || Moment(this.state.fromDate).isAfter(Moment()) || Moment(this.state.toDate).isAfter(Moment())?
                                <Button type="button" color="primary" className="waves-effect" disabled
                                    onClick={
                                        this.getUsers
                                    }>Save
                                </Button> :
                                <Button type="button" color="primary" className="waves-effect"
                                    onClick={
                                        this.getUsers
                                    }>{
                                        this.state.isLoadingSave === true ? 
                                            <div style={{display: "flex", justifyContent: "center"}}>
                                                <RotatingLines
                                                    strokeColor="white"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="10"
                                                    visible={this.state.isLoadingSave}
                                                />
                                            </div>
                                        : "Save"
                                    }
                                </Button>
                            }
                            
                        </ModalFooter>
                    </Modal>
                </Row>
                <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { setBreadcrumbItems })(Enduser));
