import React, { Component } from 'react';
import {
    Col,
    Row,
    Card,
    CardBody,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    FormGroup,
    Alert,
    UncontrolledTooltip
} from "reactstrap";
//   import { Link } from "react-router-dom";
import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import { RotatingLines } from  'react-loader-spinner'
import { MDBDataTable } from 'mdbreact';
import Moment from "moment"
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
// Import datatable css
import "../Tables/datatables.scss";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//url
import url from "../../helpers/apiUrl"

class Faq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "One Dash", link: "dashboard" },
                { title: "FAQ", link: "#" },
            ],
            category: [],
            modal_add: false,
            modal_add_faq: false,
            modal_edit_faq: false,
            id: '',
            created_at: '',
            updated_at: '',
            faq_topic: '',
            faq_answer: '',
            faq_status: '',
            category_id: '',
            prev_category_id: '',
            prev_faq_answer: '',
            prev_faq_status: '',
            prev_faq_topic: '',
            faq: [],
            success_confirm: false,
            alert_confirm: false,
            dynamic_title: "",
            dynamic_description: "",
            cat_id_filter: '',
            state_filter: '',
            from_date_filter: '',
            to_date_filter: '',
            created_at_filter: '',
            fromDate:'',
            toDate: '',
            faqCreated: false,
            faqUpdated: false,
            DateAlert:false,
            isLoadingSave: false,
        }
        this.tog_add = this.tog_add.bind(this);
        this.tog_add2 = this.tog_add2.bind(this);
        this.viewAllFaq = this.viewAllFaq.bind(this);
        this.createFaq = this.createFaq.bind(this);
        this.deleteFaq = this.deleteFaq.bind(this);
        this.updateFaq = this.updateFaq.bind(this);
        this.faqById = this.faqById.bind(this);
        this.tog_add_faq = this.tog_add_faq.bind(this);
        this.tog_edit_faq = this.tog_edit_faq.bind(this);
        this.fillFaq = this.fillFaq.bind(this);
        this.viewFaqCategory = this.viewFaqCategory.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.handleDatechange = this.handleDatechange.bind(this);
        this.handleToDatechange = this.handleToDatechange.bind(this);
        this.disableToast = this.disableToast.bind(this);

    }

    componentDidMount() {
        this.props.setBreadcrumbItems("FAQ", this.state.breadcrumbItems);
        if (localStorage.getItem("token") === null) {
            return (
                this.props.history.push('/login')
                // <Redirect to={{ pathname: "/login" }} exact />
            );
        }
        this.viewAllFaq()
        this.viewFaqCategory()

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "category_id": this.state.cat_id_filter,
            "faq_status": this.state.state_filter,
            "from_date": this.state.from_date_filter,
            "to_date": this.state.to_date_filter
        });
        console.log(raw, "faq raw")

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/faq/`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.error === "Invalid Token"){
                    localStorage.removeItem('token');
                    sessionStorage.clear();
                    this.props.history.push('/login') 
                }
                console.log(data, "faq result")
                var array = []
                if (data.statusCode === "200") {
                    
                    for (let i = 0; i < data.body.length; i++) {
                        array.push({
                            id: data.body[i].id,
                            category_id: data.body[i].category_id,
                            category_name: data.body[i].category_name,
                            faq_topic: data.body[i].faq_topic,
                            faq_answer: data.body[i].faq_answer,
                            faq_status: data.body[i].faq_status,
                            created_at: Moment(data.body[i].created_at).format('YYYY/MM/DD HH:mm:ss'),
                            updated_at: Moment(data.body[i].updated_at).format('YYYY/MM/DD HH:mm:ss'),
                            button:
                            <div style={{display: "flex", justifyContent: "center"}}>
                            <Button type="button"
                                onClick={
                                    () => this.fillFaq(data.body[i])
                                }
                                style={
                                    { marginRight: 10}
                                }
                                color="primary"
                                id="tooltip_edit_faq"
                                className="waves-effect waves-light buttons-secondary">
                                <i className="ti-pencil"></i>
                            </Button>
                            <UncontrolledTooltip placement="bottom" target="tooltip_edit_faq">
                                Edit 
                            </UncontrolledTooltip>
                            <Button type="button" color="danger"
                                onClick={
                                    () => this.setState({faq_topic: data.body[i].faq_topic, alert_confirm: true, id: data.body[i].id })
                                }
                                className="waves-effect waves-light buttons-secondary"
                                id="tooltip_delete_faq"><i className="ti-trash"></i>
                            </Button>
                            <UncontrolledTooltip placement="bottom" target="tooltip_delete_faq">
                                Delete
                            </UncontrolledTooltip>
                        </div>

                        })
                    }
                    this.setState({ faq: array })
                }
                else {
                    this.setState({
                        faq: []
                    })
                }

            })
            .catch(error => console.log('error', error));
    }

    viewAllFaq() {
        this.setState({isLoadingSave : true})

        var date = this.state.created_at_filter;
        var fromDate = ''
        var toDate = ''
        if (date === "custom2") {
            fromDate = Moment(this.state.fromDate).format('YYYY-MM-DD')
            toDate = Moment(this.state.toDate).format('YYYY-MM-DD')
        } else if (date !== "") {
            if(date === "1"){
                fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
                toDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            }else{
            fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            toDate = Moment().format('YYYY-MM-DD')
            }
        }
        
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "category_id": this.state.cat_id_filter,
            "faq_status": this.state.state_filter,
            "from_date": fromDate,
            "to_date": toDate
        });
        console.log(raw, "faq raw")

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/faq/`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.error === "Invalid Token"){
                    localStorage.removeItem('token');
                    sessionStorage.clear();
                    this.props.history.push('/login') 
                }
                console.log(data, "faq result")
                var array = []
                if (data.statusCode === "200") {
                    this.setState({isLoadingSave : false})

                    if(this.state.modal_add === true){
                        this.setState(prevState => ({
                            modal_add: false
                        }));
                    }
                    for (let i = 0; i < data.body.length; i++) {
                        array.push({
                            id: data.body[i].id,
                            category_id: data.body[i].category_id,
                            category_name: data.body[i].category_name,
                            faq_topic: data.body[i].faq_topic,
                            faq_answer: data.body[i].faq_answer,
                            faq_status: data.body[i].faq_status,
                            created_at: Moment(data.body[i].created_at).format('YYYY/MM/DD HH:mm:ss'),
                            updated_at: Moment(data.body[i].updated_at).format('YYYY/MM/DD HH:mm:ss'),
                            button:
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <Button type="button"
                                        onClick={
                                            () => this.fillFaq(data.body[i])
                                        }
                                        style={
                                            { marginRight: 10}
                                        }
                                        color="primary"
                                        id="tooltip_edit_faq"
                                        className="waves-effect waves-light buttons-secondary">
                                        <i className="ti-pencil"></i>
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target="tooltip_edit_faq">
                                        Edit 
                                    </UncontrolledTooltip>
                                    <Button type="button" color="danger"
                                        onClick={
                                            () => this.setState({faq_topic: data.body[i].faq_topic, alert_confirm: true, id: data.body[i].id })
                                        }
                                        className="waves-effect waves-light buttons-secondary"
                                        id="tooltip_delete_faq"><i className="ti-trash"></i>
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target="tooltip_delete_faq">
                                        Delete
                                    </UncontrolledTooltip>
                                </div>

                        })
                    }
                    this.setState({ faq: array })
                }
                else {
                    this.setState({
                        faq: []
                    })
                }

            })
            .catch(error => console.log('error', error));
        
    }


    createFaq() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "category_id": this.state.category_id,
            "faq_topic": this.state.faq_topic,
            "faq_answer": this.state.faq_answer,
            "faq_status": this.state.faq_status
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/faq/create`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.statusCode === "200") {
                    this.viewAllFaq()
                    this.tog_add_faq()

                    this.setState({
                        category_id: '',
                        faq_topic: '',
                        faq_answer: '',
                        faq_status: '',
                        //faqCreated: true
                    })
                    // setTimeout(() => {
                    //     this.setState({
                    //         faqCreated: false
                    //     })
                    // }, 3000)
                    toast(<div className='toast-message-container'>
                    <h6>FAQ</h6>
                    <p>FAQ created successfully.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-success'
                 })
                } else {
                    toast(<div className='toast-message-container'>
                    <h6>FAQ</h6>
                    <p>Something went wrong while creating faq.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-error'
                 })
                }

            })
            .catch(error => console.log('error', error));
    }

    deleteFaq() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${url}/faq/` + this.state.id, requestOptions)
            .then(response => response.json())
            .then(result => {
                toast(<div className='toast-message-container'>
                <h6>FAQ</h6>
                <p>FAQ deleted successfully.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-success'
             })
                this.viewAllFaq()
            })
            .catch(error => console.log('error', error));

    }

    faqById() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${url}/faq/3`, requestOptions)
            .then(response => response.json())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));

    }

    fillFaq = (faq) => {
        this.setState({
            id: faq.id,
            category_id: faq.category_id,
            faq_topic: faq.faq_topic,
            faq_answer: faq.faq_answer,
            faq_status: faq.faq_status,
            created_at: faq.created_at,
            updated_at: faq.updated_at,
            prev_category_id: faq.category_id,
            prev_faq_answer: faq.faq_answer,
            prev_faq_status: faq.faq_status,
            prev_faq_topic: faq.faq_topic
        })
        this.tog_edit_faq();
    }

    updateFaq(id) {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "category_id": this.state.category_id,
            "faq_topic": this.state.faq_topic,
            "faq_answer": this.state.faq_answer,
            "faq_status": this.state.faq_status
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/faq/create/` + id, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.statusCode === "200") {
                    this.tog_edit_faq()
                    this.viewAllFaq()
                    this.setState({
                        category_id: '',
                        faq_topic: '',
                        faq_answer: '',
                        faq_status: '',
                        //faqUpdated: true
                    })
                    // setTimeout(() => {
                    //     this.setState({
                    //         faqUpdated: false
                    //     })
                    // }, 3000)
                    toast(<div className='toast-message-container'>
                    <h6>FAQ</h6>
                    <p>FAQ updated successfully.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-success'
                 })
                }
                else {
                    toast(<div className='toast-message-container'>
                    <h6>FAQ</h6>
                    <p>Something went wrong while updating the faq.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-error'
                 })
                }
            })
            .catch(error => console.log('error', error));

    }

    viewFaqCategory() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${url}/category/faq/active`, requestOptions)
            .then(response => response.json())
            .then(data => {
                var array = []
                for (let i = 0; i < data.body.length; i++) {
                    array.push({
                        id: data.body[i].id,
                        category_name: data.body[i].category_name,
                        category_description: data.body[i].category_description,
                        category_status: data.body[i].category_status,
                        created_at: data.body[i].created_at,
                        updated_at: data.body[i].updated_at
                    })
                }
                this.setState({ category: array })
            })
            .catch(error => console.log('error', error));

    }


    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        if(e.target.value === "custom"){
            this.setState({ [e.target.name]: "custom2",fromDate:'',toDate:'' })
            this.tog_add()
        }
        else{
            setTimeout(() => {
                // console.log("created",this.state.created_at_filter)
                this.viewAllFaq()
            }, 1500);
        }
    }

    tog_add() {
        this.setState(prevState => ({
            modal_add: !prevState.modal_add

        }));
    }
    tog_add2() {
        this.setState(prevState => ({
            modal_add: !prevState.modal_add,fromDate:'',toDate:'',created_at_filter:"",DateAlert:false

        }));
        setTimeout(() => {
            this.viewAllFaq()
        }, 1500);
    }

    handleDatechange(value){
        var dateofvisit = Moment(value, 'DD-MM-YYYY');
        var today = Moment();
        if(today.diff(dateofvisit, 'Hours') <= 0){
            this.setState({
                DateAlert: true,fromDate: Moment(value).format("YYYY-MM-DD")
            })
            
            return  
        }else{
            this.setState({
                DateAlert: false
            }) 
            this.setState({fromDate: Moment(value).format("YYYY-MM-DD")})
        }
    }
    handleToDatechange(value){
        var dateofvisit = Moment(value, 'DD-MM-YYYY');
        var today = Moment();
        
        if(today.diff(dateofvisit, 'Hours') <= 0){
            this.setState({
                DateAlert: true,toDate: Moment(value).format("YYYY-MM-DD")
            })
            
            return  
        }else if(this.state.fromDate !== ""){
            if(Moment(this.state.fromDate, 'YYYY-MM-DD').diff(dateofvisit, 'Hours') > 0){
                this.setState({
                    DateAlert: true,toDate: Moment(value).format("YYYY-MM-DD")
                })
            }else{
                this.setState({
                    DateAlert: false
                })
                this.setState({toDate: Moment(value).format("YYYY-MM-DD")})
            }
        }else{
            this.setState({
                DateAlert: false
            })
            this.setState({toDate: Moment(value).format("YYYY-MM-DD")})

        }
    }



    tog_edit_faq() {

        this.setState(prevState => ({
            modal_edit_faq: !prevState.modal_edit_faq
        }));
        if (this.state.modal_edit_faq !== false) {
            this.setState({
                category_id: '',
                faq_topic: '',
                faq_answer: '',
                faq_status: ''
            })
        }
    }
    tog_add_faq() {
        this.setState(prevState => ({
            modal_add_faq: !prevState.modal_add_faq
        }));
        this.setState({
            category_id: '',
            faq_topic: '',
            faq_answer: '',
            faq_status: ''
        })
    }

    clearFilter() {
        this.setState({
            cat_id_filter: '',
            state_filter: '',
            from_date_filter: '',
            to_date_filter: '',
            fromDate: '',
            toDate: '',
            created_at_filter: ''
        })
        setTimeout(() => {
            this.viewAllFaq()
        }, 100)

    }
    disableToast(){
        let toastDiv = document.querySelector('.toast-error');
        if(toastDiv == null){
            toast(<div className='toast-message-container'>
            <h6>FAQ</h6>
            <p>Please select a valid date.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
         })
        }
    }


    render() {

        const data = {
            columns: [
                {
                    label: 'Id',
                    field: 'id',
                    sort: 'asc',
                    width: 150
                },

                {
                    label: 'Faq Topic',
                    field: 'faq_topic',
                    sort: 'asc',
                    width: 270
                },
                {
                    label: 'Faq Answer',
                    field: 'faq_answer',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: 'Faq Status',
                    field: 'faq_status',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: 'Category Name',
                    field: 'category_name',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Created At',
                    field: 'created_at',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Updated At',
                    field: 'updated_at',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Action',
                    field: 'button',
                    sort: "disabled",
                    width: 100
                }
            ],
            rows: this.state.faq
        };


        return (
            <React.Fragment>
                
                {this.state.alert_confirm ? (
                    
                    <div className="modal" style={{display:"block"}} tabIndex="-1" role="dialog" id='alertBox'>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Delete FAQ</h5>
                                
                            </div>
                            <div className="modal-body">
                                <p>{"Are you sure you want to delete " + this.state.faq_topic + "?"}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" style={{background:"#FFFFFF0D", borderColor:"#2f2f34"}} className="btn" data-dismiss="modal"
                                onClick={() => {
                                    this.setState({
                                        alert_confirm: false,
                                    });
                                    document.getElementById('alertBox').style.display = 'none';
                                }} 
                                >Cancel</button>
                                <button type="button" className="btn"
                                onClick={() => {
                                    this.setState({ alert_confirm: false })
                                    this.deleteFaq();
                                    document.getElementById('alertBox').style.display = 'none';
                                }}
                                >Delete</button>
                            </div>
                            </div>
                        </div>
                    </div>

                ) : null}
                
                <div style={
                    {
                        marginTop: 20,
                        marginBottom: 30
                    }
                }>
                    <Button type="button"
                        style={{background: "#17A0FB", padding: "7px 14px", borderColor: "#17A0FB", fontWeight: 600}}
                        onClick={
                            () => this.tog_add_faq()
                        }
                        color="info"
                        className="waves-effect waves-light">
                        Add Faq</Button>
                </div>

                {/* Filters */}
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody >
                                <Row xs="12">
                                    <Col>
                                        <Label for="example-password-input" className=" col-form-label">Category</Label>
                                        <Col>
                                            <select className="form-control" name='cat_id_filter'
                                                value={
                                                    this.state.cat_id_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                {this.state.category.map((cat) => (
                                                    <option value={cat.id}>{cat.category_name}</option>
                                                ))}

                                            </select>
                                        </Col>
                                    </Col>

                                    <Col>
                                        <Label for="example-password-input" className="col-form-label">Status</Label>
                                        <Col>
                                            <select className="form-control" name='state_filter'
                                                value={
                                                    this.state.state_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                <option value="Active">Active</option>
                                                <option value="Inactive">Inactive</option>
                                            </select>
                                        </Col>
                                    </Col>

                                    <Col>
                                    <Label for="example-password-input" className=" col-form-label">Created At</Label>
                                        <Col>
                                            <select className="form-control" name='created_at_filter'
                                                value={
                                                    this.state.created_at_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                <option value="0">Today</option>
                                                <option value="1">Yesterday</option>
                                                <option value="7">Last 7 days</option>
                                                <option value="30">Last 30 days</option>
                                                <option value="180">Last 6 months</option>
                                                <option value="custom" onClick={this.tog_add}>Custom</option>
                                                <option value="custom2" hidden>{ this.state.created_at_filter === "custom2" ? "From: " + this.state.fromDate + " To: " + this.state.toDate : "Custom"}</option>
                                            </select>
                                        </Col>
                                    </Col>

                                </Row>
                                <Col className="text-left" style={{marginTop: 10}}>
                                    <Button type="button"
                                        style={{background: "#17A0FB", padding: "7px 14px", borderColor: "#17A0FB", fontWeight: 600}}
                                        onClick={
                                            this.viewAllFaq
                                        }
                                        color="warning"
                                        className="waves-effect waves-light">
                                        <i className='mdi mdi-filter-menu-outline'
                                            style={
                                                { marginRight: 10, fontSize: 15 }
                                            }
                                        ></i>
                                        Filter
                                    </Button>
                                    {
                                        this.state.cat_id_filter === '' && this.state.created_at_filter === '' && this.state.state_filter === '' ?
                                        <Button type="button"
                                            style={{ marginLeft: 10 ,border: "1px solid #6a6a71", borderRadius: "3px" ,backgroundColor: "rgba(80,80,88,.8)", transition: "background-color .2s linear", fontWeight: 600}}
                                            disabled
                                            onClick={
                                                () => this.clearFilter()
                                            }
                                            color="success"
                                            className="waves-effect waves-light">

                                            Clear Filter
                                    </Button>:
                                    <Button type="button"
                                        style={{ marginLeft: 10 ,border: "1px solid #E6536C", borderRadius: "3px" ,backgroundColor: "#E6536C", transition: "background-color .2s linear", padding:"7px 14px", fontWeight: 600}}
                                        onClick={
                                            () => this.clearFilter()
                                        }
                                        color="success"
                                        className="waves-effect waves-light">

                                        Clear Filter
                                    </Button>
                                    }
                                    
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {this.state.faqCreated ? <Alert color="success">
                    <strong>Faq created successfully.</strong>
                </Alert> : null}
                {this.state.faqUpdated ? <Alert color="success">
                    <strong>Faq updated successfully.</strong>
                </Alert> : null}
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <MDBDataTable
                                    responsive
                                    bordered
                                    striped
                                    data={data}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Modal isOpen={
                        this.state.modal_edit_faq
                    }
                        toggle={
                            this.tog_edit_faq
                        }
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">
                        <ModalHeader>
                            Edit Details
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup row className="formContainer2">
                                <Label for="example-text-input" className="col-form-label">Id</Label>
                                <Col>
                                    <Input className="w-100" disabled name="id"
                                        value={
                                            this.state.id
                                        }
                                        type="text"
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-text-input" />
                                </Col>
                            </FormGroup>

                            <FormGroup row className="formContainer2">
                                <Label for="example-tel-input" className="col-form-label">Faq Topic</Label>
                                <Col>
                                    <Input className="w-100" name="faq_topic" type="text"
                                        value={
                                            this.state.faq_topic
                                        }
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-search-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-tel-input" className="col-form-label">Faq Answer</Label>
                                <Col>
                                    <Input className="w-100" name="faq_answer" type="text"
                                        value={
                                            this.state.faq_answer
                                        }
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-search-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-password-input" className="col-sm-2 col-form-label">Category</Label>
                                <Col>
                                    <select className="selectFormContainer mb-0 w-100" name='category_id'
                                        value={
                                            this.state.category_id
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        {this.state.category.map((cat) => (
                                            <option value={cat.id}>{cat.category_name}</option>
                                        ))}

                                    </select>
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-password-input" className="col-form-label">Faq Status</Label>
                                <Col>
                                    <select className="selectFormContainer mb-0 w-100" name='faq_status'
                                        value={
                                            this.state.faq_status
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </Col>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons"
                                onClick={
                                    this.tog_edit_faq
                                }>Close
                            </Button>
                            {
                                this.state.faq_topic === this.state.prev_faq_topic && this.state.faq_answer === this.state.prev_faq_answer && this.state.faq_status === this.state.prev_faq_status && this.state.category_id === this.state.prev_category_id ?
                                <Button type="button" color="primary"
                                    disabled
                                    style={{cursor: "default"}}
                                    onClick={
                                        () => this.updateFaq(this.state.id)
                                    }
                                    className="waves-effect waves-light">Save changes
                                </Button>:
                                <Button type="button" color="primary"
                                    onClick={
                                        () => this.updateFaq(this.state.id)
                                    }
                                    className="waves-effect waves-light">Save changes
                                </Button>
                            }
                            
                        </ModalFooter>
                    </Modal>
                </Row>

                <Row>
                    <Modal isOpen={
                        this.state.modal_add_faq
                    }
                        toggle={
                            this.tog_add_faq
                        }
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">
                        <ModalHeader>
                            Add Details
                        </ModalHeader>
                        <ModalBody>

                            <FormGroup row className="formContainer2">
                                <Label for="example-tel-input" className="col-form-label">Faq Topic</Label>
                                <Col>
                                    <Input className="w-100" name="faq_topic" type="text"
                                        value={
                                            this.state.faq_topic
                                        }
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-search-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-tel-input" className="col-form-label">Faq Answer</Label>
                                <Col>
                                    <Input className="w-100" name="faq_answer" type="text"
                                        value={
                                            this.state.faq_answer
                                        }
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-search-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-password-input" className="col-form-label">Category</Label>
                                <Col>
                                    <select className="selectFormContainer w-100 mb-0" name='category_id'
                                        value={
                                            this.state.category_id
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                            <option value="" disabled selected hidden>Select</option>
                                        {this.state.category.map((cat) => (
                                            <option value={cat.id}>{cat.category_name}</option>
                                        ))}

                                    </select>
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-password-input" className="col-form-label">Faq Status</Label>
                                <Col>
                                    <select className="selectFormContainer mb-0 w-100" name='faq_status'
                                        value={
                                            this.state.faq_status
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </Col>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons"
                                onClick={
                                    this.tog_add_faq
                                }>Close
                            </Button>
                            {
                               this.state.faq_topic === '' || this.state.faq_answer === '' || this.state.faq_status === '' || this.state.category_id === '' ?
                                <Button type="button" color="primary"
                                style={{cursor: "default"}}
                                disabled
                                onClick={
                                    () => this.createFaq()
                                }
                                className="waves-effect waves-light">Add Faq</Button>
                                : <Button type="button" color="primary"
                                onClick={
                                    () => this.createFaq()
                                }
                                className="waves-effect waves-light">Add Faq</Button>
                            }
                            
                        </ModalFooter>
                    </Modal>
                </Row>

                <Row>
                    <Modal isOpen={
                        this.state.modal_add
                    }
                        toggle={
                            this.tog_add
                        }
                        autoFocus={true}
                        size="lg">
                        <ModalHeader toggle={
                            this.tog_add2
                        }>
                            Select Date
                        </ModalHeader>
                        <ModalBody>
                        {this.state.DateAlert ?  
                        this.disableToast()
                        : Moment(this.state.fromDate).isAfter(Moment(this.state.toDate)) || Moment(this.state.fromDate).isAfter(Moment()) || Moment(this.state.toDate).isAfter(Moment())?
                        this.disableToast()
                          : null}
                            <Row>
                                <Col>
                                    <Calendar 
                                        onChange={this.handleDatechange} 
                                    />
                                </Col>
                                <Col>
                                    <Calendar 
                                        onChange={this.handleToDatechange} 
                                    />
                                </Col>
                            </Row>                                                   
                        </ModalBody>
                        <ModalFooter>
                        {
                                 this.state.DateAlert === true || this.state.fromDate === '' || this.state.toDate === ''? 
                                 <Button type="button" color="primary" className="waves-effect" disabled
                                    onClick={
                                        this.viewAllFaq
                                    }>Save
                                </Button> 
                                : 
                                Moment(this.state.fromDate).isAfter(Moment(this.state.toDate)) || Moment(this.state.fromDate).isAfter(Moment()) || Moment(this.state.toDate).isAfter(Moment())?
                                <Button type="button" color="primary" className="waves-effect" disabled
                                    onClick={
                                        this.viewAllFaq
                                    }>Save
                                </Button> :
                                <Button type="button" color="primary" className="waves-effect"
                                    onClick={
                                        this.viewAllFaq
                                    }>{
                                        this.state.isLoadingSave === true ? 
                                            <div style={{display: "flex", justifyContent: "center"}}>
                                                <RotatingLines
                                                    strokeColor="white"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="10"
                                                    visible={this.state.isLoadingSave}
                                                />
                                            </div>
                                        : "Save"
                                    }
                                </Button>
                            }
                            
                        </ModalFooter>
                    </Modal>
                </Row>
                <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(Faq);