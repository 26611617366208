import React, { Component } from 'react';
import { Row, Col, Card, CardBody,  TabContent,
    TabPane,
    NavLink,
    NavItem,
    Nav,
    Table,
    Button, 
    Progress,
    Alert,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import { connect } from "react-redux";
import classnames from "classnames";
import { MDBDataTable } from 'mdbreact';
import Moment from "moment"
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { RotatingLines } from  'react-loader-spinner'
import ReactApexChart from 'react-apexcharts';
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import url from "../../helpers/apiUrl"
import '../../App.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "One Dash", link: "dashboard" },
                { title: "Dashboard", link: "#" },

            ],
            user: '',
            actUser: '',
            addtoBagCount: '',
            atob_country: [],
            purchased: '',
            view: '',
            activeTab1: "5",
            country: [],
            total_sales: '',
            integrations: [],
            shoppable: 0,
            unlimited: 0,
            GB10: 0,
            GB20: 0,
            GB50: 0,
            amazon: '',
            woocommerce: '',
            stripe: '',
            bigcommerce: '',
            shopify: '',
            fromDate: Moment(new Date()).format('YYYY-MM-DD'),
            toDate: Moment(new Date()).format('YYYY-MM-DD'),
            created_at: 0,
            modal_add: false,
            disableduser: '',
            activeuser: '0',
            progressbarCount: 0,
            geography: 'select',
            DateAlert: false,
            isLoadingSave: false,
            revenue:[]
        }
        this.totalUsers = this.totalUsers.bind(this);
        this.totalrevenue = this.totalrevenue.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.countryChangeHandler = this.countryChangeHandler.bind(this);
        this.addToBag = this.addToBag.bind(this);
        this.purchase = this.purchase.bind(this);
        this.totalViews = this.totalViews.bind(this);
        this.toggle1 = this.toggle1.bind(this);
        this.totalSales = this.totalSales.bind(this);
        this.totalIntegrations = this.totalIntegrations.bind(this);
        this.salesOverview = this.salesOverview.bind(this);
        this.handleDatechange = this.handleDatechange.bind(this);
        this.handleToDatechange = this.handleToDatechange.bind(this);
        this.tog_add = this.tog_add.bind(this);
        this.tog_add2 = this.tog_add2.bind(this);
        this.calendarApiCalls = this.calendarApiCalls.bind(this);
        this.disableToast = this.disableToast.bind(this);

    }

    componentDidMount() {
        this.props.setBreadcrumbItems("Dashboard", this.state.breadcrumbItems);
        if (localStorage.getItem("token") === null || localStorage.getItem("token") === undefined || localStorage.getItem("token") === "") {
            return (
                this.props.history.push('/login')
            );
        }
        
        
        this.totalUsers()
        this.totalrevenue()
        this.addToBag()
        this.purchase()
        this.totalViews()
        this.totalSales()
        this.totalIntegrations()
        this.salesOverview()
    }
    toggle1(tab) {
        if (this.state.activeTab1 !== tab) {
          this.setState({
            activeTab1: tab,
            geography: "select"
          });
        }
        //this.purchase()
    }

    totalUsers(){
        var date = this.state.created_at;
        var fromDate = ''
        var toDate = ''
        if (date === "custom" || date === "custom2") {
            fromDate = Moment(this.state.fromDate).format('YYYY-MM-DD')
            toDate = Moment(this.state.toDate).format('YYYY-MM-DD')
           
        } else if(date !== "") {
            if(date === "1"){
                fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
                toDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            }else{
            fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            toDate = Moment().format('YYYY-MM-DD')
            }
        }
       
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
            "fromDate": fromDate,
            "toDate": toDate
            });

            var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
            };

            fetch(`${url}/dashboard/users`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result.error === "Invalid Token"){
                    localStorage.removeItem('token');
                    sessionStorage.clear();
                    this.props.history.push('/login') 
                }
                if(result.statusCode === "200"){
                    if(this.state.modal_add === true){
                        this.setState(prevState => ({
                            modal_add: false
                        }));
                    } 
                    if(result.body.activeUsers.status.buckets.length===0){
                        this.setState({
                            activeuser: "0"
                        })
                    }
                    for(let i = 0; i < result.body.activeUsers.status.buckets.length; i++){
                        if(result.body.activeUsers.status.buckets[i].key === "active"){
                            this.setState({
                                activeuser: result.body.activeUsers.status.buckets.length == 0 ? "0" : result.body.activeUsers.status.buckets[i].doc_count
                            })   
                        }
                    }
                
                this.setState({
                    user: result.body.totalUsers,
                    disableduser: result.body.disabledUsers,
                })
                }
                    
            })
            .catch(error => console.log('error', error));
        
    }

    totalrevenue(){

        var date = this.state.created_at;
        var fromDate = ''
        var toDate = ''
        if (date === "custom" || date === "custom2") {
            fromDate = Moment(this.state.fromDate).format('YYYY-MM-DD')
            toDate = Moment(this.state.toDate).format('YYYY-MM-DD')
           
        } else if(date !== "") {
            if(date === "1"){
                fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
                toDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            }else{
            fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            toDate = Moment().format('YYYY-MM-DD')
            }
        }

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "fromDate": fromDate,
            "toDate": toDate
            });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/dashboard/revenue`, requestOptions)
        .then(response => response.json())
        .then(result => {
            this.setState({
                revenue:result.body.totalRevenue
            })
        }
        )
        .catch(error => console.log('error', error));
    }

    changeHandler = (e) => {
        this.setState({
            geography: "select"
        })
        this.setState({ [e.target.name]: e.target.value })
       
         
        if(e.target.value === "custom"){
            this.setState({fromDate:'',toDate:'' })

            this.tog_add()
        }

        else{
            
            setTimeout(() => {
                this.totalUsers();
                this.totalrevenue()
                this.addToBag()
                this.purchase()
                this.totalViews()
                this.totalSales()
                this.totalIntegrations()
                this.salesOverview()
            }, 1000);
        }
       
    }

    countryChangeHandler = (e) => {
        this.setState({
            geography: e.target.value
        })
        if(e.target.value == "purchase"){
            this.purchase()
        }
         if(e.target.value == "view"){
            this.totalViews()
        }
         if(e.target.value == "a2b"){
            this.addToBag()
        }
    }

    addToBag(){

        const regionNames = new Intl.DisplayNames(
            ['en'], {type: 'region'}
        );

        var date = this.state.created_at;
        var fromDate = ''
        var toDate = ''
        if (date === "custom" || date === "custom2") {
            fromDate = Moment(this.state.fromDate).format('YYYY-MM-DD')
            toDate = Moment(this.state.toDate).format('YYYY-MM-DD')
           
        } else if(date !== "") {
            if(date === "1"){
                fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
                toDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            }else{
            fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            toDate = Moment().format('YYYY-MM-DD')
            }
        }
        
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "fromDate": fromDate,
            "toDate": toDate
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/dashboard/addtobag`, requestOptions)
        .then(response => response.json())
        .then(result => {
            
            var atobCoArr = []
            
                if(this.state.modal_add === true){
                    this.setState(prevState => ({
                        modal_add: false
                    }));
                } 
            
                for(let i = 0 ; i < result.body.geography.interactionType.buckets.length; i++){
                    atobCoArr.push({
                        country_name: regionNames.of(result.body.geography.interactionType.buckets[i].key),
                        country_count: result.body.geography.interactionType.buckets[i].doc_count
                    })
                }
            
            this.setState({
                addtoBagCount: result.body.addtoBagCount,
                country: atobCoArr
            })
        })
        .catch(error => console.log('error', error));
    
    }

    purchase(){
        const regionNames = new Intl.DisplayNames(
            ['en'], {type: 'region'}
        );

        var date = this.state.created_at;
        var fromDate = ''
        var toDate = ''
        if (date === "custom" || date === "custom2") {
            fromDate = Moment(this.state.fromDate).format('YYYY-MM-DD')
            toDate = Moment(this.state.toDate).format('YYYY-MM-DD')
           
        } else if(date !== ""){
            if(date === "1"){
                fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
                toDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            }else{
            fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            toDate = Moment().format('YYYY-MM-DD')
            }
        }
       

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "fromDate": fromDate,
            "toDate": toDate
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/dashboard/purchases`, requestOptions)
        .then(response => response.json())
        .then(result => {
                var array = []
                
                    if(this.state.modal_add === true){
                        this.setState(prevState => ({
                            modal_add: false
                        }));
                    } 
                
                for(let i = 0; i<result.body.geography.interactionType.buckets.length; i++ ){
                    array.push({
                        country_name: regionNames.of(result.body.geography.interactionType.buckets[i].key),
                        country_count: result.body.geography.interactionType.buckets[i].purchase_count.value
                    })
                }
            
            this.setState({
                purchased: result.body.purchases,
                country: array   
            })
        })
        .catch(error => console.log('error', error));
    
    }

    totalViews(){
      
        const regionNames = new Intl.DisplayNames(
            ['en'], {type: 'region'}
        );

        var date = this.state.created_at;
        var fromDate = ''
        var toDate = ''
        if (date === "custom" || date === "custom2") {
            fromDate = Moment(this.state.fromDate).format('YYYY-MM-DD')
            toDate = Moment(this.state.toDate).format('YYYY-MM-DD')
           
        } else if(date !== "") {
            if(date === "1"){
                fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
                toDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            }else{
            fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            toDate = Moment().format('YYYY-MM-DD')
            }
        }
        
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "fromDate": fromDate,
        "toDate": toDate
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/dashboard/views`, requestOptions)
        .then(response => response.json())
        .then(result => {
            var array =[]
            
                if(this.state.modal_add === true){
                    this.setState(prevState => ({
                        modal_add: false
                    }));
                } 
            
            for(let i = 0; i< result.body.geography.interactionType.buckets.length; i++ ){
                array.push({
                    country_name: regionNames.of(result.body.geography.interactionType.buckets[i].key),
                    country_count: result.body.geography.interactionType.buckets[i].doc_count
                })
            }
            this.setState({
                view: result.body.views,
                country: array
            })
        })
        .catch(error => console.log('error', error));
    
    }

    totalSales(){
        var date = this.state.created_at;
        var fromDate = ''
        var toDate = ''
        if (date === "custom" || date === "custom2") {
            fromDate = Moment(this.state.fromDate).format('YYYY-MM-DD')
            toDate = Moment(this.state.toDate).format('YYYY-MM-DD')
           
        } else if(date !== ""){
            if(date === "1"){
                fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
                toDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            }else{
            fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            toDate = Moment().format('YYYY-MM-DD')
            }
        }
        
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "fromDate": fromDate,
        "toDate": toDate
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/dashboard/totalsales`, requestOptions)
        .then(response => response.json())
        .then(result => {
            
                if(this.state.modal_add === true){
                    this.setState(prevState => ({
                        modal_add: false
                    }));
                } 
            
            this.setState({
                total_sales: result.body.total_sales
            })
        })
        .catch(error => console.log('error', error));
    
    }

    totalIntegrations(){
        var date = this.state.created_at;
        var fromDate = ''
        var toDate = ''
        if (date === "custom" || date === "custom2") {
            fromDate = Moment(this.state.fromDate).format('YYYY-MM-DD')
            toDate = Moment(this.state.toDate).format('YYYY-MM-DD')
           
        } else if(date !== "") {
            if(date === "1"){
                fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
                toDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            }else{
            fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            toDate = Moment().format('YYYY-MM-DD')
            }
        }
        
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "fromDate": fromDate,
        "toDate": toDate
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/dashboard/integration-report`, requestOptions)
        .then(response => response.json())
        .then(result => {
        this.setState({isLoadingSave : false})

                if(this.state.modal_add === true){
                    this.setState(prevState => ({
                        modal_add: false
                    }));
                 
            }
            
            this.setState({
                amazon: result.body.amazon.value,
                shopify: result.body.shopify.value,
                bigcommerce: result.body.bigcommerce.value,
                stripe: result.body.stripe.value,
                woocommerce: result.body.woocommerce.value
            })
        })
        .catch(error => console.log('error', error));
    
    }

    salesOverview(){
        var date = this.state.created_at;
        var fromDate = ''
        var toDate = ''
        if (date === "custom" || date === "custom2") {
            fromDate = Moment(this.state.fromDate).format('YYYY-MM-DD')
            toDate = Moment(this.state.toDate).format('YYYY-MM-DD')
           
        } else if(date !== "") {
            if(date === "1"){
                fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
                toDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            }else{
            fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            toDate = Moment().format('YYYY-MM-DD')
            }
        }
        
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "fromDate": fromDate,
        "toDate": toDate
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/dashboard/sales-overview`, requestOptions)
        .then(response => response.json())
        .then(result => {
            
                if(this.state.modal_add === true){
                    this.setState(prevState => ({
                        modal_add: false
                    }));
                } 
                if(result.body.plans.all_plans.buckets.length === 0){
                    this.setState({
                        shoppable: 0,
                        unlimited: 0,
                        GB10: 0,
                        GB20: 0,
                        GB50: 0,
                        progressbarCount: 0
                    })
                }
            
            this.setState({
                shoppable: 0,
                unlimited: 0,
                GB10: 0,
                GB20: 0,
                GB50: 0  
            })
           for(let i = 0; i< result.body.plans.all_plans.buckets.length; i++){

                if(result.body.plans.all_plans.buckets[i].key == "Shoppable"){
                    this.setState({ shoppable: result.body.plans.all_plans.buckets[i].doc_count })
                }
                
                if(result.body.plans.all_plans.buckets[i].key == "Unlimited"){
                    this.setState({ unlimited: result.body.plans.all_plans.buckets[i].doc_count })
                }
                
                if(result.body.plans.all_plans.buckets[i].key == "10GB"){
                    this.setState({ GB10: result.body.plans.all_plans.buckets[i].doc_count })
                }
                
                if(result.body.plans.all_plans.buckets[i].key == "20GB"){
                    this.setState({ GB20: result.body.plans.all_plans.buckets[i].doc_count })
                }
                
                if(result.body.plans.all_plans.buckets[i].key == "50GB"){
                    this.setState({ GB50: result.body.plans.all_plans.buckets[i].doc_count })
                }
                
                this.setState({
                    progressbarCount: this.state.shoppable + this.state.unlimited + this.state.GB10 + this.state.GB20 + this.state.GB50
                })
           
           }
           
        })
        .catch(error => console.log('error', error));
    
    }

    tog_add() {
        this.setState(prevState => ({
            modal_add: !prevState.modal_add
        }));
    }
    tog_add2() {
        this.setState(prevState => ({
            modal_add: !prevState.modal_add,fromDate:Moment(new Date()).format('YYYY-MM-DD'),toDate:Moment(new Date()).format('YYYY-MM-DD'),created_at:0,DateAlert:false

        }));
        setTimeout(() => {
            this.setState({isLoadingSave : true})
            this.totalUsers()
            this.totalrevenue();
            this.addToBag()
            this.purchase()
            this.totalViews()
            this.totalSales()
            this.totalIntegrations()
            this.salesOverview()
        }, 1500);
        
    }

    handleDatechange(value){
        var dateofvisit = Moment(value, 'DD-MM-YYYY');
        var today = Moment();
        if(today.diff(dateofvisit, 'Hours') <= 0){
            this.setState({
                DateAlert: true,fromDate: Moment(value).format("YYYY-MM-DD")
            })
            
            return  
        }else{
            this.setState({
                DateAlert: false
            }) 
            this.setState({fromDate: Moment(value).format("YYYY-MM-DD")})
        }
    }
    handleToDatechange(value){
        var dateofvisit = Moment(value, 'DD-MM-YYYY');
        var today = Moment();
        
        if(today.diff(dateofvisit, 'Hours') <= 0){
            this.setState({
                DateAlert: true,toDate: Moment(value).format("YYYY-MM-DD")
            })
            
            return  
        }else if(this.state.fromDate !== ""){
            if(Moment(this.state.fromDate, 'YYYY-MM-DD').diff(dateofvisit, 'Hours') > 0){
                this.setState({
                    DateAlert: true,toDate: Moment(value).format("YYYY-MM-DD")
                })
            }else{
                this.setState({
                    DateAlert: false
                })
                this.setState({toDate: Moment(value).format("YYYY-MM-DD")})
            }
        }else{
            this.setState({
                DateAlert: false
            })
            this.setState({toDate: Moment(value).format("YYYY-MM-DD")})

        }
    }
    calendarApiCalls(){
        this.setState({isLoadingSave : true})
        this.totalUsers()
        this.totalrevenue();
        this.addToBag()
        this.purchase()
        this.totalViews()
        this.totalSales()
        this.totalIntegrations()
        this.salesOverview()
        this.setState({
            created_at: 'custom2'
        })
    }
    disableToast(){
        let toastDiv = document.querySelector('.toast-error');
        if(toastDiv == null){
            toast(<div className='toast-message-container'>
                <h6>Dashboard</h6>
                <p>Please select a valid date.</p>
                </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
         })
        }
    }


    render() {
        const data = {
            columns: [
                {
                    label: 'Country Name',
                    field: 'country_name',
                    sort: 'asc',
                    width: 150
                },

                {
                    label: 'Count',
                    field: 'country_count',
                    sort: 'asc',
                    width: 270
                },
               
            ],
            rows: this.state.country
        };
        
        
        return (
            <React.Fragment>
                <Row style={{display: "grid", justifyItems: "end", marginBottom: 20}}>
                    <Col xs="2">
                        <select className="form-control" name='created_at'
                            value={
                                this.state.created_at
                            }
                            onChange={
                                this.changeHandler
                            }>
                            <option value="0">Today</option>
                            <option value="1">Yesterday</option>
                            <option value="7">Last 7 days</option>
                            <option value="30">Last 30 days</option>
                            <option value="180">Last 6 months</option>
                            <option value="custom" onClick={this.tog_add}>{ this.state.created_at === "custom" ? "From: " + this.state.fromDate + " To: " + this.state.toDate : "Custom"}</option>
                            <option value="custom2" hidden onClick={this.tog_add}>{ this.state.created_at === "custom2" ? "From: " + this.state.fromDate + " To: " + this.state.toDate : "Custom"}</option>
                        </select>
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                    <Row>
                    
                        <Col xs="2">
                            <h5>Total User</h5>
                            <h6>{this.state.user}</h6>
                           
                            
                        </Col>
                        <Col xs="2">
                            <h5>Disabled Users</h5>
                            <h6>{this.state.disableduser}</h6>
                        </Col>
                        <Col xs="2">
                            <h5>Active User</h5>
                            <h6>{this.state.activeuser}</h6>
                        </Col>
                        <Col xs= "4">
                            <h5>Integrations</h5>
                            <Row>
                                <Col xs="12">
                                    
                                    <p>Amazon: {this.state.amazon}</p>
                                    <p>Bigcommerce: {this.state.bigcommerce}</p>
                                    <p>Woocommerce: {this.state.woocommerce}</p>
                                    <p>Stripe: {this.state.stripe}</p>
                                    <p>Shopify: {this.state.shopify}</p>
                                    
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="2">
                            <h5>Total Sales Value</h5>
                            <h6>$ {this.state.total_sales}</h6>
                        </Col>
                    
                    </Row>
                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                    <Row>
                    
                        <Col xs="2">
                            <h5>Add To Bag</h5>
                            <h6>{this.state.addtoBagCount}</h6>
                        </Col>
                        <Col xs="2">
                            <h5>Purchased</h5>
                            <h6>{this.state.purchased}</h6>
                        </Col>
                        <Col xs="2">
                            <h5>Total Views</h5>
                            <h6>{this.state.view}</h6>
                        </Col>
                        <Col xs="6">
                            <h5>Revenue</h5>
                            <Row>
                                <Col xs="12">
                                    <div style={{height: '150px', overflowY:'scroll'}}>
                                <table style={{border:"none"}}>

                                        <thead>
                                            <tr>
                                                <th>Shop URL</th>
                                                <th>Revenue</th>
                                            </tr>
                                        </thead>
                                        
                                        {
                                            this.state.revenue.length===0? <div className='text-center' style={{paddingTop:"15px"}}>No data available</div>:
                                            <tbody>
                                                 
                                        {
                                            this.state.revenue.map(item=>(
                                                <tr>
                                                    <td>{item.shopUrl || "---"}</td>
                                                    <td>{item.revenue} {item.currency}</td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                        }
                                        
                                        </table>
                                        </div>
                                    
                                </Col>
                            </Row>
                        </Col>
                    
                    </Row>
                    </CardBody>
                </Card>

                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <Nav pills justified>
                                    <NavItem className="waves-effect waves-light">
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: this.state.activeTab1 === "5"
                                            })}
                                            onClick={() => {
                                                this.toggle1("5");
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                            <span className="d-none d-sm-block">Overview</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="waves-effect waves-light">
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: this.state.activeTab1 === "6"
                                            })}
                                            onClick={() => {
                                                this.toggle1("6");
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                            <span className="d-none d-sm-block">Geography</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>


                                <TabContent activeTab={this.state.activeTab1}>
                                    <TabPane tabId="5" className="p-3">
                                        <Row>
                                            <Col xs="6">
                                                <div className="">
                                                    <div className="mb-4">
                                                        <Row>
                                                            <Col>
                                                                <p>Shoppable</p> 
                                                            </Col>
                                                            <Col>
                                                                <p style={{display: "grid", justifyItems: "end"}}>{this.state.shoppable} purchases</p>
                                                            </Col>
                                                        </Row>
                                                        <Progress color="primary" value={this.state.shoppable} ></Progress>
                                                    </div>
                                                    <div className="mb-4">
                                                        <Row>
                                                            <Col>
                                                            <p>Unlimited</p> 
                                                            </Col>
                                                            <Col>
                                                            <p style={{display: "grid", justifyItems: "end"}}>{ this.state.unlimited } purchases</p>
                                                            </Col>
                                                        </Row>
                                                        
                                                        <Progress color="primary"  value={this.state.unlimited} ></Progress>
                                                    </div>
                                                    <div className="mb-4">
                                                        <Row>
                                                            <Col>
                                                            <p>10GB</p> 
                                                            </Col>
                                                            <Col>
                                                            <p style={{display: "grid", justifyItems: "end"}}>{ this.state.GB10} purchases</p>
                                                            </Col>
                                                        </Row>
                                                        <Progress color="primary" value={this.state.GB10} ></Progress>
                                                    </div>
                                                    <div className="mb-4">
                                                        <Row>
                                                            <Col>
                                                            <p>20GB</p> 
                                                            </Col>
                                                            <Col>
                                                            <p style={{display: "grid", justifyItems: "end"}}>{ this.state.GB20 } purchases</p>
                                                            </Col>
                                                        </Row>
                                                        <Progress color="primary" value={this.state.GB20} ></Progress>
                                                    </div>
                                                    <div className="mb-4">
                                                        <Row>
                                                            <Col>
                                                            <p>50GB</p> 
                                                            </Col>
                                                            <Col>
                                                            <p style={{display: "grid", justifyItems: "end"}}>{ this.state.GB50} purchases</p>
                                                            </Col>
                                                        </Row>
                                                        <Progress color="primary" value={this.state.GB50} ></Progress>
                                                    </div>
                                                    
                                                </div>
                                            </Col>
                                        </Row>
                                    
                                    </TabPane>
                                    <TabPane tabId="6" className="p-3">
                                        <p>Geographic Distribution</p>
                                        <Col xs="3">
                                            <select className="form-control" name='country'
                                            value={this.state.geography}
                                                onChange={
                                                    this.countryChangeHandler
                                                }>
                                                <option value="select" selected hidden> Select</option>
                                                <option value="purchase">In Purchase</option>
                                                <option value="view">In Views</option>
                                                <option value="a2b">In Add To Bag</option>
                                            </select>
                                        </Col>

                                        <Row>
                                            <Col xs="6">
                                                <Card>
                                                    <CardBody>
                                                        {
                                                           this.state.geography === "select" ?
                                                           "" :
                                                            <MDBDataTable
                                                                responsive
                                                                bordered
                                                                striped
                                                                data={data}
                                                            />
                                                        }
                                                        
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col xs="6">
                                                {
                                                     this.state.geography === "select" ?
                                                     "" :
                                                     <ReactApexChart 
                                                    options={ {
                                                        labels: this.state.country.map((abc) => abc.country_name),
                                                        dataLabels: {
                                                            enabled: true
                                                        },
                                                        legend: {
                                                            show: true,
                                                            labels: {
                                                                colors: ["#FFFFFF"], 
                                                            }
                                                        },
                                                        responsive: [{
                                                            breakpoint: 480,
                                                            options: {
                                                                chart: {
                                                                    width: 100,
                                                                },
                                                                legend: {
                                                                    position: 'bottom'
                                                                }
                                                            }
                                                        }]
                                                    }} 
                                                    series={this.state.country.map((pqr) => pqr.country_count)} 
                                                    type="donut" 
                                                    height="285" 
                                                />
                                                }
                                                
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Modal isOpen={
                        this.state.modal_add
                    }
                        toggle={
                            this.tog_add
                        }
                        autoFocus={true}
                        size="lg">
                        <ModalHeader toggle={
                            this.tog_add2
                        }>
                            Select Date
                        </ModalHeader>
                        <ModalBody>
                        {this.state.DateAlert ?  
                        this.disableToast()
                        : Moment(this.state.fromDate).isAfter(Moment(this.state.toDate)) || Moment(this.state.fromDate).isAfter(Moment()) || Moment(this.state.toDate).isAfter(Moment())?
                        this.disableToast()
                          : null}
                            <Row>
                                <Col>
                                    <Calendar 
                                        onChange={this.handleDatechange} 
                                    />
                                </Col>
                                <Col>
                                    <Calendar 
                                        onChange={this.handleToDatechange} 
                                    />
                                </Col>
                            </Row>                                                   
                        </ModalBody>
                        <ModalFooter>
                        {
                                 this.state.DateAlert === true || this.state.fromDate === '' || this.state.toDate === ''? 
                                 <Button type="button" color="primary" className="waves-effect" disabled
                                    onClick={
                                        this.calendarApiCalls
                                    }>Save
                                </Button> 
                                : 
                                Moment(this.state.fromDate).isAfter(Moment(this.state.toDate))  || Moment(this.state.fromDate).isAfter(Moment()) || Moment(this.state.toDate).isAfter(Moment())?
                                <Button type="button" color="primary" className="waves-effect" disabled
                                    onClick={
                                        this.calendarApiCalls
                                    }>Save
                                </Button> :
                                <Button type="button" color="primary" className="waves-effect"
                                    onClick={
                                        this.calendarApiCalls
                                    }>{
                                        this.state.isLoadingSave === true ? 
                                            <div style={{display: "flex", justifyContent: "center"}}>
                                                <RotatingLines
                                                    strokeColor="white"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="10"
                                                    visible={this.state.isLoadingSave}
                                                />
                                            </div>
                                        : "Save"
                                    }
                                </Button>
                            }
                            
                        </ModalFooter>
                    </Modal>
                </Row>
                <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(Dashboard);