import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import {
    toggleSidebar,
    hideRightSidebar,
    changeTopbarTheme,
    changeLayout,
    changeSidebarTheme,
    changeLayoutWidth,
    changeSidebarType,
    changePreloader
} from '../../../store/actions';

//Import Images
import logosm from "../../../assets/logo1.png";
import logodark from "../../../assets/logo1.png";
import logolight from "../../../assets/logo1.png";

// MetisMenu
import MetisMenu from "metismenujs";

class SidebarContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_toggle: false,
            layoutType: this.props.layoutType,
            topbarTheme: this.props.topbarTheme,
            leftSideBarTheme: this.props.leftSideBarTheme,
            layoutWidth: this.props.layoutWidth,
            sidebarType: this.props.leftSideBarType,
            isPreloader: this.props.isPreloader,
            roleLogin: ''
        }
        this.changeLayout = this.changeLayout.bind(this);
        this.changeTopbarTheme = this.changeTopbarTheme.bind(this);
        this.changeLeftSidebarTheme = this.changeLeftSidebarTheme.bind(this);
        this.changeLayoutWidth = this.changeLayoutWidth.bind(this);
        this.changeLeftSidebarType = this.changeLeftSidebarType.bind(this);
        this.changeThemePreloader = this.changeThemePreloader.bind(this);
    }

    componentDidMount() {
        document.body.setAttribute('data-sidebar', 'dark');
        this.initMenu();
        // console.log("role", localStorage.getItem("role" + "sidebar"))
        this.setState({
            roleLogin: localStorage.getItem("role") || ''
        })
        this.forceUpdate();
    }


    // update local state after changing layout
    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                layoutType: this.props.layoutType,
                topbarTheme: this.props.topbarTheme,
                leftSideBarTheme: this.props.leftSideBarTheme,
                layoutWidth: this.props.layoutWidth,
                sidebarType: this.props.leftSideBarType,
                isPreloader: this.props.isPreloader
            });
        }

        if (this.props.leftSideBarType !== prevProps.leftSideBarType) {
            this.initMenu();
        }
    }

    initMenu() {
        new MetisMenu("#side-menu");

        var matchingMenuItem = null;
        var ul = document.getElementById("side-menu");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }

    }

    activateParentDropdown = (item) => {

        item.classList.add('mm-active');
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add('mm-active'); // li
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add('mm-active'); // li
                    parent3.childNodes[0].classList.add('mm-active'); // a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add('mm-active');
                    }
                }
            }
            return false;
        }
        return false;
    }
    // change layput type and dispatch action
    changeLayout() {
        this.props.changeLayout("horizontal");
    }

    // theme preloader
    changeThemePreloader = (value) => {
        this.props.changePreloader(!this.props.isPreloader);
    }

    // change left sidebar theme
    changeLeftSidebarTheme(value) {
        this.props.changeSidebarTheme(value);
    }

    // change layout width
    changeLayoutWidth(value) {
        if (this.state.layoutWidth === "boxed")
            this.props.changeLayoutWidth("fluid", this.state.layoutType);
        else
            this.props.changeLayoutWidth("boxed", this.state.layoutType);



    }

    // change topbar theme and dispatch action
    changeTopbarTheme(value) {
        this.props.changeTopbarTheme(value);
    }

    // change sidebar type
    changeLeftSidebarType(value) {
        this.props.changeSidebarType(value);
    }

    render() {
        return (
            <React.Fragment>
                <div id="sidebar-menu" style={{ height: "100% !important", width:"240px", fontSize:"12px", fontWeight: "600" }}>

                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title">Main</li>

                        <li>
                            <Link to="/dashboard" className="waves-effect">
                                <i className="mdi mdi-view-dashboard"></i>
                                
                                <span>Dashboard</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/" className=" waves-effect">
                                <i className="dripicons-graph-bar"></i>
                                <span>Analytics</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li style={{display: "flex", alignItems: "center"}}>
                                   
                                        <span className="mdi mdi-video-outline icons-sidebar" style={{fontSize:"20px", color: "#8699AD"}}></span>
                                        <Link to="video-reports">Video Reports</Link>
                                   
                                </li>
                                <li style={{display: "flex", alignItems: "center"}}>
                                   
                                        <span className="typcn typcn-shopping-bag icons-sidebar" style={{fontSize:"19px", color: "#8699AD", marginTop:"-2px"}}></span>
                                        <Link to="product-reports">Product Reports</Link>
                                    
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/sales" className="waves-effect">
                                <i className="ion ion-md-stats"></i>
                                <span>Sales</span>
                            </Link>
                        </li>

                        <li className="menu-title">Managements</li>

                        <li>
                            <Link to="rolesmanagement" className=" waves-effect">
                                <i className="fas fa-user"></i>
                                <span>Portal Users</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="user" className=" waves-effect">
                                <i className="far fa-user"></i>
                                <span>User Management</span>
                            </Link>

                        </li>

                        <li>
                            <Link to="subscription" className=" waves-effect">
                                <i className="mdi mdi-calendar-check"></i>
                                <span>Subscriptions</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="" className=" waves-effect">
                                <i className="fas fa-headset"></i>
                                <span>Customer Support</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li style={{display: "flex", alignItems: "center"}}>
                                   
                                        <span className="ti-support icons-sidebar" style={{fontSize:"20px", color: "#8699AD"}}></span>
                                        <Link to="customerservice">Ticket Management</Link>
                                    
                                </li>
                                <li style={{display: "flex", alignItems: "center"}}>
                                        <span className="mdi mdi-menu icons-sidebar" style={{fontSize:"20px", color: "#8699AD"}}></span>
                                         <Link to="supportCategory">Add Category</Link>
                                </li>
                                <li style={{display: "flex", alignItems: "center"}}>
                                        <span className="mdi mdi-priority-high icons-sidebar" style={{fontSize:"20px", color: "#8699AD"}}></span>
                                        <Link to="supportPriority">Add Priority</Link>
                                    
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/" className=" waves-effect">
                                <i className=" 	fas fa-bullhorn"></i>
                                <span>Marketing</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li style={{display: "flex", alignItems: "center"}}>
                                    <span className="mdi mdi-inbox icons-sidebar"></span>
                                    <Link to="campaign">Campaign</Link>
                                </li>
                                <li style={{display: "flex", alignItems: "center"}}>
                                    <span className="mdi mdi-playlist-plus icons-sidebar"></span>
                                    <Link to="list-management">List Management</Link>
                                </li>
                                <li style={{display: "flex", alignItems: "center"}}>
                                    <span className="mdi mdi-email-outline icons-sidebar"></span>
                                    <Link to="template-managemnet">Template Management</Link>
                                </li>
                                <li style={{display: "flex", alignItems: "center"}}>
                                    <span className="mdi mdi-account-multiple-outline icons-sidebar"></span>
                                    <Link to="contacts">Contacts</Link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/" className=" waves-effect">
                                <i className="mdi mdi-comment-question-outline"></i>
                                <span>FAQ</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li style={{display: "flex", alignItems: "center"}}>
                                    <span className="mdi mdi-comment-question-outline icons-sidebar"></span>
                                    <Link to="faq">Faq</Link>
                                </li>
                                <li style={{display: "flex", alignItems: "center"}}>
                                    <span className="mdi mdi-comment-plus-outline icons-sidebar"></span>
                                    <Link to="faqCategory">Add Category</Link>
                                </li>

                            </ul>
                        </li>
                        
                        <li>
                            <Link to="profile" className=" waves-effect">
                                <i className="mdi mdi-account-circle font-size-17 text-muted align-middle mr-1"></i>
                                <span>Profile </span>
                            </Link>
                        </li>
                        <li>
                            <Link to="logout" className=" waves-effect">
                                <i className="mdi mdi-power font-size-17 text-muted align-middle mr-1 text-danger"></i>
                                <span>Logout </span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {
        is_toggle,
        leftSideBarType,
        layoutType,
        leftSideBarTheme,
        layoutWidth,
        topbarTheme,
        isPreloader
    } = state.Layout;
    return {
        is_toggle,
        leftSideBarType,
        layoutType,
        leftSideBarTheme,
        layoutWidth,
        topbarTheme,
        isPreloader
    };
}

export default withRouter(connect(mapStatetoProps, {
    toggleSidebar,
    hideRightSidebar,
    changeLayout,
    changeTopbarTheme,
    changeSidebarTheme,
    changeLayoutWidth,
    changeSidebarType,
    changePreloader
})(SidebarContent)); 