import React, {Component} from 'react';
import {connect} from "react-redux";
import {Row, Col, Card, CardBody, Button} from "reactstrap";

import { MDBDataTable } from 'mdbreact';
import { Link } from "react-router-dom";
import Moment from "moment";
import ReactApexChart from 'react-apexcharts';

// Import Action to copy breadcrumb items from local state to redux state
import {setBreadcrumbItems} from "../../store/actions";
import url from "../../helpers/apiUrl"
import productImage from '../../helpers/productImage';

class VideoDeatails extends Component {
    constructor(props) {
        if (localStorage.getItem("token") === null) {
            return (
                this.props.history.push('/login')
            );
        }
        super(props);
        const { projects } = this.props.location.state
        const { created_at } = this.props.location.state
        this.state = {
            breadcrumbItems: [
                {
                    title: "One Dash",
                    link: "dashboard"
                }, {
                    title: "Video Report",
                    link: "video-reports"
                },
                {
                    title: "Video Details",
                    link: "#"
                },
            ],
            products: [],
            products_details: [],
            countryCount: [],
            interaction: [],
            device: [],
            id: projects._id,
            created_at: created_at,
            fromDate: '',
            toDate: '',
            project_name: projects._source.name,
            project_type: projects._source.projectType,
            project_status: projects._source.status
        }
        this.videoReports = this.videoReports.bind(this);
        this.productDetails = this.productDetails.bind(this);
    }

    componentDidMount() {
        this.props.setBreadcrumbItems("Video Details", this.state.breadcrumbItems);
        
        this.videoReports()
        this.productDetails()
        
    }

    videoReports(){
        var date = this.state.created_at;
        var fromDate = ''
        var toDate = ''
        if (date === "") {
            fromDate = ""
            toDate = ""
        } else {
            if(date === "1"){
                fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
                toDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            }else{
            fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            toDate = Moment().format('YYYY-MM-DD')
            }
        }

        const regionNames = new Intl.DisplayNames(
            ['en'], {type: 'region'}
        );

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "projectId": this.state.id,
        "fromDate": fromDate,
        "toDate": toDate
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/analytics/video-report`, requestOptions)
        .then(response => response.json())
        .then(result =>{ 
            if(result.error === "Invalid Token"){
                localStorage.removeItem('token');
                sessionStorage.clear();
                this.props.history.push('/login') 
            }
            var array_country = []
            var array_interaction =[]
            var array_device = []
            var array_product = []
            if(result.statusCode === "200"){
                for(let i=0; i< result.body.country.buckets.length; i++){
                    if(result.body.country.buckets[i].country_views.doc_count != 0){
                        array_country.push({
                            country_name: regionNames.of(result.body.country.buckets[i].key),
                            country_count: result.body.country.buckets[i].country_views.doc_count,
                        })
                    }
                    
                }
                this.setState({countryCount: array_country})
                array_interaction.push({
                    interaction_count: result.body.interaction_types.doc_count
                })

                for(let i=0; i< result.body.device.buckets.length; i++){
                    if(result.body.device.buckets[i].device_views.doc_count!=0){
                    array_device.push({
                        device_name: result.body.device.buckets[i].key,
                        device_view: result.body.device.buckets[i].device_views.doc_count
                    })
                    }
                }

                for(let i=0; i< result.body.products.buckets.length; i++){
                    array_product.push({
                        key: result.body.products.buckets[i].key
                    })
                }
            }
            this.setState({
                
                interaction: array_interaction, 
                device: array_device, 
                products: array_product
            })
            for(let j = 0; j< this.state.products.length; j++){
                this.productDetails(this.state.products[j].key)
            }
            

            
        })
        .catch(error => console.log('error', error));
    }

    productDetails(key){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "productId": key
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/analytics/product-details`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.error === "Invalid Token"){
                localStorage.removeItem('token');
                sessionStorage.clear();
                this.props.history.push('/login') 
            }
            var arr = []
            if(result.statusCode === "200"){
                for(let i=0; i < result.body.length; i++){
                    arr.push({
                        name: result.body[i]._source.name,
                        shopUrl: result.body[i]._source.shopUrl,
                        vendorType:  result.body[i]._source.vendorType,
                        currencySymbol: result.body[i]._source.currencySymbol,
                        image: <img style={{height: 150, width: 100}} src={`${productImage}` + result.body[i]._source.image.src }></img>
                    })
                }
            }
            var array = this.state.products_details
            array = array.concat(arr)
            this.setState({ products_details: array })
        })
        .catch(error => console.log('error', error));
    }

    render() {
        const data = {
            columns: [
                {
                    label: 'Country Name',
                    field: 'country_name',
                    width: 150
                },
                {
                    label: 'Views',
                    field: 'country_count',
                    width: 150
                }
            ],
            rows: this.state.country
        };

        const device = {
            columns: [
                {
                    label: 'Device Name',
                    field: 'device_name',
                    width: 150
                },
                {
                    label: 'Views',
                    field: 'device_view',
                    width: 150
                }
            ],
            rows: this.state.device
        };

        const projects = {
            columns: [
                {
                    label: 'Image',
                    field: 'image',
                    sort: "disabled",
                    width: 150
                },
                {
                    label: 'Name',
                    field: 'name',
                    width: 150
                },
                {
                    label: 'Shop Name',
                    field: 'vendorType',
                    sort: "disabled",
                    width: 150
                },
                {
                    label: 'Shop Url',
                    field: 'shopUrl',
                    sort: "disabled",
                    width: 150
                }
            ],
            rows: this.state.products_details
        };
        return (
            <React.Fragment>
                <Col>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <p>Name: {this.state.project_name}</p>
                                    <p>Project Type: {this.state.project_type}</p>
                                    <p>Interaction Count: { this.state.interaction.map((int) => int.interaction_count)}</p>
                                    <p>Status: {(this.state.project_status).toLowerCase()}</p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Row>
                    <Col>
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody >
                                    <h6>Country Views</h6>
                                    {
                                        this.state.countryCount.length == 0 ? 
                                            <div style={{display: "grid", justifyItems: "center", marginTop: 30}}>
                                                <i className='far fa-eye-slash' style={{fontSize: 50, marginBottom: 30}}></i>
                                                <h5>Oops!!</h5>
                                                <p>No views</p>
                                            </div> :
                                        <ReactApexChart 
                                            options={ {
                                                
                                                labels: this.state.countryCount.map((abc) => abc.country_name),
                                                dataLabels: {
                                                    enabled: true
                                                },
                                                legend: {
                                                    show: true,
                                                    labels: {
                                                        colors: ["#FFFFFF"], 
                                                    }
                                                },
                                                responsive: [{
                                                    breakpoint: 480,
                                                    options: {
                                                        chart: {
                                                            width: 1,
                                                        },
                                                        legend: {
                                                            position: 'bottom'
                                                        }
                                                    }
                                                }]
                                            }} 
                                        series={this.state.countryCount.map((pqr) => pqr.country_count)} 
                                        type="donut" 
                                        height="285" 
                                        />
                                    }
                                    
                                    
                                        {/*
                                            <MDBDataTable responsive striped
                                            data={data}
                                        />
                                        */}
                                        
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <h6>Device Views</h6>
                                        {
                                            this.state.device.length == 0 ? 
                                            <div style={{display: "grid", justifyItems: "center", marginTop: 30}}>
                                                <i className='far fa-eye-slash' style={{fontSize: 50, marginBottom: 30}}></i>
                                                <h5>Oops!!</h5>
                                                <p>No views</p>
                                            </div> :
                                            <ReactApexChart 
                                            options={ {
                                               
                                                labels: this.state.device.map((abc) => abc.device_name),
                                                dataLabels: {
                                                    enabled: true
                                                },
                                                legend: {
                                                    show: true,
                                                    labels: {
                                                        colors: ["#FFFFFF"], 
                                                    },
                                                },
                                                responsive: [{
                                                    breakpoint: 480,
                                                    options: {
                                                        chart: {
                                                            width: 1,
                                                        },
                                                        legend: {
                                                            position: 'bottom'
                                                        }
                                                    }
                                                }]
                                            }} 
                                        series={this.state.device.map((pqr) => pqr.device_view)} 
                                        type="donut" 
                                        height="285" 
                                        />
                                        }
                                        
                                        {/*
                                            <MDBDataTable responsive striped
                                            data={device}
                                        />
                                        */}
                                        
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    
                </Row>

                <Row>
                    <Col xs="12">
                        <Card>
                            
                            <CardBody >
                            <h5>All Products</h5>
                                <MDBDataTable responsive striped
                                    data={projects}
                                />
                            </CardBody>
                        </Card>
                    </Col> 
                </Row>

            </React.Fragment>
        );
    }
}

export default connect(null, {setBreadcrumbItems})(VideoDeatails);