import React, {Component} from 'react';
import {connect} from "react-redux";
import {Row, 
    Col, 
    Card, 
    CardBody, 
    Button, 
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    Input,
    Alert,
    UncontrolledTooltip
} from "reactstrap";

import { MDBDataTable } from 'mdbreact';
// Import Action to copy breadcrumb items from local state to redux state
import {setBreadcrumbItems} from "../../store/actions";
import { Link } from "react-router-dom";
import Moment from "moment";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { RotatingLines } from  'react-loader-spinner'
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import url from "../../helpers/apiUrl"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class VideoReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                {
                    title: "One Dash",
                    link: "dashboard"
                }, {
                    title: "Video Analytics",
                    link: "#"
                },

            ],
            projects: [],
            modal_add: false,
            fromDate: '',
            toDate: '',
            created_at: 0,
            username: '',
            isLoading: true,
            DateAlert: false,
            isLoadingSave: false
        }
        this.viewAllProjects = this.viewAllProjects.bind(this);
        this.tog_add = this.tog_add.bind(this);
        this.tog_add2 = this.tog_add2.bind(this);
        this.searchProject = this.searchProject.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.handleDatechange = this.handleDatechange.bind(this);
        this.handleToDatechange = this.handleToDatechange.bind(this);
        this.searchChangeHandler = this.searchChangeHandler.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.disableToast = this.disableToast.bind(this);

    }

    componentDidMount() {
        this.props.setBreadcrumbItems("Video Reports", this.state.breadcrumbItems);
        if (localStorage.getItem("token") === null) {
            return (
                this.props.history.push('/login')
            );
        }
        this.viewAllProjects()
    }

    viewAllProjects(){ 
        this.setState({isLoadingSave : true})
        var date = this.state.created_at;
        var fromDate = ''
        var toDate = ''
        if (date === "custom2") {
            fromDate = Moment(this.state.fromDate).format('YYYY-MM-DD')
            toDate = Moment(this.state.toDate).format('YYYY-MM-DD')
           
        } else {
            if(date === "1"){
                fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
                toDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            }else{
            fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            toDate = Moment().format('YYYY-MM-DD')
            }
        }
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "fromDate": fromDate,
            "toDate": toDate
        });

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };
        fetch(`${url}/analytics/projects`, requestOptions)
        .then(response => response.json())
        .then(result =>{
            if(result.error === "Invalid Token"){
                localStorage.removeItem('token');
                sessionStorage.clear();
                this.props.history.push('/login') 
            }
            var array = []
            if(result.statusCode === "200"){
                this.setState({isLoadingSave: false})
                if(this.state.modal_add === true){
                    this.setState(prevState => ({
                        modal_add: false
                    }));
                }
                
                for (let i = 0; i < result.body.length; i++) {
                   array.push({
                    name: result.body[i]._source.name,
                    projectType: result.body[i]._source.projectType,
                    status: result.body[i]._source.status,
                    createdAt:  Moment(result.body[i]._source.metadata.createdAt).format('YYYY/MM/DD HH:mm:ss'),
                    updatedAt: Moment(result.body[i]._source.metadata.updatedAt).format('YYYY/MM/DD HH:mm:ss'),
                    button: <div>
                         <Link to={{
                                    pathname: "/video-details",
                                    state: {
                                        projects: result.body[i],
                                        created_at: this.state.created_at,
                                        fromDate: this.state.fromDate,
                                        toDate: this.state.toDate
                                    }
                                }}>
                                    <Button type="button"
                                        
                                        style={
                                            { marginRight: 10 }
                                        }
                                        color="primary" id="tooltip_edit_video"
                                        className="waves-effect waves-light buttons-secondary">
                                        <i className="ion ion-md-arrow-round-forward"></i>
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target="tooltip_edit_video">
                                        View 
                                    </UncontrolledTooltip>
                                </Link>
                    </div>
                   })   
                }
            }else{
                this.setState({isLoadingSave: false})
                if(this.state.modal_add === true){
                    this.setState(prevState => ({
                        modal_add: false
                    }));
                }
                toast(<div className='toast-message-container'>
                <h6>Video Reports</h6>
                <p>Internal server error.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-error'
             })
            }
            this.setState({ projects: array, isLoading: false})
           
        })
        .catch(error => console.log('error', error));
    
    }

    searchProject(){
        if (this.state.username === null || this.state.username === "" || this.state.username === undefined) {
            return this.viewAllProjects
        }
        var date = this.state.created_at;
        var fromDate = ''
        var toDate = ''
        if (date === "") {
            fromDate = ""
            toDate = ""
        } else {
            if(date === "1"){
                fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
                toDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            }else{
            fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            toDate = Moment().format('YYYY-MM-DD')
            }
        }
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "username": this.state.username,
            "fromDate": fromDate,
            "toDate": toDate
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
        this.state.isLoading = true
        fetch(`${url}/analytics/projectbyuser`, requestOptions)
        .then(response => response.json())
        .then(result => {
            var array = []
            if(result.statusCode === "200"){
                if(this.state.modal_add === true){
                    this.setState(prevState => ({
                        modal_add: false
                    }));
                }
                
                for (let i = 0; i < result.body.length; i++) {
                   array.push({
                    name: result.body[i]._source.name,
                    projectType: result.body[i]._source.projectType,
                    status: result.body[i]._source.status,
                    createdAt:  Moment(result.body[i]._source.metadata.createdAt).format('YYYY/MM/DD HH:mm:ss'),
                    updatedAt: Moment(result.body[i]._source.metadata.updatedAt).format('YYYY/MM/DD HH:mm:ss'),
                    button: <div>
                         <Link to={{
                                    pathname: "/video-details",
                                    state: {
                                        projects: result.body[i],
                                        created_at: this.state.created_at,
                                        fromDate: this.state.fromDate,
                                        toDate: this.state.toDate
                                    }
                                }}>
                                    <Button type="button"
                                        
                                        style={
                                            { marginRight: 10 }
                                        }
                                        color="primary" id="tooltip_edit_video"
                                        className="waves-effect waves-light buttons-secondary">
                                        <i className="ion ion-md-arrow-round-forward"></i>
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target="tooltip_edit_video">
                                        View 
                                    </UncontrolledTooltip>
                                </Link>
                    </div>
                   })   
                }
            }
            this.setState({ projects: array, isLoading: false})
        })
        .catch(error => console.log('error', error));
    }

    tog_add() {
        this.setState(prevState => ({
            modal_add: !prevState.modal_add,

        }));
    }
    tog_add2() {
        this.setState(prevState => ({
            modal_add: !prevState.modal_add,fromDate:Moment(new Date()).format('YYYY-MM-DD'),toDate:Moment(new Date()).format('YYYY-MM-DD'),created_at:0,DateAlert:false

        }));
        setTimeout(() => {
            this.viewAllProjects()
        }, 1500);
        
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value, username: '' })
        this.setState({ isLoading: true })
        if(e.target.value === "custom"){
            this.setState({ [e.target.name]: "custom2",fromDate:'',toDate:'' })
            this.tog_add()
        }
        else{
            setTimeout(() => {
                this.viewAllProjects()
            }, 1500);
        }
        
        
    }
    searchChangeHandler(e){
        this.setState({ [e.target.name]: e.target.value  }) 
        if(e.target.value === ""){
            this.viewAllProjects()
        }
    }

    handleDatechange(value){
        var dateofvisit = Moment(value, 'DD-MM-YYYY');
        var today = Moment();
        if(today.diff(dateofvisit, 'Hours') <= 0){
            this.setState({
                DateAlert: true,fromDate: Moment(value).format("YYYY-MM-DD")
            })
            
            return  
        }else{
            this.setState({
                DateAlert: false
            }) 
            this.setState({fromDate: Moment(value).format("YYYY-MM-DD")})
        }
    }
    handleToDatechange(value){
        var dateofvisit = Moment(value, 'DD-MM-YYYY');
        var today = Moment();
        
        if(today.diff(dateofvisit, 'Hours') <= 0){
            this.setState({
                DateAlert: true,toDate: Moment(value).format("YYYY-MM-DD")
            })
            
            return  
        }else if(this.state.fromDate !== ""){
            if(Moment(this.state.fromDate, 'YYYY-MM-DD').diff(dateofvisit, 'Hours') > 0){
                this.setState({
                    DateAlert: true,toDate: Moment(value).format("YYYY-MM-DD")
                })
            }else{
                this.setState({
                    DateAlert: false
                })
                this.setState({toDate: Moment(value).format("YYYY-MM-DD")})
            }
        }else{
            this.setState({
                DateAlert: false
            })
            this.setState({toDate: Moment(value).format("YYYY-MM-DD")})

        }
    }

    keyPress(e){
        if(e.keyCode == 13){
            e.preventDefault();
             this.searchProject();
        }
     }
     disableToast(){
        let toastDiv = document.querySelector('.toast-error');
        if(toastDiv == null){
            toast(<div className='toast-message-container'>
            <h6>Video Reports</h6>
            <p>Please select a valid date.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
         })
        }
    }
  

    render() {
        const data = {
            columns: [
                {
                    label: 'Name',
                    field: 'name',
                    width: 150
                },
                {
                    label: 'Project Type',
                    field: 'projectType',
                    width: 270
                },
                {
                    label: 'Status',
                    field: 'status',
                    width: 200
                },
                {
                    label: 'Created At',
                    field: 'createdAt',
                    width: 150
                }, {
                    label: 'Updated At',
                    field: 'updatedAt',
                    width: 100
                }, {
                    label: 'Action',
                    field: 'button',
                    sort: "disabled",
                    width: 100
                }
            ],
            rows: this.state.projects
        };
        return (
            <React.Fragment>
                <Row style={{marginBottom: 20}}>
                    <Col xs="8">
                        <Col lg="6">
                        <Form className="app-search d-none d-lg-block">
                            <div className="position-relative">
                                <Row>
                                    <Col xs="10">
                                        <span className="fa fa-search"></span>
                                        <Input onChange={this.searchChangeHandler} onKeyDown={this.keyPress} type="text" value={this.state.username} name="username" className="form-control" placeholder="Search by username..." />  
                                    </Col>
                                    <Col xs="2">
                                        {
                                            this.state.username === "" ?
                                            <Button color="primary" disabled onClick={this.searchProject}>Search</Button>
                                            : <Button color="primary" onClick={this.searchProject}>Search</Button>
                                        }
                                        
                                    </Col>
                                </Row>   
                            </div>
                        </Form>
                        </Col>
                    </Col>
                    <Col xs="4">
                        <Col>
                            <select className="form-control" name='created_at'
                                value={
                                    this.state.created_at
                                }
                                onChange={
                                    this.changeHandler
                                }>
                                <option value="0">Today</option>
                                <option value="1">Yesterday</option>
                                <option value="7">Last 7 days</option>
                                <option value="30">Last 30 days</option>
                                <option value="180">Last 6 months</option>
                                <option value="custom" onClick={this.tog_add}>Custom</option>
                                <option value='custom2' hidden>{ this.state.created_at === "custom2" ? "From: " + this.state.fromDate + " To: " + this.state.toDate : "Custom"}</option>
                            </select>
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                {
                                    this.state.isLoading === true ? 
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <RotatingLines
                                                strokeColor="grey"
                                                strokeWidth="5"
                                                animationDuration="0.75"
                                                width="96"
                                                visible={this.state.isLoading}
                                            />
                                        </div>
                                    : <MDBDataTable responsive bordered striped
                                    data={data}
                                />
                                }
                                
                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Modal isOpen={
                        this.state.modal_add
                    }
                        toggle={
                            this.tog_add
                        }
                        autoFocus={true}
                        size="lg">
                        <ModalHeader toggle={
                            this.tog_add2
                        }>
                            Select Date
                        </ModalHeader>
                        <ModalBody>
                        {this.state.DateAlert ?  
                        this.disableToast()
                        : Moment(this.state.fromDate).isAfter(Moment(this.state.toDate)) || Moment(this.state.fromDate).isAfter(Moment()) || Moment(this.state.toDate).isAfter(Moment())?
                        this.disableToast()
                          : null}
                            <Row>
                                <Col>
                                    <Calendar 
                                        onChange={this.handleDatechange} 
                                    />
                                </Col>
                                <Col>
                                    <Calendar 
                                        onChange={this.handleToDatechange} 
                                    />
                                </Col>
                            </Row>                                                   
                        </ModalBody>
                        <ModalFooter>
                        {
                                 this.state.DateAlert === true || this.state.fromDate === '' || this.state.toDate === ''? 
                                 <Button type="button" color="primary" className="waves-effect" disabled
                                    onClick={
                                        this.viewAllProjects
                                    }>Save
                                </Button> 
                                : 
                                Moment(this.state.fromDate).isAfter(Moment(this.state.toDate))  || Moment(this.state.fromDate).isAfter(Moment()) || Moment(this.state.toDate).isAfter(Moment())?
                                <Button type="button" color="primary" className="waves-effect" disabled
                                    onClick={
                                        this.viewAllProjects
                                    }>Save
                                </Button> :
                                <Button type="button" color="primary" className="waves-effect"
                                    onClick={
                                        this.viewAllProjects
                                    }>{
                                        this.state.isLoadingSave === true ? 
                                            <div style={{display: "flex", justifyContent: "center"}}>
                                                <RotatingLines
                                                    strokeColor="white"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="10"
                                                    visible={this.state.isLoadingSave}
                                                />
                                            </div>
                                        : "Save"
                                    }
                                </Button>
                            }
                            
                        </ModalFooter>
                    </Modal>
                </Row>
                <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />
            </React.Fragment>
        );
    }
}

export default connect(null, {setBreadcrumbItems})(VideoReport);