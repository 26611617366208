
import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    Col,
    Row,
    Card,
    CardBody,
    FormGroup,
    Label,
    Button,
    Alert,
    Form
} from "reactstrap";

import { setBreadcrumbItems } from "../../store/actions";

import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/tooltip";
import "bootstrap/dist/css/bootstrap.css";


class EditorText extends Component {
    constructor(props) {
        super(props);
   
        this.state = {
            breadcrumbItems: [
                {
                    title: "One Dash",
                    link: "#"
                }, {
                    title: "Template",
                    link: "#"
                },
            ], 
        };
    }
    

    componentDidMount() {
        this.props.setBreadcrumbItems("Update Template", this.state.breadcrumbItems);
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
       
    render() {
      
        return (
            <React.Fragment>
              <ReactSummernote
        value="Default value"
        options={{
          lang: 'ru-RU',
          height: 350,
          dialogsInBody: true,
          toolbar: [
            ['style', ['style']],
            ['font', ['bold', 'underline', 'clear']],
            ['fontname', ['fontname']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['table', ['table']],
            ['insert', ['link', 'picture', 'video']],
            ['view', ['fullscreen', 'codeview']]
          ]
        }}
        onChange={this.onChange}
      />
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(EditorText);