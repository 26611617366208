import React, { Component } from 'react';
import {
    Col,
    Row,
    Card,
    CardBody,
    FormGroup,
    Label,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Input,
    UncontrolledTooltip
} from "reactstrap";
import { connect } from "react-redux";
import { MDBDataTable } from 'mdbreact';
import { AvForm, AvField } from "availity-reactstrap-validation";
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
import "./rolemanagement.css"
import PhoneInput, { isValidPhoneNumber, isPossiblePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
// Import datatable css
import "../Tables/datatables.scss";
import { RotatingLines } from  'react-loader-spinner'


import SweetAlert from "react-bootstrap-sweetalert";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
// Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import {
//     isPossiblePhoneNumber,
//     isValidPhoneNumber,
//     validatePhoneNumberLength
//   } from 'libphonenumber-js'
//url
import url from "../../helpers/apiUrl"

import Moment from "moment"

// import axios from 'axios';
import ApiServices from '../../helpers/ApiServices';

class Rolesmanagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                {
                    title: "One Dash",
                    link: "dashboard"
                }, {
                    title: "Portal Users",
                    link: "#"
                },
            ],
            modal_edit: false,
            modal_add: false,
            modal_add_cal: false,
            hidden: true,
            password: '',
            items: [],
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            role: '',
            passwords: '',
            status: '',
            id: '',
            success_confirm: false,
            alert_confirm: false,
            alert_phone: false,
            dynamic_title: "",
            dynamic_description: "",
            email_filter: '',
            status_filter: '',
            role_filter: '',
            to_date_filter: '',
            from_date_filter: '',
            temp: false,
            temp2: false,
            alert_verification: false,
            alert_verification_phone: false,
            created_at_filter: '',
            prev_status: '',
            prev_role: '',
            prev_email: '',
            prev_first_name: '',
            prev_last_name: '',
            prev_password: '',
            prev_phone: '',
            fromDate: '',
            toDate: '',
            createRole: false,
            updateRole: false,
            emailValidation: false,
            DateAlert:false,
            isLoadingSave:false,
        }
        this.tog_edit = this.tog_edit.bind(this);
        this.tog_add = this.tog_add.bind(this);
        this.tog_add_cal = this.tog_add_cal.bind(this);
        this.tog_add_cal2 = this.tog_add_cal2.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.toggleShow = this.toggleShow.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.fillUser = this.fillUser.bind(this);
        this.editUser = this.editUser.bind(this);
        // this.isValidNumber = this.isValidNumber.bind(this);
        this.handleDatechange = this.handleDatechange.bind(this);
        this.handleToDatechange = this.handleToDatechange.bind(this);
        this.numberValidate = this.numberValidate.bind(this);
        this.disableToast = this.disableToast.bind(this);

    }

    async getUserDetail(){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        const response = await fetch(`${url}/profile/userdetail`, requestOptions);
        const result = await response.json();
        return result.body.email;
    }

    componentDidMount() {
        if (localStorage.getItem("token") === null) {
            return (
                this.props.history.push('/login')
                // <Redirect to={{ pathname: "/login" }} exact />
            );
        }
        this.props.setBreadcrumbItems("Portal Users", this.state.breadcrumbItems);
        if (this.props.password) {
            this.setState({ password: this.props.password });
        }

        
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var raw = JSON.stringify({
           
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/adminusers`, requestOptions).then((response) => response.json()).then(async (data) => {
            if(data.error === "Invalid Token"){
                localStorage.removeItem('token');
                sessionStorage.clear();
                this.props.history.push('/login') 
            }
            var array = []
            if (data.statusCode === "200") {

                const userEmail = await this.getUserDetail();
    
                for (let i = 0; i < data.body.length; i++) {
                    array.push({
                        id: data.body[i].id,
                        first_name: data.body[i].first_name,
                        last_name: data.body[i].last_name,
                        email: data.body[i].email,
                        password: data.body[i].password,
                        phone: data.body[i].phone,
                        role: data.body[i].role === 1 ? 'Admin' : data.body[i].role === 2 ? 'Support Manager' : data.body[i].role === 4 ? 'Support Engineer' : 'Marketing',
                        status: data.body[i].status,
                        //DD-MM-YYYY HH:mm:ss
                        created_at:  Moment(data.body[i].created_at).format('YYYY/MM/DD HH:mm:ss'),
                        updated_at: Moment(data.body[i].updated_at).format('YYYY/MM/DD HH:mm:ss'),
                        button: <div style={{display: "flex", justifyContent: "center"}}>
                            <Button type="button"
                                onClick={
                                    () => this.fillUser(data.body[i])
                                }
                                style={
                                    { marginRight: 10 }
                                }
                                color="primary"
                                id="tooltip_edit_role"
                                className="waves-effect waves-light buttons-secondary">
                                <i className="ti-pencil"></i>
                            </Button>
                            <UncontrolledTooltip placement="bottom" target="tooltip_edit_role">
                                Edit 
                            </UncontrolledTooltip>
                            <Button type="button" color="danger" disabled={userEmail == data.body[i].email}
                                onClick={
                                    () => this.setState({ first_name: data.body[i].first_name, last_name: data.body[i].last_name, alert_confirm: true, id: data.body[i].id })
                                }
                                className="waves-effect waves-light buttons-secondary"
                                id="tooltip_delete_role"><i className="ti-trash"></i>
                            </Button>
                            <UncontrolledTooltip placement="bottom" target="tooltip_delete_role">
                                Delete
                            </UncontrolledTooltip>
                        </div>

                    })
                }
                this.setState({ items: array })
            }
            else {
                this.setState({
                    items: []
                })
            }

        })

    }

    handleValidate (value){
        const isValid = isValidPhoneNumber(value);
        console.log({ isValid: true})
        return isValid
    }

    getUsers() {
        this.setState({isLoadingSave : true})
        var date = this.state.created_at_filter;
        var fromDate = ''
        var toDate = ''
        if (date === "custom2") {
            fromDate = Moment(this.state.fromDate).format('YYYY-MM-DD')
            toDate = Moment(this.state.toDate).format('YYYY-MM-DD')
        } else if (date !== "") {
            if(date === "1"){
                fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
                toDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            }else{
            fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            toDate = Moment().format('YYYY-MM-DD')
            }
        }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        var raw = JSON.stringify({
            "email": this.state.email_filter,
            "status": this.state.status_filter,
            "role": this.state.role_filter,
            "from_date": fromDate,
            "to_date": toDate
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/adminusers`, requestOptions)
            .then((response) => response.json())
            .then(async (data) => {
                if(data.error === "Invalid Token"){
                    localStorage.removeItem('token');
                    sessionStorage.clear();
                    this.props.history.push('/login') 
                }
                
                var array = []
               
                if (data.statusCode === "200") {
                    
                    this.setState({isLoadingSave : false})

                    if(this.state.modal_add_cal === true){
                        this.setState(prevState => ({
                            modal_add_cal: false
                        }));
                    }

                    const userEmail = await this.getUserDetail();

                    for (let i = 0; i < data.body.length; i++) {
                        array.push({
                            id: data.body[i].id,
                            first_name: data.body[i].first_name,
                            last_name: data.body[i].last_name,
                            email: data.body[i].email,
                            password: data.body[i].password,
                            phone: data.body[i].phone,
                            role: data.body[i].role === 1 ? 'Admin' : data.body[i].role === 2 ? 'Support Manager' : data.body[i].role === 4 ? 'Support Engineer' : 'Marketing',
                            status: data.body[i].status,
                            created_at:  Moment(data.body[i].created_at).format('YYYY/MM/DD HH:mm:ss'),
                            updated_at: Moment(data.body[i].updated_at).format('YYYY/MM/DD HH:mm:ss'),
                            button: <div style={{display: "flex", justifyContent: "center"}}>
                                <Button type="button"
                                    onClick={
                                        () => this.fillUser(data.body[i])
                                    }
                                    style={
                                        { marginRight: 10 }
                                    }
                                    color="primary"
                                    className="waves-effect waves-light buttons-secondary">
                                    <i className="ti-pencil"></i>
                                </Button>
                                <Button type="button" color="danger" disabled={userEmail == data.body[i].email}
                                    onClick={
                                        () => this.setState({ first_name: data.body[i].first_name, last_name: data.body[i].last_name, alert_confirm: true, id: data.body[i].id })
                                    }
                                    className="waves-effect waves-light buttons-secondary"
                                    id="sa-warning"><i className="ti-trash"></i>
                                </Button>

                            </div>

                        })

                    }
                    this.setState({ items: array })
                }
                else {
                    this.setState({isLoadingSave : false})
                    if(this.state.modal_add_cal === true){
                        this.setState(prevState => ({
                            modal_add_cal: false
                        }));
                    }
                    this.setState({
                        items: []
                    })
                }
            })
        
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        if(e.target.value === "custom"){
            this.setState({ [e.target.name]: "custom2",fromDate:'',toDate:'' })
            this.tog_add_cal()
        }
        else{
            setTimeout(() => {
                this.getUsers()
            }, 1500);
        }
    }

    handleDelete() {
        ApiServices.deleteUsers(this.state.id).then(res => res.json()).then(res => {
            console.log(res)
            if(res.statusCode==="200"){
            this.getUsers()
            this.setState({
                items: this.state.items.filter(item => item.id !== this.state.id),
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                role: '',
                passwords: ''
            })
            toast(<div className='toast-message-container'>
                <h6>Portal Users</h6>
                <p>User deleted successfully.</p>
                </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-success'
                });
            }else{
                toast(<div className='toast-message-container'>
                <h6>Portal Users</h6>
                <p>{res.body}</p>
                </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
                });
            }
        })
        
    }

    fillUser = (user) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "userid": user.id
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/adminusers/userdetails`, requestOptions)
            .then(response => response.json())
            .then(result => {
                this.setState({
                    id: result.body.id,
                    first_name: result.body.first_name,
                    last_name: result.body.last_name,
                    password: result.body.password,
                    email: result.body.email,
                    phone: result.body.phone,
                    role: result.body.role,
                    status: result.body.status,
                    created_at: result.body.created_at,
                    updated_at: result.body.updated_at,
                    prev_email: result.body.email,
                    prev_first_name: result.body.first_name,
                    prev_last_name: result.body.last_name,
                    prev_password: result.body.password,
                    prev_phone: result.body.phone,
                    prev_role: result.body.role,
                    prev_status: result.body.status

                })
                this.tog_edit();

            })
            .catch(error => console.log('error', error));

    }

    editUser(id) {
        
        if(this.state.phone.length >= 21){
            this.setState({
                alert_verification_phone: true,
            }) 
            setTimeout(() => {
                this.setState({
                    alert_verification_phone: false,
                })
            }, 3000) 
            return
        }
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "id": id,
            "first_name": this.state.first_name,
            "last_name": this.state.last_name,
            "email": this.state.email,
            "phone": this.state.phone,
            "password": this.state.password,
            "role": this.state.role,
            "status": this.state.status
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/adminusers/update`, requestOptions).then(response => response.json()).then((data) => {
            console.log(data)
            if (data.statusCode === "200") {
                this.getUsers()
                this.tog_edit()
                this.setState({
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    role: '',
                    passwords: '',
                    status: '',
                    //updateRole: true
                })
                toast(<div className='toast-message-container'>
                <h6>Portal Users</h6>
                <p>User updated successfully.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-success'
             })
                // setTimeout(() => {
                //     this.setState({
                //         updateRole: false
                //     })
                // }, 3000)
            } else {
                if (data.body === "Duplicate entry '" + this.state.email + "' for key 'users.email'") {
                    // this.setState({
                    //     temp: true
                    // })
                    // setTimeout(() => {
                    //     this.setState({
                    //         temp: false
                    //     })
                    // }, 3000);
                    toast(<div className='toast-message-container'>
                    <h6>Portal Users</h6>
                    <p>Email already exists.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-error'
                 })
                } else if (data.body === "Duplicate entry '" + this.state.phone + "' for key 'users.phone'") {
                    // this.setState({
                    //     temp2: true
                    // })
                    // setTimeout(() => {
                    //     this.setState({
                    //         temp2: false
                    //     })
                    // }, 3000);
                    toast(<div className='toast-message-container'>
                    <h6>Portal Users</h6>
                    <p>Mobile number already exists.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-error'
                 })
                }
                // alert(data.body)
            }

            if(data.body === "Please provide a valid mobile number"){
                // this.setState({
                //     alert_phone: true
                // })
                // setTimeout(() => {
                //     this.setState({
                //         alert_phone: false
                //     })
                // }, 4000);
                toast(<div className='toast-message-container'>
                <h6>Portal Users</h6>
                <p>Please enter a valid mobile number.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-error'
             })
            }
        }).catch(error => console.log('error', error));
    }

    createUser() {
        
        if(this.state.phone.length >= 21){
            // this.setState({
            //     alert_verification_phone: true,
            // }) 
            // setTimeout(() => {
            //     this.setState({
            //         alert_verification_phone: false,
            //     })
            // }, 3000) 
            toast(<div className='toast-message-container'>
            <h6>Portal Users</h6>
            <p>Please add a valid contact number.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
         })
            return
        }
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "first_name": this.state.first_name,
            "last_name": this.state.last_name,
            "email": this.state.email,
            "password": this.state.passwords,
            "phone": this.state.phone,
            "role": this.state.role
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/adminusers/register`, requestOptions).then((response) => response.json()).then(data => {
            
            if (data.statusCode === "200") {
                console.log(data)
                this.tog_add()
                this.setState({
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    role: '',
                    passwords: ''
                })
                
                // this.setState({
                //     alert_verification: true,
                //     createRole: true
                // })
                // setTimeout(() => {
                //     console.log("Hi there")
                //     this.getUsers()
                //     this.setState({
                //         alert_verification: false,
                //         createRole: false
                //     })
                // }, 4000);
                toast(<div className='toast-message-container'>
                <h6>Portal Users</h6>
                <p>User created successfully and a verification mail has been sent.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-success'
             })
               setTimeout(() => {
                this.getUsers()
               }, 1500)
                
            } else {
                if (data.body === "Duplicate entry '" + this.state.email + "' for key 'users.email'") {
                    // this.setState({
                    //     temp: true
                    // })
                    // setTimeout(() => {
                    //     this.setState({
                    //         temp: false
                    //     })
                    // }, 3000);
                    toast(<div className='toast-message-container'>
                    <h6>Portal Users</h6>
                    <p>Email already exists.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-error'
                 })
                } else if (data.body === "Duplicate entry '" + this.state.phone + "' for key 'users.phone'") {
                    // this.setState({
                    //     temp2: true
                    // })
                    // setTimeout(() => {
                    //     this.setState({
                    //         temp2: false
                    //     })
                    // }, 3000);
                    toast(<div className='toast-message-container'>
                    <h6>Portal Users</h6>
                    <p>Mobile number already exists.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-error'
                 })
                }
                // alert(data.body)
            }
        }).catch(error => console.log('error', error));
    }

    handlePasswordChange(e) {
        this.setState({ password: e.target.value });
    }

    toggleShow() {
        this.setState({
            hidden: !this.state.hidden
        });
    }

    tog_edit() {
        if (this.state.modal_edit === true) {
            this.setState({
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                role: '',
                passwords: ''
            })
        }
        this.setState(prevState => ({
            modal_edit: !prevState.modal_edit
        }));

    }
    tog_add() {
        if (this.state.modal_add === true) {
            this.setState({
                id: '',
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                phone: '',
                role: ''
            })
        }
        this.setState(prevState => ({
            modal_add: !prevState.modal_add

        }));
    }

    clearFilter() {
        this.setState({
            email_filter: '',
            status_filter: '',
            role_filter: '',
            from_date_filter: '',
            to_date_filter: '',
            created_at_filter: ''
        })
        setTimeout(() => {
            this.getUsers()
        }, 100)

    }

    handleDatechange(value){
        var dateofvisit = Moment(value, 'DD-MM-YYYY');
        var today = Moment();
        if(today.diff(dateofvisit, 'Hours') <= 0){
            this.setState({
                DateAlert: true,fromDate: Moment(value).format("YYYY-MM-DD")
            })
            
            return  
        }else{
            this.setState({
                DateAlert: false
            }) 
            this.setState({fromDate: Moment(value).format("YYYY-MM-DD")})
        }
    }
    handleToDatechange(value){
        var dateofvisit = Moment(value, 'DD-MM-YYYY');
        var today = Moment();
        
        if(today.diff(dateofvisit, 'Hours') <= 0){
            this.setState({
                DateAlert: true,toDate: Moment(value).format("YYYY-MM-DD")
            })
            
            return  
        }else if(this.state.fromDate !== ""){
            if(Moment(this.state.fromDate, 'YYYY-MM-DD').diff(dateofvisit, 'Hours') > 0){
                this.setState({
                    DateAlert: true,toDate: Moment(value).format("YYYY-MM-DD")
                })
            }else{
                this.setState({
                    DateAlert: false
                })
                this.setState({toDate: Moment(value).format("YYYY-MM-DD")})
            }
        }else{
            this.setState({
                DateAlert: false
            })
            this.setState({toDate: Moment(value).format("YYYY-MM-DD")})

        }
    }

    tog_add_cal() {
        this.setState(prevState => ({
            modal_add_cal: !prevState.modal_add_cal

        }));
    }
    tog_add_cal2() {
        this.setState(prevState => ({
            modal_add_cal: !prevState.modal_add_cal,fromDate:'',toDate:'',created_at_filter:"",DateAlert:false

        }));
        setTimeout(() => {
            this.getUsers()
        }, 1500);
    }
    numberValidate(value){
        const isValid = isValidPhoneNumber(value);
        console.log({ isValid })
        return isValid
        
    }

    disableToast(){
        let toastDiv = document.querySelector('.toast-error');
        if(toastDiv == null){
            toast(<div className='toast-message-container'>
            <h6>Portal Users</h6>
            <p>Please select a valid date.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
         })
        }
    }

    render() {
        const { items } = this.state

        const data = {
            columns: [
                {
                    label: 'ID',
                    field: 'id',

                    width: 150
                },
                {
                    label: 'First Name',
                    field: 'first_name',

                    width: 270
                },
                {
                    label: 'Last Name',
                    field: 'last_name',

                    width: 200
                },
                {
                    label: 'Email',
                    field: 'email',

                    width: 10
                }, {
                    label: 'Phone No.',
                    field: 'phone',
                    sort: "disabled",
                    width: 150
                }, {
                    label: 'Roles',
                    field: 'role',

                    width: 100
                }, {
                    label: 'Status',
                    field: 'status',

                    width: 100
                }, {
                    label: 'Created At',
                    field: 'created_at',
                    sort: 'desc',
                    width: 100
                }, {
                    label: 'Updated At',
                    field: 'updated_at',

                    width: 100
                }, {
                    label: 'Action',
                    field: 'button',
                    sort: "disabled",
                    width: 100
                }
            ],
            rows: this.state.items
        };
        return (
            <React.Fragment>

                {this.state.alert_confirm ? (
                    
                    <div className="modal" style={{display:"block"}} tabIndex="-1" role="dialog" id='alertBox'>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Delete User</h5>
                                
                            </div>
                            <div className="modal-body">
                                <p>{ "Are you sure you want to delete" + " " + this.state.first_name +  " " + this.state.last_name + "?"}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" style={{background:"#FFFFFF0D", borderColor:"#2f2f34"}} className="btn" data-dismiss="modal"
                                onClick={() => {
                                    this.setState({
                                        alert_confirm: false,
                                    });
                                    document.getElementById('alertBox').style.display = 'none';
                                }} 
                                >Cancel</button>
                                <button type="button" className="btn"
                                onClick={() => {
                                    this.setState({ alert_confirm: false })
                                    this.handleDelete();
                                    document.getElementById('alertBox').style.display = 'none';
                                }}
                                >Delete</button>
                            </div>
                            </div>
                        </div>
                    </div>

                ) : null}
                

                <div style={
                    {
                        marginTop: 20,
                        marginBottom: 30
                    }
                }>
                    <Button type="button"
                        style={{background: "#17A0FB", padding: "7px 14px", borderColor: "#17A0FB", fontWeight: 600}}
                        onClick={
                            this.tog_add
                        }
                        color="info"
                        className="waves-effect waves-light">
                        <i className="ion ion-md-person-add"
                            style={
                                { marginRight: 10 }
                            }></i>Add User
                    </Button>
                </div>


                {/* Filters */}
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody >
                                <Row xs="12">
                                    <Col>
                                        <Label for="example-password-input" className=" col-form-label">Role</Label>
                                        <Col>
                                            <select className="form-control" name='role_filter'
                                                value={
                                                    this.state.role_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                <option value="1">Admin</option>
                                                <option value="2">Support Manager</option>
                                                <option value="3">Marketing</option>
                                                <option value="4">Support Engineer</option>
                                            </select>
                                        </Col>
                                    </Col>

                                    <Col>
                                        <Label for="example-password-input" className="col-form-label">Status</Label>
                                        <Col>
                                            <select className="form-control" name='status_filter'
                                                value={
                                                    this.state.status_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                <option value="Active">Active</option>
                                                <option value="Inactive">Inactive</option>
                                            </select>
                                        </Col>
                                    </Col>
                                    <Col>
                                        <Label for="example-password-input" className=" col-form-label">Created At</Label>
                                        <Col>
                                            <select className="form-control" name='created_at_filter'
                                                value={
                                                    this.state.created_at_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                <option value="0">Today</option>
                                                <option value="1">Yesterday</option>
                                                <option value="7">Last 7 days</option>
                                                <option value="30">Last 30 days</option>
                                                <option value="180">Last 6 months</option>
                                                <option value="custom" onClick={this.tog_add_cal}>Custom</option>
                                                <option value="custom2" hidden>{ this.state.created_at_filter === "custom2" ? "From: " + this.state.fromDate + " To: " + this.state.toDate : "Custom"}</option>
                                            </select>
                                        </Col>
                                    </Col>
                                    
                                </Row>
                                <Col className="text-left" style={{ marginTop: 10 }}>
                                    <Button type="button"
                                        style={{background: "#17A0FB", padding: "7px 14px", borderColor: "#17A0FB", fontWeight: 600}}
                                        onClick={
                                            this.getUsers
                                        }
                                        color="warning"
                                        className="waves-effect waves-light">
                                        <i className='mdi mdi-filter-menu-outline'
                                            style={
                                                { marginRight: 10, fontSize: 15 }
                                            }
                                        ></i>
                                        Filter
                                    </Button>

                                    {
                                        this.state.role_filter === "" && this.state.created_at_filter === '' && this.state.status_filter === '' ?
                                        <Button type="button"
                                        disabled
                                        style={{ marginLeft: 10 ,border: "1px solid #6a6a71", borderRadius: "3px" ,backgroundColor: "rgba(80,80,88,.8)", transition: "background-color .2s linear", fontWeight: 600}}
                                        onClick={
                                            () => this.clearFilter()
                                        }
                                        color="success"
                                        className="waves-effect waves-light">

                                        Clear Filter
                                    </Button> :
                                    <Button type="button"
                                    style={{ marginLeft: 10 ,border: "1px solid #E6536C", borderRadius: "3px" ,backgroundColor: "#E6536C", transition: "background-color .2s linear", padding:"7px 14px", fontWeight: 600}}
                                    onClick={
                                        () => this.clearFilter()
                                    }
                                    color="success"
                                    className="waves-effect waves-light">

                                    Clear Filter
                                </Button>
                                    }
                                    
                                </Col>
                                {/*
                                
                                */}

                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {this.state.createRole ? <Alert color="success">
                    <strong>Portal user created successfully.</strong>
                </Alert> : null}
                {this.state.updateRole ? <Alert color="success">
                    <strong>Portal user updated successfully.</strong>
                </Alert> : null}
                <Row>
                    <Col xs="12">
                        {this.state.alert_verification ? <Alert color="success">
                                <strong>User verification mail has been sent.</strong>
                        </Alert> : null}
                        <Card>
                            <CardBody>
                                <MDBDataTable responsive bordered striped
                                    data={data}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Modal isOpen={
                        this.state.modal_edit
                    }
                        toggle={
                            this.tog_edit
                        }
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">
                        <ModalHeader className="form-modal-header">
                            Edit Details
                        </ModalHeader>
                        <ModalBody className="form-modal-body">
                            
                            {this.state.alert_verification_phone ? <Alert color="danger">
                                <strong>Please provide a valid mobile number</strong>
                            </Alert> : null}
                            <AvForm className="formContainer">
                                <AvField
                                    name="first_name"
                                    label="First Name"
                                    placeholder="Enter First Name"
                                    type="text"
                                    errorMessage="Enter Name"
                                    validate={{ required: { value: true } }}
                                    value={
                                        this.state.first_name
                                    }
                                    onChange
                                    ={this.changeHandler}
                                />
                                <AvField
                                    name="last_name"
                                    label="Last Name"
                                    placeholder="Enter Last Name"
                                    type="text"
                                    errorMessage="Enter Name"
                                    validate={{ required: { value: true } }}
                                    value={
                                        this.state.last_name
                                    }
                                    onChange
                                    ={this.changeHandler}
                                />
                                <AvField
                                    name="email"
                                    label="E-Mail  "
                                    placeholder="Enter Valid Email"
                                    type="email"
                                    disabled
                                    errorMessage="Invalid Email"
                                    validate={{
                                        required: { value: true },
                                        email: { value: true }
                                    }}
                                    value={
                                        this.state.email
                                    }
                                    onChange
                                    ={this.changeHandler}
                                />
                                <AvField
                                    name="password"
                                    type="password"
                                    label="Password"
                                    placeholder="Enter Password"
                                    errorMessage="Enter password"
                                    validate={{
                                        required: { value: true },
                                        pattern: {
                                            value: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.!@#\$%\^&\*])",
                                            errorMessage: "Please use 1 alphabate 1 number and 1 special character."
                                        },
                                        maxLength: { value: 14, errorMessage: "Max 14 characters required." },
                                        minLength: { value: 6, errorMessage: "Min 6 characters required." }
                                    }}
                                    value={
                                        this.state.password
                                    }
                                    onChange
                                    ={this.changeHandler}
                                />
                                <Label for="example-password-input" className="col-form-label">Phone Number *</Label>
                                <PhoneInput 
                                    
                                    style={{ backgroundColor: '#474548' }}
                                    max = {20}
                                    country={'ae'}
                                    isValid ={true}
                                    value={this.state.phone}
                                    onChange={phone => this.setState({ phone })}
                                    rules={{
                                        validate: (value) => this.handleValidate(value)
                                 }}
                                
                                />
                            </AvForm>
                            <Label for="example-password-input" className="col-form-label">Role</Label>
                            <FormGroup row>

                                <Col sm="12">
                                    <select className="selectFormContainer" name='role'
                                        value={
                                            this.state.role
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        <option value="1">Admin</option>
                                        <option value="2">Support Manager</option>
                                        <option value="3">Marketing</option>
                                        <option value="4">Support Engineer</option>
                                    </select>
                                </Col>
                            </FormGroup>
                            <Label for="example-password-input" className=" col-form-label">Status</Label>
                            <FormGroup row>

                                <Col sm="12">
                                    <select className="selectFormContainer" name='status'
                                        value={
                                            this.state.status
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </Col>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter className="form-modal-header">
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons"
                                onClick={
                                    this.tog_edit
                                }>Close
                            </Button>
                                {
                                    this.state.status === this.state.prev_status && this.state.role === this.state.prev_role && this.state.first_name === this.state.prev_first_name && this.state.last_name === this.state.prev_last_name && this.state.email === this.state.prev_email && this.state.password === this.state.prev_password && this.state.phone === this.state.prev_phone ?
                                    <Button type="button" color="primary" disabled
                                onClick={
                                    () => this.editUser(this.state.id)
                                }
                                style ={{cursor: "default"}}
                                className="waves-effect waves-light">Save changes
                            </Button>:
                            <Button type="button" color="primary"
                            onClick={
                                () => this.editUser(this.state.id)
                            }
                            className="waves-effect waves-light">Save changes
                        </Button>
                                }
                            
                        </ModalFooter>
                    </Modal>
                </Row>

                <Row>
                    <Modal isOpen={
                        this.state.modal_add
                    }
                        toggle={
                            this.tog_add
                        }
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">
                        <ModalHeader className="form-modal-header">
                            Add Details
                        </ModalHeader>
                        <ModalBody className="form-modal-body">
                        {this.state.alert_verification_phone ? <Alert color="danger">
                                <strong>Please provide a valid mobile number</strong>
                            </Alert> : null}
                            {this.state.temp ? <Alert color="danger">
                                <strong>Duplicate entry for email. Try new email.</strong>
                            </Alert> : null}
                            {this.state.temp2 ? <Alert color="danger">
                                <strong>Duplicate entry for phone. Try new phone number.</strong>
                            </Alert> : null}
                            {this.state.emailValidation ? <Alert color="danger">
                                <strong>Please provide valid email.</strong>
                            </Alert> : null}
                            <AvForm className="formContainer">
                                <AvField
                                    name="first_name"
                                    label="First Name *"
                                    placeholder="Enter First Name"
                                    type="text"
                                    errorMessage="Enter Name"
                                    validate={{ required: { value: true } }}
                                    value={
                                        this.state.first_name
                                    }
                                    onChange
                                    ={this.changeHandler}
                                />
                                <AvField
                                    name="last_name"
                                    label="Last Name *"
                                    placeholder="Enter Last Name"
                                    type="text"
                                    errorMessage="Enter Name"
                                    validate={{ required: { value: true } }}
                                    value={
                                        this.state.last_name
                                    }
                                    onChange
                                    ={this.changeHandler}
                                />
                                <AvField
                                    name="email"
                                    label="E-Mail  *"
                                    placeholder="Enter Valid Email"
                                    type="email"
                                    errorMessage="Invalid Email"
                                    validate={{
                                        required: { value: true },
                                        email: { value: true }
                                    }}
                                    value={
                                        this.state.email
                                    }
                                    onChange
                                    ={this.changeHandler}
                                />
                                <AvField
                                    name="passwords"
                                    type="text"
                                    label="Password *"
                                    placeholder="Enter Password"
                                    errorMessage="Enter password"
                                    validate={{
                                        required: { value: true },
                                        pattern: {
                                            value: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.!@#\$%\^&\*])",
                                            errorMessage: "Please use 1 alphabate 1 number and 1 special character."
                                        },
                                        maxLength: { value: 14, errorMessage: "Max 14 characters required." },
                                        minLength: { value: 6, errorMessage: "Min 6 characters required." }
                                    }}
                                    value={
                                        this.state.password
                                    }
                                    onChange
                                    ={this.changeHandler}
                                />
                                <Label for="example-password-input" className="col-form-label">Phone Number *</Label>
                                
                                <PhoneInput 
                                    max = {20}
                                    style={{ backgroundColor: '#474548' }}
                                    country={'ae'}
                                    isValid ={true}
                                    value={this.state.phone}
                                    onChange={phone => this.setState({ phone })}
                                />
                            </AvForm>
                            <Label for="example-password-input" className="col-form-label">Role *</Label>
                            <FormGroup row>

                                <Col sm="12">
                                    <select className="selectFormContainer" name='role'
                                        
                                        value={
                                            this.state.role
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        <option value="1">Admin</option>
                                        <option value="2">Support Manager</option>
                                        <option value="3">Marketing</option>
                                        <option value="4">Support Engineer</option>
                                    </select>
                                </Col>
                            </FormGroup>


                        </ModalBody>
                        <ModalFooter className="form-modal-header">
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons"
                                onClick={
                                    this.tog_add
                                }>Close
                            </Button>
                            {
                                this.state.first_name === "" || this.state.last_name === "" || this.state.phone === "" || this.state.email === "" || this.state.passwords === "" || this.state.role === "" ?
                                    <Button type="button" color="primary"
                                        onClick={
                                            () => this.createUser()
                                        }
                                        style={{cursor: "default"}}
                                        disabled
                                        className="waves-effect waves-light">Add User
                                    </Button>
                                    : <Button type="button" color="primary"
                                        onClick={
                                            () => this.createUser()
                                        }
                                        className="waves-effect waves-light">Add User</Button>
                            }

                        </ModalFooter>
                    </Modal>
                </Row>

                <Row>
                    <Modal isOpen={
                        this.state.modal_add_cal
                    }
                        toggle={
                            this.tog_add_cal
                        }
                        autoFocus={true}
                        size="lg">
                        <ModalHeader toggle={
                            this.tog_add_cal2
                        }>
                            Select Date
                        </ModalHeader>
                        <ModalBody>
                        {this.state.DateAlert ?  
                        this.disableToast()
                        : Moment(this.state.fromDate).isAfter(Moment(this.state.toDate)) || Moment(this.state.fromDate).isAfter(Moment()) || Moment(this.state.toDate).isAfter(Moment())?
                        this.disableToast()
                          : null}
                            <Row>
                                <Col>
                                    <Calendar 
                                        onChange={this.handleDatechange} 
                                        // value={this.state.fromDate} 
                                    />
                                </Col>
                                <Col>
                                    <Calendar 
                                        onChange={this.handleToDatechange} 
                                        // value={this.state.toDate} 
                                    />
                                </Col>
                            </Row>                                                   
                        </ModalBody>
                        <ModalFooter>
                        {
                                 this.state.DateAlert === true || this.state.fromDate === '' || this.state.toDate === ''? 
                                 <Button type="button" color="primary" className="waves-effect" disabled
                                    onClick={
                                        this.getUsers
                                    }>Save
                                </Button> 
                                : 
                                Moment(this.state.fromDate).isAfter(Moment(this.state.toDate))  || Moment(this.state.fromDate).isAfter(Moment()) || Moment(this.state.toDate).isAfter(Moment())?
                                <Button type="button" color="primary" className="waves-effect" disabled
                                    onClick={
                                        this.getUsers
                                    }>Save
                                </Button> :
                                <Button type="button" color="primary" className="waves-effect"
                                    onClick={
                                        this.getUsers
                                    }>{
                                        this.state.isLoadingSave === true ? 
                                            <div style={{display: "flex", justifyContent: "center"}}>
                                                <RotatingLines
                                                    strokeColor="white"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="10"
                                                    visible={this.state.isLoadingSave}
                                                />
                                            </div>
                                        : "Save"
                                    }
                                </Button>
                            }
                            
                        </ModalFooter>
                    </Modal>
                </Row>
                <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />
            </React.Fragment>
        );
    }
}
export default connect(null, { setBreadcrumbItems })(Rolesmanagement);
