import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    Col,
    Row,
    Card,
    CardBody,
    FormGroup,
    Label,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    UncontrolledTooltip
} from "reactstrap";

import { MDBDataTable } from 'mdbreact';
import { AvForm, AvField } from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import url from "../../helpers/apiUrl"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Moment from "moment"
import { times } from 'chartist';
import { RotatingLines } from  'react-loader-spinner'
import "./marketing.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class MarketingCampaign extends Component {
    constructor(props) {
        super(props);
   
        this.state = {
            breadcrumbItems: [
                {
                    title: "One Dash",
                    link: "dashboard"
                }, {
                    title: "Campaign",
                    link: "#"
                },
            ], 
            success_confirm: false,
            alert_confirm: false,
            dynamic_title: "",
            dynamic_description: "",
            modal_add: false,
            modal_edit: false,
            campaign: [],
            list: [],
            template: [],
            id: '',
            prev_id: '',
            campaign_type: "",
            campaign_type_filter: '',
            prev_campaign_type: '',
            campaign_channel: "",
            campaign_channel_filter: '',
            prev_campaign_channel: '',
            campaign_name: "",
            prev_campaign_name: '',
            campaign_description: "",
            prev_campaign_description: '',
            campaign_list_id: "",
            campaign_list_id_filter: '',
            prev_campaign_list_id: '',
            campaign_template_id: "",
            campaign_template_id_filter: '',
            prev_campaign_template_id: '',
            campaign_start_date: Moment().format("YYYY-MM-Do"),
            prev_campaign_start_date: '',
            campaign_sending_status: "",
            campaign_sending_status_filter: '',
            prev_campaign_sending_status: '',
            campaign_status: "",
            campaign_status_filter: '',
            prev_campaign_status: '',
            campaign_updated_at: '',
            campaign_created_at: '',
            todayDate: new Date(),
            template_filter: [],
            created_at_filter: '',
            fromDate: '',
            toDate: '',
            modal_add_cal: false,
            updateCampFail: false,
            createCampFail: false,
            updateCamp: false,
            createCamp: false,
            startdate: false,
            DateAlert:false,
            isLoadingSave: false,
        };
        this.viewAllCampaign = this.viewAllCampaign.bind(this);
        this.deleteCampaign = this.deleteCampaign.bind(this);
        this.tog_add = this.tog_add.bind(this);
        this.tog_edit = this.tog_edit.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.createCampaign = this.createCampaign.bind(this);
        this.viewAllList = this.viewAllList.bind(this);
        this.viewAllTemplate = this.viewAllTemplate.bind(this);
        this.updateCampaign = this.updateCampaign.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        // this.handleDate = this.handleDate.bind(this);
        this.changeHandler2 = this.changeHandler2.bind(this);
        this.changeHandler3 = this.changeHandler3.bind(this);
        this.tog_add_cal = this.tog_add_cal.bind(this);
        this.tog_add_cal2 = this.tog_add_cal2.bind(this);
        this.handleDatechange = this.handleDatechange.bind(this);
        this.handleToDatechange = this.handleToDatechange.bind(this);
        this.fillList = this.fillList.bind(this);
        this.disableToast = this.disableToast.bind(this);

    }

    

    componentDidMount() {
        this.props.setBreadcrumbItems("Campaign", this.state.breadcrumbItems);
        if (localStorage.getItem("token") === null) {
            return (
                this.props.history.push('/login')
                // <Redirect to={{ pathname: "/login" }} exact />
            );
        }
        this.viewAllCampaign()
        this.viewAllList()
        this.viewAllTemplate()
    }

    changeHandler2(e){
        this.setState({ [e.target.name]: e.target.value })
        var array = []
        if(e.target.value === "Email"){
            for(let i = 0; i < this.state.template.length; i++){
                if("Email" === this.state.template[i].template_type ){
                    array.push(this.state.template[i])
                }
                
            }
        }      
        else if (e.target.value === "SMS"){
            for(let i = 0; i < this.state.template.length; i++){
                if("SMS" === this.state.template[i].template_type ){
                    array.push(this.state.template[i])
                }
                
            }
        }
        else if (e.target.value === "Whatsapp"){
            for(let i = 0; i < this.state.template.length; i++){
                if("Whatsapp" === this.state.template[i].template_type ){
                    array.push(this.state.template[i])
                }
                
            }  
        }
        this.setState({ template_filter: array })  
    }

    changeHandler3(campaign_channel){
        var array = []
        if(campaign_channel === "Email"){
            for(let i = 0; i < this.state.template.length; i++){
                if("Email" === this.state.template[i].template_type ){
                    array.push(this.state.template[i])
                }
                
            }
        }      
        else if (campaign_channel === "SMS"){
            for(let i = 0; i < this.state.template.length; i++){
                if("SMS" === this.state.template[i].template_type ){
                    array.push(this.state.template[i])
                }
                
            }
        }
        else if (campaign_channel === "Whatsapp"){
            for(let i = 0; i < this.state.template.length; i++){
                if("Whatsapp" === this.state.template[i].template_type ){
                    array.push(this.state.template[i])
                }
                
            }  
        }
        this.setState({ template_filter: array })  
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        if(e.target.value === "custom"){
            this.setState({ [e.target.name]: "custom2",fromDate:'',toDate:''  })
            this.tog_add_cal()
        }
        else{
            setTimeout(() => {
                this.viewAllCampaign()
            }, 1500);
        }
    }

    viewAllCampaign(){
        this.setState({isLoadingSave : true})

        var date = this.state.created_at_filter;
        var fromDate = ''
        var toDate = ''
        if (date === "custom2") {
            fromDate = Moment(this.state.fromDate).format('YYYY-MM-DD')
            toDate = Moment(this.state.toDate).format('YYYY-MM-DD')
        } else if (date !== "") {
            if(date === "1"){
                fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
                toDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            }else{
            fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            toDate = Moment().format('YYYY-MM-DD')
            }
        }

        
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "campaign_type": this.state.campaign_type_filter,
            "campaign_channel": this.state.campaign_channel_filter,
            "campaign_list_id": this.state.campaign_list_id_filter,
            "campaign_template_id": this.state.campaign_template_id_filter,
            "campaign_sending_status": this.state.campaign_sending_status_filter,
            "campaign_status": this.state.campaign_status_filter,
            "from_date": fromDate,
            "to_date": toDate
        });

        
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        
        fetch(`${url}/campaign/view/`, requestOptions)
          .then(response => response.json())
          .then(result => {
            if(result.error === "Invalid Token"){
                localStorage.removeItem('token');
                sessionStorage.clear();
                this.props.history.push('/login') 
            }
            var array = []
            if(result.statusCode === "200"){
                this.setState({isLoadingSave : false})

                if(this.state.modal_add_cal === true){
                    this.setState(prevState => ({
                        modal_add_cal: false
                    }));
                } 
                for(let i = 0; i < result.body.length; i++){
                    array.push({
                        id: result.body[i].id,
                        campaign_type:  result.body[i].campaign_type === "Internal" ? "One Dash Users" : "External" ,
                        campaign_channel:  result.body[i].campaign_channel,
                        campaign_name:  result.body[i].campaign_name,
                        campaign_description:  result.body[i].campaign_description,
                        campaign_list_id:  result.body[i].campaign_list_id ,
                        campaign_template_id: result.body[i].campaign_template_id,
                        campaign_start_date: Moment(result.body[i].campaign_start_date).format('YYYY/MM/DD'),
                        campaign_sending_status:  result.body[i].campaign_sending_status,
                        campaign_status:  result.body[i].campaign_status,
                        list_name: result.body[i].list_name === null ? "-" : result.body[i].list_name,
                        template_name: result.body[i].template_name,
                        campaign_created_at:  Moment(result.body[i].campaign_created_at).format('YYYY/MM/DD HH:mm:ss'),
                        campaign_updated_at:  Moment(result.body[i].campaign_updated_at).format('YYYY/MM/DD HH:mm:ss'),
                        button: <div style={{display: "flex", justifyContent: "center"}}>
                            {
                                result.body[i].campaign_sending_status === "completed" ? null :
                                <>
                                <Button type="button"
                                    onClick={
                                        () => this.fillList(result.body[i])
                                    }
                                   style={{marginRight: 10}}
                                    color="primary"
                                    id="tooltip_edit_campaign"
                                    className="waves-effect waves-light buttons-secondary">
                                    <i className="ti-pencil"></i>
                                </Button>
                                <UncontrolledTooltip placement="bottom" target="tooltip_edit_campaign">
                                    Edit 
                                </UncontrolledTooltip>
                                </>
                            }
                            
                            
                            <Button type="button" color="danger"
                                onClick={
                                    () => this.setState({ campaign_name:  result.body[i].campaign_name, alert_confirm: true, id: result.body[i].id })
                                }
                                className="waves-effect waves-light buttons-secondary"
                                id="tooltip_delete_campaign"><i className="ti-trash"></i>
                            </Button>
                            <UncontrolledTooltip placement="bottom" target="tooltip_delete_campaign">
                                Delete 
                            </UncontrolledTooltip>
                        </div>
                    })
                }
                this.setState({ campaign: array })
            }
            else{
                this.setState({campaign: []})
            }
          })
          .catch(error => console.log('error', error));
        
    }

    viewAllList(){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(`${url}/lists/view/active`, requestOptions)
        .then(response => response.json())
        .then(result => {
            var array = []
            if(result.statusCode === "200"){
                for (let i = 0; i < result.body.length; i++) {
                   array.push({
                    id: result.body[i].id,
                    list_name: result.body[i].list_name,              
                   })   
                }
            }
            this.setState({ list: array })
        })
        .catch(error => console.log('error', error));
    }

    viewAllTemplate(){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(`${url}/templates/view/active`, requestOptions)
        .then(response => response.json())
        .then(result => {
            var array = []
            if(result.statusCode === "200"){
                for (let i = 0; i < result.body.length; i++) {
                    array.push({
                     id: result.body[i].id,
                     template_name: result.body[i].template_name,
                     template_type: result.body[i].template_type,              
                    })   
                 }   
            }
            this.setState({ template: array })
        })
        .catch(error => console.log('error', error));
    }

    createCampaign(){
        if(this.state.campaign_start_date < Moment().format('YYYY-MM-DD')){
            // this.setState({
            //     startdate: true
            // })
            // setTimeout(() => {
            //     this.setState({
            //         startdate: false
            //     })
            // }, 3000)
            toast(<div className='toast-message-container'>
            <h6>Campaigns</h6>
            <p>Please select a valid date.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
         })
            return
        }
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        if(this.state.campaign_type === "Internal"){
            var raw = JSON.stringify({
                "campaign_type": this.state.campaign_type,
                "campaign_channel": this.state.campaign_channel,
                "campaign_name": this.state.campaign_name,
                "campaign_description": this.state.campaign_description,
                "campaign_list_id": 0,
                "campaign_template_id": this.state.campaign_template_id,
                "campaign_start_date": this.state.campaign_start_date,
                "campaign_sending_status": "pending",
                "campaign_status": this.state.campaign_status,
            });
        }else{
            var raw = JSON.stringify({
                "campaign_type": this.state.campaign_type,
                "campaign_channel": this.state.campaign_channel,
                "campaign_name": this.state.campaign_name,
                "campaign_description": this.state.campaign_description,
                "campaign_list_id": this.state.campaign_list_id,
                "campaign_template_id": this.state.campaign_template_id,
                "campaign_start_date": this.state.campaign_start_date,
                "campaign_sending_status": "pending",
                "campaign_status": this.state.campaign_status,
            });
        }
        
        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/campaign/create/`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.statusCode === "200"){
                this.tog_add()
                this.viewAllCampaign()
                // this.setState({
                //     createCamp: true
                // })
                // setTimeout(() => {
                //     this.setState({
                //         createCamp: false
                //     })
                // }, 3000)
                toast(<div className='toast-message-container'>
                <h6>Campaigns</h6>
                <p>Campaign created successfully.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-success'
             })
            }
            else{
                // this.setState({
                //     createCampFail: true
                // })
                // setTimeout(() => {
                //     this.setState({
                //         createCampFail: false
                //     })
                // }, 3000)
                toast(<div className='toast-message-container'>
                <h6>Campaigns</h6>
                <p>Campaign could not be deleted.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-error'
             })
            }
        })
        .catch(error => console.log('error', error));
    }

    updateCampaign(){
        if(this.state.campaign_start_date < Moment().format('YYYY-MM-DD')){
            // this.setState({
            //     startdate: true
            // })
            // setTimeout(() => {
            //     this.setState({
            //         startdate: false
            //     })
            // }, 3000)
            toast(<div className='toast-message-container'>
            <h6>Campaigns</h6>
            <p>Please select a valid date.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
         })
            return
        }
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "id": this.state.id,
        "campaign_type": this.state.campaign_type,
        "campaign_channel": this.state.campaign_channel,
        "campaign_name": this.state.campaign_name,
        "campaign_description": this.state.campaign_description,
        "campaign_list_id": this.state.campaign_list_id,
        "campaign_template_id": this.state.campaign_template_id,
        "campaign_start_date": this.state.campaign_start_date,
        "campaign_sending_status": this.state.campaign_sending_status,
        "campaign_status": this.state.campaign_status,
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/campaign/update/`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.statusCode === "200"){
                this.tog_edit()
                
                this.setState({
                    id: '',
                    campaign_type: '',
                    campaign_channel: '',
                    campaign_name: '',
                    campaign_description: '',
                    campaign_list_id: '',
                    campaign_template_id: '',
                    campaign_start_date: '',
                    campaign_sending_status: '',
                    campaign_status: '',
                    campaign_log: ''
                })
                this.viewAllCampaign()
                // this.setState({
                //     updateCamp: true
                // })
                // setTimeout(() => {
                //     this.setState({
                //         updateCamp: false
                //     })
                // }, 3000)
                toast(<div className='toast-message-container'>
                <h6>Campaigns</h6>
                <p>Campaign updated successfully.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-success'
             })
            }
            else{
                // this.setState({
                //     updateCampFail: true
                // })
                // setTimeout(() => {
                //     this.setState({
                //         updateCampFail: false
                //     })
                // }, 3000)
                toast(<div className='toast-message-container'>
                <h6>Campaigns</h6>
                <p>Campaign could not be updated.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-error'
             }) 
            }
        })
        .catch(error => console.log('error', error));
    }

    deleteCampaign(){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(`${url}/campaign/delete/` + this.state.id, requestOptions)
        .then(response => response.json())
        .then(result => {
            toast(<div className='toast-message-container'>
            <h6>Campaigns</h6>
            <p>Campaign deleted successfully.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-success'
         })
            this.viewAllCampaign()
        })
        .catch(error => console.log('error', error));


    }

    fillList(campaign){
        this.tog_edit()
        this.setState({
            id: campaign.id,
            campaign_type: campaign.campaign_type,
            campaign_channel: campaign.campaign_channel,
            campaign_name: campaign.campaign_name,
            campaign_description: campaign.campaign_description,
            campaign_list_id: campaign.campaign_list_id,
            campaign_template_id: campaign.campaign_template_id,
            campaign_start_date: campaign.campaign_start_date,
            campaign_sending_status: campaign.campaign_sending_status,
            campaign_status: campaign.campaign_status,
            prev_campaign_type: campaign.campaign_type,
            prev_campaign_channel: campaign.campaign_channel,
            prev_campaign_name: campaign.campaign_name,
            prev_campaign_description: campaign.campaign_description,
            prev_campaign_list_id: campaign.campaign_list_id,
            prev_campaign_template_id: campaign.campaign_template_id,
            prev_campaign_start_date: campaign.campaign_start_date,
            prev_campaign_sending_status: campaign.campaign_sending_status,
            prev_campaign_status: campaign.campaign_status
        })
        this.changeHandler3(campaign.campaign_channel)
    }

    tog_add() {
        if (this.state.modal_add === true) {
            this.setState({
                campaign_type: '',
                campaign_channel: '',
                campaign_name: '',
                campaign_description: '',
                campaign_list_id: '',
                campaign_template_id: '',
                campaign_start_date: '',
                campaign_sending_status: '',
                campaign_status: '',
                campaign_log: ''
            })
        }
        this.setState(prevState => ({
            modal_add: !prevState.modal_add

        }));
    }

    tog_edit() {
        this.viewAllCampaign()
        this.setState(prevState => ({
            modal_edit: !prevState.modal_edit         
        }));
        if (this.state.modal_edit === true) {
            
            this.setState({
                campaign_type: '',
                campaign_channel: '',
                campaign_name: '',
                campaign_description: '',
                campaign_list_id: '',
                campaign_template_id: '',
                campaign_start_date: '',
                campaign_sending_status: '',
                campaign_status: '',
                campaign_log: ''
            })
        }
        setTimeout(() => {
            this.viewAllCampaign()
        }, 100)
        
        
    }

    clearFilter(){
        this.setState({
            campaign_type_filter: '',
            campaign_channel_filter: '',
            campaign_list_id_filter: '',
            campaign_template_id_filter: '',
            campaign_sending_status_filter: '',
            campaign_status_filter: '',
            campaign_created_at: '',
            created_at_filter: '',
            campaign_updated_at: '',
            campaign_created_at: '',
        })
        setTimeout(() => {
            this.viewAllCampaign()
        }, 100)
    }
    listId(){
        this.setState({
            campaign_list_id: 0
        })
    }

    tog_add_cal() {
        this.setState(prevState => ({
            modal_add_cal: !prevState.modal_add_cal
        }));
    }

    tog_add_cal2() {
        this.setState(prevState => ({
            modal_add_cal: !prevState.modal_add_cal,fromDate:'',toDate:'',created_at_filter:"",DateAlert:false

        }));
        setTimeout(() => {
            this.viewAllCampaign()
        }, 1500);
    }
    handleDatechange(value){
        var dateofvisit = Moment(value, 'DD-MM-YYYY');
        var today = Moment();
        if(today.diff(dateofvisit, 'Hours') <= 0){
            this.setState({
                DateAlert: true,fromDate: Moment(value).format("YYYY-MM-DD")
            })
            
            return  
        }else{
            this.setState({
                DateAlert: false
            }) 
            this.setState({fromDate: Moment(value).format("YYYY-MM-DD")})
        }
    }
    handleToDatechange(value){
        var dateofvisit = Moment(value, 'DD-MM-YYYY');
        var today = Moment();
        
        if(today.diff(dateofvisit, 'Hours') <= 0){
            this.setState({
                DateAlert: true,toDate: Moment(value).format("YYYY-MM-DD")
            })
            
            return  
        }else if(this.state.fromDate !== ""){
            if(Moment(this.state.fromDate, 'YYYY-MM-DD').diff(dateofvisit, 'Hours') > 0){
                this.setState({
                    DateAlert: true,toDate: Moment(value).format("YYYY-MM-DD")
                })
            }else{
                this.setState({
                    DateAlert: false
                })
                this.setState({toDate: Moment(value).format("YYYY-MM-DD")})
            }
        }else{
            this.setState({
                DateAlert: false
            })
            this.setState({toDate: Moment(value).format("YYYY-MM-DD")})

        }
    }
    disableToast(){
        let toastDiv = document.querySelector('.toast-error');
        if(toastDiv == null){
            toast(<div className='toast-message-container'>
            <h6>Campaigns</h6>
            <p>Please select a valid date.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
         })
        }
    }
    render() {
        const data = {
            columns: [
                {
                    label: 'ID',
                    field: 'id',
                    width: 150
                },
                {
                    label: 'Campaign Type',
                    field: 'campaign_type',
                    width: 270
                },
                {
                    label: 'Campaign Channel',
                    field: 'campaign_channel',
                    width: 200
                },
                {
                    label: 'Campaign Name',
                    field: 'campaign_name',
                    width: 10
                }, 
                {
                    label: 'Campaign Description',
                    field: 'campaign_description',

                    width: 10
                },
                {
                    label: 'List Name',
                    field: 'list_name',
                    width: 10
                },
                {
                    label: 'Campaign Start Date',
                    field: 'campaign_start_date',
                    width: 10
                },
                {
                    label: 'Template Name',
                    field: 'template_name',
                    width: 10
                },
                {
                    label: 'Campaign Sending Status',
                    field: 'campaign_sending_status',
                    width: 10
                },
                {
                    label: 'Campaign Status',
                    field: 'campaign_status',
                    width: 10
                },
                {
                    label: 'Created At',
                    field: 'campaign_created_at',
                    width: 150
                }, {
                    label: 'Updated At',
                    field: 'campaign_updated_at',
                    width: 100
                }, {
                    label: 'Action',
                    field: 'button',
                    sort: "disabled",
                    width: 100
                }
            ],
            rows: this.state.campaign
        };
        return (
            <React.Fragment>
                
                
                {this.state.alert_confirm ? (
                    
                    <div className="modal" style={{display:"block"}} tabIndex="-1" role="dialog" id='alertBox'>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Delete Campaign</h5>
                                
                            </div>
                            <div className="modal-body">
                                <p>{ "Are you sure you want to delete " + this.state.campaign_name + "?"}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" style={{background:"#FFFFFF0D", borderColor:"#2f2f34"}} className="btn" data-dismiss="modal"
                                onClick={() => {
                                    this.setState({
                                        alert_confirm: false,
                                    });
                                    document.getElementById('alertBox').style.display = 'none';
                                }} 
                                >Cancel</button>
                                <button type="button" className="btn"
                                onClick={() => {
                                    this.setState({ alert_confirm: false })
                                    this.deleteCampaign();
                                    document.getElementById('alertBox').style.display = 'none';
                                }}
                                >Delete</button>
                            </div>
                            </div>
                        </div>
                    </div>

                ) : null}
                
                <div style={
                    {
                        marginTop: 20,
                        marginBottom: 30
                    }
                }>
                    <Button type="button"
                        style={
                            { marginBottom: 10, background: "#17A0FB", padding: "7px 14px", borderColor: "#17A0FB", fontWeight: 600, margin: "15px" }
                        }
                        onClick={
                            this.tog_add
                        }
                        color="info"
                        className="waves-effect waves-light">
                       Add Campaign</Button>
                </div>

                
                {/* Filters */}
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody >
                                <Row xs="12">
                                    <Col>
                                        <Label for="example-password-input" className=" col-form-label">Campaign Type</Label>
                                        <Col>
                                            <select className="form-control" name='campaign_type_filter'
                                                value={
                                                    this.state.campaign_type_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                <option value="Internal">One Dash Users</option>
                                                <option value="External">External Users</option>
                                            </select>
                                        </Col>
                                    </Col>

                                    <Col>
                                        <Label for="example-password-input" className="col-form-label">Campaign Channel</Label>
                                        <Col>
                                            <select className="form-control" name='campaign_channel_filter'
                                                value={
                                                    this.state.campaign_channel_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                <option value="Email">Email</option>
                                                <option value="Sms">Sms</option>
                                                <option value="Whatsapp">Whatsapp</option>
                                            </select>
                                        </Col>
                                    </Col>
                                    <Col>
                                        <Label for="example-password-input" className=" col-form-label">List Name</Label>
                                        <Col>
                                            <select className="form-control" name='campaign_list_id_filter'
                                                value={
                                                    this.state.campaign_list_id_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                               <option value="" disabled selected hidden>Select</option>
                                                {
                                                    this.state.list.map((lists) => (
                                                        <option value={lists.id}>{lists.list_name}</option>
                                                    ))
                                                }
                                            </select>
                                        </Col>
                                    </Col>
                                    <Col>
                                        <Label for="example-password-input" className=" col-form-label">Template List</Label>
                                        <Col>
                                            <select className="form-control" name='campaign_template_id_filter'
                                                value={
                                                    this.state.campaign_template_id_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                {
                                                    this.state.template.map((templates) => (
                                                        <option value={templates.id}>{templates.template_name}</option>
                                                    ))
                                                }
                                            </select>
                                        </Col>
                                    </Col>
                                    <Col>
                                        <Label for="example-password-input" className="col-form-label"> Sending Status</Label>
                                        <Col>
                                            <select className="form-control" name='campaign_sending_status_filter'
                                                value={
                                                    this.state.campaign_sending_status_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                <option value="Pending">Pending</option>
                                                <option value="Completed">Completed</option>
                                            </select>
                                        </Col>
                                    </Col>
                                    <Col>
                                        <Label for="example-password-input" className="col-form-label">Campaign Status</Label>
                                        <Col>
                                            <select className="form-control" name='campaign_status_filter'
                                                value={
                                                    this.state.campaign_status_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                <option value="Active">Active</option>
                                                <option value="Inactive">Inactive</option>
                                            </select>
                                        </Col>
                                    </Col>
                                    <Col>
                                        <Label for="example-password-input" className=" col-form-label">Created At</Label>
                                        <Col>
                                            <select className="form-control" name='created_at_filter'
                                                value={
                                                    this.state.created_at_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                <option value="0">Today</option>
                                                <option value="1">Yesterday</option>
                                                <option value="7">Last 7 days</option>
                                                <option value="30">Last 30 days</option>
                                                <option value="180">Last 6 months</option>
                                                <option value = "custom" onClick={this.tog_add_cal}>Custom</option>
                                                <option value = "custom2" hidden>{ this.state.created_at_filter === "custom2" ? "From: " + this.state.fromDate + " To: " + this.state.toDate : "Custom"}</option>
                                            </select>
                                        </Col>
                                    </Col>
                                </Row>
                                <Row xs="12">
                                </Row>
                                <Col className="text-left" style={{ marginTop: 10 }}>
                                    <Button type="button"
                                        style={{background: "#17A0FB", padding: "7px 14px", borderColor: "#17A0FB", fontWeight: 600}}
                                        onClick={
                                            this.viewAllCampaign
                                        }
                                        color="warning"
                                        className="waves-effect waves-light">
                                        <i className='mdi mdi-filter-menu-outline'
                                            style={
                                                { marginRight: 10, fontSize: 15 }
                                            }
                                        ></i>
                                        Filter
                                    </Button>

                                    {
                                        this.state.created_at_filter === '' && this.state.campaign_status_filter === '' && this.state.campaign_sending_status_filter === '' && this.state.campaign_template_id_filter === '' && this.state.campaign_list_id_filter === '' && this.state.campaign_channel_filter === '' && this.state.campaign_type_filter === '' ?
                                        <Button type="button"
                                            style={{ marginLeft: 10 ,border: "1px solid #6a6a71", borderRadius: "3px" ,backgroundColor: "rgba(80,80,88,.8)", transition: "background-color .2s linear", fontWeight: 600}}
                                            disabled
                                            onClick={
                                                () => this.clearFilter()
                                            }
                                            color="success"
                                            className="waves-effect waves-light">

                                            Clear Filter
                                        </Button> :
                                        <Button type="button"
                                            style={{ marginLeft: 10 ,border: "1px solid #E6536C", borderRadius: "3px" ,backgroundColor: "#E6536C", transition: "background-color .2s linear", padding:"7px 14px", fontWeight: 600}}
                                            onClick={
                                                () => this.clearFilter()
                                            }
                                            color="success"
                                            className="waves-effect waves-light">

                                            Clear Filter
                                        </Button>
                                    }
                                    
                                </Col>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {this.state.createCamp ? <Alert color="success">
                    <strong>Campaign created successfully.</strong>
                </Alert> : null}
                {this.state.updateCamp ? <Alert color="success">
                    <strong>Campaign updated successfully.</strong>
                </Alert> : null}
                {this.state.createCampFail ? <Alert color="danger">
                    <strong>Creation failed.</strong>
                </Alert> : null}
                {this.state.updateCampFail ? <Alert color="danger">
                    <strong>Updation failed.</strong>
                </Alert> : null}
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody >
                                <MDBDataTable responsive bordered striped
                                    data={data}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Modal isOpen={
                        this.state.modal_add_cal
                    }
                        toggle={
                            this.tog_add_cal
                        }
                        autoFocus={true}
                        size="lg">
                        <ModalHeader toggle={
                            this.tog_add_cal2
                        }>
                            Select Date
                        </ModalHeader>
                        <ModalBody>
                        {this.state.DateAlert ?  
                        this.disableToast()
                        : Moment(this.state.fromDate).isAfter(Moment(this.state.toDate)) || Moment(this.state.fromDate).isAfter(Moment()) || Moment(this.state.toDate).isAfter(Moment())?
                        this.disableToast()
                          : null}
                            <Row>
                                <Col>
                                    <Calendar 
                                        onChange={this.handleDatechange} 
                                    />
                                </Col>
                                <Col>
                                    <Calendar 
                                        onChange={this.handleToDatechange} 
                                    />
                                </Col>
                            </Row>                                                   
                        </ModalBody>
                        <ModalFooter>
                        {
                                 this.state.DateAlert === true || this.state.fromDate === '' || this.state.toDate === ''? 
                                 <Button type="button" color="primary" className="waves-effect" disabled
                                    onClick={
                                        this.viewAllCampaign
                                    }>Save
                                </Button> 
                                : 
                                Moment(this.state.fromDate).isAfter(Moment(this.state.toDate))  || Moment(this.state.fromDate).isAfter(Moment()) || Moment(this.state.toDate).isAfter(Moment())?
                                <Button type="button" color="primary" className="waves-effect" disabled
                                    onClick={
                                        this.viewAllCampaign
                                    }>Save
                                </Button> :
                                <Button type="button" color="primary" className="waves-effect"
                                    onClick={
                                        this.viewAllCampaign
                                    }>{
                                        this.state.isLoadingSave === true ? 
                                            <div style={{display: "flex", justifyContent: "center"}}>
                                                <RotatingLines
                                                    strokeColor="white"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="10"
                                                    visible={this.state.isLoadingSave}
                                                />
                                            </div>
                                        : "Save"
                                    }
                                </Button>
                            }
                            
                        </ModalFooter>
                    </Modal>
                </Row>

                {/*Create List*/}
                <Row>
                    <Modal isOpen={
                        this.state.modal_add
                    }
                        toggle={
                            this.tog_add
                        }
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">
                        <ModalHeader className="modal-form-header">
                            Add Details
                        </ModalHeader>
                        <ModalBody>
                        <Label for="example-password-input" className="col-form-label">Campaign Type *</Label>
                            <FormGroup row className="formContainer2">

                                <Col sm="12">
                                    <select className="selectFormContainer mb-0" style={{width:"100%"}} name='campaign_type'
                                        value={
                                            this.state.campaign_type
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        <option value="Internal">One Dash Users</option>
                                        <option value="External">External Users</option>
                                    </select>
                                </Col>
                            </FormGroup>
                            <AvForm className="formContainer">
                                <AvField
                                    name="campaign_name"
                                    className="w-100"
                                    label="Campaign Name * (50 characters max.)"
                                    placeholder="Enter Campaign Name"
                                    type="text"
                                    maxLength="50"
                                    errorMessage="Enter Campaign Name"
                                    validate={{ required: { value: true } }}
                                    value={
                                        this.state.campaign_name
                                    }
                                    onChange
                                    ={this.changeHandler}
                                />
                                <AvField
                                    name="campaign_description"
                                    label="Campaign Description *"
                                    className="w-100"
                                    placeholder="Enter Campaign Description"
                                    type="text"
                                    errorMessage="Enter Campaign Description"
                                    validate={{ required: { value: true } }}
                                    value={
                                        this.state.campaign_description
                                    }
                                    onChange
                                    ={this.changeHandler}
                                />
                                 {this.state.startdate ? <Alert color="danger">
                                        <strong>Please select proper date.</strong>
                                    </Alert> : null}
                                <AvField
                                    name="campaign_start_date"
                                    label="Campaign Start Date *"
                                    className="w-100"
                                    placeholder="Enter Campaign Start Date"
                                    type="date"
                                    errorMessage="Enter Campaign Start Date or Valid Date"
                                    min = {this.state.todayDate}
                                    validate={{ required: { value: true } }}
                                    value={
                                        this.state.campaign_start_date
                                    }
                                    onChange
                                    ={this.changeHandler}
                                />
                            </AvForm>
                            <Label for="example-password-input" className="col-form-label">Campaign Channel *</Label>
                            <FormGroup row className="formContainer2">
                                <Col sm="12">
                                    <select className="selectFormContainer mb-0 w-100" name='campaign_channel'
                                        value={
                                            this.state.campaign_channel
                                        }
                                        onChange={
                                            this.changeHandler2
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        {
                                            this.state.campaign_type === "Internal" ?
                                            <option value="Email">Email</option> :
                                            <> 
                                                <option value="Email">Email</option>
                                                <option value="SMS">SMS</option>
                                                <option value="Whatsapp">Whatsapp</option>
                                            </>
                                            
                                        }
                                        
                                    </select>
                                </Col>
                            </FormGroup>
                            { this.state.campaign_type === "Internal" ? null
                            : <>
                                <Label  for="example-password-input" className="col-form-label">Campaign List Name *</Label>
                                <FormGroup row className="formContainer2">
                                    <Col sm="12">
                                        <select  className="selectFormContainer mb-0 w-100" name='campaign_list_id'
                                            value={
                                                this.state.campaign_list_id
                                            }
                                            onChange={
                                                this.changeHandler
                                            }>
                                            <option value="" disabled selected hidden>Select</option>
                                            {
                                                this.state.list.map((lists) => (
                                                    <option value={lists.id}>{lists.list_name}</option>
                                                ))
                                            }
                                        </select>
                                    </Col>
                                </FormGroup>
                            </>
                            }
                            <Label for="example-password-input" className="col-form-label">Template Name *</Label>
                            <FormGroup row className="formContainer2">
                                <Col sm="12">
                                    <select className="selectFormContainer mb-0 w-100" name='campaign_template_id'
                                        value={
                                            this.state.campaign_template_id
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        {
                                            this.state.template_filter.map((templates) => (
                                                <option value={templates.id}>{templates.template_name}</option>
                                            ))
                                        }
                                    </select>
                                </Col>
                            </FormGroup>
                            
                            <Label for="example-password-input" className="col-form-label">Status *</Label>
                            <FormGroup row className="formContainer2">
                                <Col sm="12">
                                    <select className="selectFormContainer w-100" name='campaign_status'
                                        value={
                                            this.state.campaign_status
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </Col>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons"
                                onClick={
                                    this.tog_add
                                }>Close
                            </Button>
                            {
                                this.state.campaign_type === "" || this.state.campaign_description === "" || this.state.campaign_channel === "" || this.state.campaign_name === ""  || this.state.campaign_template_id === "" || this.state.campaign_start_date === "" || this.state.campaign_status === "" ?
                                    <Button type="button" color="primary"
                                    style={{cursor: "default"}}
                                        onClick={
                                            () => this.createCampaign()
                                        }
                                        disabled
                                        className="waves-effect waves-light">Add Campaign
                                    </Button>
                                    : <Button type="button" color="primary"
                                        onClick={
                                            () => this.createCampaign()
                                        }
                                        className="waves-effect waves-light">Add Campaign</Button>
                            }

                        </ModalFooter>
                    </Modal>
                </Row>

                {/*Update List*/}
                <Row>
                    <Modal isOpen={
                        this.state.modal_edit
                    }
                        toggle={
                            this.tog_edit
                        }
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">
                        <ModalHeader >
                            Edit Details
                        </ModalHeader>
                        <ModalBody>
                        <Label for="example-password-input" className="col-form-label">Campaign Type *</Label>
                            <FormGroup row className="formContainer2">

                                <Col sm="12">
                                    <select className="selectFormContainer w-100 mb-0" name='campaign_type' 
                                        value={
                                            this.state.campaign_type
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        <option value="Internal">One Dash Users</option>
                                        <option value="External">External Users</option>
                                    </select>
                                </Col>
                            </FormGroup>
                            <AvForm className="formContainer2">
                                <AvField
                                    name="campaign_name"
                                    className="w-100"
                                    label="Campaign Name * (50 characters max.)"
                                    placeholder="Enter Campaign Name"
                                    maxLength="50"
                                    type="text"
                                    errorMessage="Enter Campaign Name"
                                    validate={{ required: { value: true } }}
                                    value={
                                        this.state.campaign_name
                                    }
                                    onChange
                                    ={this.changeHandler}
                                />
                                <AvField
                                    name="campaign_description"
                                    label="Campaign Description *"
                                    className="w-100"
                                    placeholder="Enter Campaign Description"
                                    type="text"
                                    errorMessage="Enter Campaign Description"
                                    validate={{ required: { value: true } }}
                                    value={
                                        this.state.campaign_description
                                    }
                                    onChange
                                    ={this.changeHandler}
                                />
                                {this.state.startdate ? <Alert color="danger">
                                        <strong>Please select proper date.</strong>
                                    </Alert> : null}
                                <AvField
                                    name="campaign_start_date"
                                    label="Campaign Start Date *"
                                    placeholder="Enter Campaign Start Date"
                                    type="date"
                                    className="w-100"
                                    errorMessage="Enter Campaign Valid Start Date"
                                    min = {this.state.todayDate}
                                    validate={{ required: { value: true }}}
                                    value={
                                        Moment(this.state.campaign_start_date).format('YYYY-MM-DD')
                                    }
                                    onChange
                                    ={this.changeHandler}
                                />
                            </AvForm>
                            <Label for="example-password-input" className="col-form-label" >Campaign Channel </Label>
                            <FormGroup row className="formContainer2">
                                <Col sm="12">
                                    <select className="selectFormContainer mb-0 w-100" name='campaign_channel' disabled
                                        value={
                                            this.state.campaign_channel
                                        }
                                        onChange={
                                            this.changeHandler2
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        <option value="Email">Email</option>
                                        <option value="SMS">SMS</option>
                                        <option value="Whatsapp">Whatsapp</option>
                                    </select>
                                </Col>
                            </FormGroup>
                            {
                                this.state.campaign_type === "Internal" ? null
                                : <>
                                    <Label for="example-text-input" className=" col-form-label">Campaign List Name *</Label>
                                    <FormGroup row className="formContainer2">
                                        <Col sm="12">
                                            <select className="selectFormContainer mb-0 w-100" name='campaign_list_id' 
                                                value={
                                                    this.state.campaign_list_id
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                {
                                                    this.state.list.map((lists) => (
                                                        <option value={lists.id}>{lists.list_name}</option>
                                                    ))
                                                }
                                            </select>
                                        </Col>
                                    </FormGroup>
                                </>
                            }
                            
                            <Label for="example-password-input" className="col-form-label">Template Name *</Label>
                            <FormGroup row className="formContainer2">
                                <Col sm="12">
                                    <select className="selectFormContainer mb-0 w-100" name='campaign_template_id' 
                                        value={
                                            this.state.campaign_template_id
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        {
                                            this.state.template_filter.map((templates) => (
                                                <option value={templates.id}>{templates.template_name}</option>
                                            ))
                                        }
                                    </select>
                                </Col>
                            </FormGroup>
                            <Label for="example-password-input" className="col-form-label">Campaign Sending Status *</Label>
                            <FormGroup row className="formContainer2">
                                <Col sm="12">
                                    <select className="selectFormContainer w-100 mb-0" name='campaign_sending_status' disabled
                                        value={
                                            this.state.campaign_sending_status
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        <option value="pending">Pending</option>
                                        <option value="completed">Completed</option>
                                    </select>
                                </Col>
                            </FormGroup>
                            <Label for="example-password-input" className="col-form-label">Status *</Label>
                            <FormGroup row className="formContainer2">
                                <Col sm="12">
                                    <select className="selectFormContainer mb-0 w-100" name='campaign_status' 
                                        value={
                                            this.state.campaign_status
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                        <option value="completed">Completed</option>
                                    </select>
                                </Col>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons"
                                onClick={
                                    this.tog_edit
                                }>Close
                            </Button>
                            {
                               this.state.campaign_type === this.state.prev_campaign_type && this.state.campaign_description === this.state.prev_campaign_description && this.state.campaign_channel === this.state.prev_campaign_channel && this.state.campaign_name === this.state.prev_campaign_name && this.state.campaign_list_id === this.state.prev_campaign_list_id && this.state.campaign_template_id === this.state.prev_campaign_template_id && this.state.campaign_start_date === this.state.prev_campaign_start_date && this.state.campaign_sending_status === this.state.prev_campaign_sending_status && this.state.campaign_status === this.state.prev_campaign_status ?
                                    <Button type="button" color="primary"
                                        onClick={
                                            () => this.updateCampaign()
                                        }
                                        style={{cursor: "default"}}
                                        disabled
                                        className="waves-effect waves-light">Update Campaign
                                    </Button>
                                    : <Button type="button" color="primary"
                                        onClick={
                                            () => this.updateCampaign()
                                        }
                                        className="waves-effect waves-light">Update Campaign</Button>
                            }

                        </ModalFooter>
                    </Modal>
                </Row>
                <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(MarketingCampaign);